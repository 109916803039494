import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import { Button, GridRowColumn, Icon, Text } from '../BaseComponents'
import type { Fonts } from '../../styles/theme'

const PageHeader = ({
  header,
  isMultiStep,
  extraContent,
  backControl,
  divider = true,
}: {
  header: string
  isMultiStep?: boolean
  extraContent?: ReactNode
  backControl?: {
    link: string | -1
    text: string
  }
  divider?: boolean
}) => {
  const navigate = useNavigate()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
  let size: keyof typeof Fonts = 'h1'

  if (isMultiStep) {
    size = 'h3'
  } else if (isMobile) {
    size = 'h2'
  }

  const leftMarginStyle = isMobile ? { marginLeft: 20 } : {}

  return (
    <Grid>
      {backControl && (
        <>
          <Grid.Row className="short" />
          <GridRowColumn style={leftMarginStyle}>
            {/* @ts-expect-error -- -1 is a valid parameter that navigates to the last page on the stack */}
            <Button onClick={() => navigate(backControl.link)} variant="link">
              <Icon icon={regular('arrow-left')} style={{ marginRight: 10 }} />
              {backControl.text}
            </Button>
          </GridRowColumn>
          <GridRowColumn short>
            {divider && (
              <Divider
                // Tablet divider should be flush to sides
                style={isTablet ? { marginLeft: -25, marginRight: -25 } : {}}
              />
            )}
          </GridRowColumn>
        </>
      )}
      <Grid.Row verticalAlign="middle">
        <Grid.Column computer={11} tablet={10} mobile={10}>
          <Text as={size} style={leftMarginStyle}>
            {header}
          </Text>
        </Grid.Column>
        <Grid.Column
          floated="right"
          computer={5}
          tablet={6}
          mobile={6}
          style={{
            maxWidth: 250,
            display: 'flex',
            alignItems: 'end',
            ...(isMobile && { paddingRight: 20 }),
          }}
        >
          {extraContent}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PageHeader
