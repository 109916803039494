import { ReactElement, useCallback, useMemo, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { Container, Divider, Grid, List } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import {
  Alert,
  Button,
  Card,
  FormikDateInput,
  FormikInput,
  FormikLocationSearchInput,
  FormikScrollOnError,
  FormikSSNInput,
  getFieldNames,
  GridRowColumn,
  makeDateSchema,
  makeEinSchema,
  makeReqPhoneNumberSchema,
  makeReqStringSchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import {
  selectIsTwoFormFiler,
  selectAnnualTaxFilingFormById,
} from '../../../annualTaxFilingForms.selector'
import {
  isTaxEntityType,
  TAX_ENTITY_TYPES,
  TAX_ENTITY_TYPES_TYPE,
} from '../../../../taxConstants'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  TaxChecklistResponse,
  TaxChecklistResponseValue,
} from '../../taxChecklistQuestion.slice'
import { FORM_1120_S_SCREENS } from '../../Form1120S'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import {
  updateUser,
  UpdateUserPayload,
} from '../../../../../../actions/userActions'
import { FORM_1040_DETAILS_SCREENS } from '../../Form1040/Details'
import { useSetScreen } from '../../../../../../components/FormFlow/formFlow'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { UPSERT_USER_TAX_QUESTIONNAIRE_KEY } from '../../taxChecklistQuestion.actions'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../../../utils/dateHelpers'
import ReadOnlyForm from '../ReadOnlyForm'
import { SubStepIdentifiers } from '../ReviewStepsandProgresses/stepProgress.helpers'
import { StepUpdates } from '../ReviewStepsandProgresses/useProgressSteps'
import { EXTENSION_REQUEST_SCREENS } from '../../../ExtensionSurvey/Combined'
import { AnnualTaxFilingForm } from '../../../annualTaxFilingForms.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import {
  EncryptionError,
  isEncrypted,
  useEncryption,
} from '../../../../../../utils/evervault'
import useMissingAnswerValidation from '../UseMissingAnswerValidation'

export const getPersonalQuestionIds = (
  formType?: TAX_ENTITY_TYPES_TYPE,
  isShortened = false
) => {
  if (formType === TAX_ENTITY_TYPES.form_1120_s) {
    return [
      TaxListQuestionId.first_name,
      TaxListQuestionId.last_name,
      TaxListQuestionId.ssn,
      TaxListQuestionId.personal_phone,
      TaxListQuestionId.home_address,
    ]
  }
  if (isShortened) {
    return [
      TaxListQuestionId.first_name,
      TaxListQuestionId.last_name,
      TaxListQuestionId.date_of_birth,
      TaxListQuestionId.occupation,
    ]
  }
  return [
    TaxListQuestionId.first_name,
    TaxListQuestionId.last_name,
    TaxListQuestionId.date_of_birth,
    TaxListQuestionId.ssn,
    TaxListQuestionId.occupation,
    TaxListQuestionId.personal_phone,
    TaxListQuestionId.home_address,
  ]
}

export const getBusinessQuestionIds = (formType: TAX_ENTITY_TYPES_TYPE) => {
  if (formType === TAX_ENTITY_TYPES.form_1040) {
    return [
      TaxListQuestionId.business_name,
      TaxListQuestionId.business_email,
      TaxListQuestionId.business_phone,
      TaxListQuestionId.business_address,
    ]
  }
  return [
    TaxListQuestionId.business_name,
    TaxListQuestionId.scorp_election_date,
    TaxListQuestionId.ein,
    TaxListQuestionId.business_email,
    TaxListQuestionId.business_phone,
    TaxListQuestionId.business_address,
  ]
}

const shouldSaveResponseToQuestions = (
  filingForm: AnnualTaxFilingForm | null,
  questionId: TaxListQuestionId,
  type: 'personal' | 'business',
  isTwoFormFiler: boolean
) => {
  const formName = filingForm?.formType?.name

  if (!isTaxEntityType(formName)) {
    return false
  }
  const relevantIds =
    type === 'business'
      ? getBusinessQuestionIds(formName)
      : getPersonalQuestionIds(formName, isTwoFormFiler)
  return relevantIds.includes(questionId)
}

export interface CheckDetailsProps<T> {
  goToNextStep: (
    data: Partial<TaxChecklistResponse>[] | null,
    newScreen: T | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => T
  ) => Promise<boolean>
  goBack: (newScreen: T | null) => void
  nextScreen?: T | null
  previousScreen?: T | null
  formId?: string
}

export const useBusinessDetails = <
  T extends
    | FORM_1120_S_SCREENS
    | FORM_1040_DETAILS_SCREENS
    | EXTENSION_REQUEST_SCREENS,
>({
  is1040,
  isUpdating,
  isTwoFormFiler,
  formId,
  goToNextStep,
}: {
  is1040: boolean
  isUpdating: boolean
  isTwoFormFiler: boolean
  formId?: string
  goToNextStep: (
    data: Partial<TaxChecklistResponse>[] | null,
    newScreen: T | null
  ) => Promise<boolean>
}) => {
  const user = useReselector(getCurrentUser)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const financialProfile = user?.financialProfile
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const { currentScreen } = useSetScreen()

  const shouldShowBusinessForm = useMemo(
    () => !is1040 || !isTwoFormFiler,
    [is1040, isTwoFormFiler]
  )

  const questionBusinessName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.business_name,
    taxYear
  )
  const responseBusinessName = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_name,
    Number(formId)
  )
  const questionScorpElectionDate = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.scorp_election_date,
    taxYear
  )
  const responseScorpElectionDate = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.scorp_election_date,
    Number(formId)
  )
  const questionEin = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.ein,
    taxYear
  )
  const responseEin = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.ein,
    Number(formId)
  )
  const questionBusinessEmail = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.business_email,
    taxYear
  )
  const responseBusinessEmail = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_email,
    Number(formId)
  )
  const questionBusinessAddress = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.business_address,
    taxYear
  )
  const responseBusinessAddress = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_address,
    Number(formId)
  )
  const questionBusinessPhone = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.business_phone,
    taxYear
  )
  const responseBusinessPhone = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_phone,
    Number(formId)
  )

  const getPopupContent = (questionId?: TaxListQuestionId) => {
    if (
      questionId !== TaxListQuestionId.scorp_election_date &&
      questionId !== TaxListQuestionId.ein
    ) {
      return null
    }
    const title =
      questionId === TaxListQuestionId.scorp_election_date
        ? 'Date of S corp Election'
        : 'Employer Identification Number'
    let content: string | ReactElement =
      'This is the date of election on Form 2553, the form you filed when registering for S corp status.'
    if (questionId === TaxListQuestionId.ein) {
      content = (
        <>
          <GridRowColumn short>
            <Text>In order to retrieve this, you can:</Text>
          </GridRowColumn>
          <List bulleted>
            <List.Item>
              <Text>Call the IRS and request it</Text>
            </List.Item>
            <List.Item>
              <Text>
                Find it on your confirmation letter from the IRS, old tax
                returns or credit reports for your business or payroll documents
              </Text>
            </List.Item>
          </List>
        </>
      )
    }
    return (
      <Popup
        content={
          <Grid>
            <GridRowColumn>
              <Text as="h3">{title}</Text>
            </GridRowColumn>
            <GridRowColumn short>{content}</GridRowColumn>
          </Grid>
        }
      />
    )
  }

  const isInitialValidBusiness = Boolean(
    (is1040 && isTwoFormFiler) ||
      ((responseBusinessName?.[0]?.value || financialProfile?.businessName) &&
        (is1040 ||
          responseScorpElectionDate?.[0]?.value ||
          financialProfile?.entityChangeElectionDate) &&
        (is1040 || responseEin?.[0]?.value || financialProfile?.einNumber) &&
        (responseBusinessEmail?.[0]?.value || user?.email) &&
        (responseBusinessAddress?.[0]?.value ||
          financialProfile?.businessAddress) &&
        (responseBusinessPhone?.[0]?.value || user?.phoneNumber))
  )

  const shouldSaveResponse = useCallback(
    (questionId: TaxListQuestionId, type: 'personal' | 'business') => {
      return shouldSaveResponseToQuestions(
        filingForm,
        questionId,
        type,
        isTwoFormFiler
      )
    },
    [filingForm, isTwoFormFiler]
  )

  const businessFormik = useFormik({
    initialValues: {
      businessName:
        responseBusinessName?.[0]?.value ?? financialProfile?.businessName,
      scorpElectionDate:
        responseScorpElectionDate?.[0]?.value ??
        (financialProfile?.entityChangeElectionDate
          ? formatISOFromUTC(financialProfile.entityChangeElectionDate)
          : undefined),
      ein: responseEin?.[0]?.value ?? financialProfile?.einNumber,
      businessEmail: responseBusinessEmail?.[0]?.value ?? user?.email,
      businessAddress:
        responseBusinessAddress?.[0]?.value ??
        financialProfile?.businessAddress,
      businessPhone: responseBusinessPhone?.[0]?.value ?? user?.phoneNumber,
    },
    enableReinitialize: true,
    isInitialValid: isInitialValidBusiness,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        shouldSaveResponse(TaxListQuestionId.business_name, 'business') &&
        values.businessName !== responseBusinessName?.[0]?.value
      ) {
        responseData.push({
          id: responseBusinessName?.[0]?.id,
          value: values.businessName,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.business_name,
        })
      }
      if (
        shouldSaveResponse(TaxListQuestionId.scorp_election_date, 'business') &&
        values.scorpElectionDate !== responseScorpElectionDate?.[0]?.value
      ) {
        responseData.push({
          id: responseScorpElectionDate?.[0]?.id,
          value: values.scorpElectionDate,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.scorp_election_date,
        })
      }
      if (
        shouldSaveResponse(TaxListQuestionId.ein, 'business') &&
        values.ein !== responseEin?.[0]?.value
      ) {
        responseData.push({
          id: responseEin?.[0]?.id,
          value: values.ein,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.ein,
        })
      }
      if (
        shouldSaveResponse(TaxListQuestionId.business_email, 'business') &&
        values.businessEmail !== responseBusinessEmail?.[0]?.value
      ) {
        responseData.push({
          id: responseBusinessEmail?.[0]?.id,
          value: values.businessEmail,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.business_email,
        })
      }
      if (
        shouldSaveResponse(TaxListQuestionId.business_address, 'business') &&
        values.businessAddress !== responseBusinessAddress?.[0]?.value
      ) {
        responseData.push({
          id: responseBusinessAddress?.[0]?.id,
          value: values.businessAddress,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.business_address,
        })
      }
      if (
        shouldSaveResponse(TaxListQuestionId.business_phone, 'business') &&
        values.businessPhone !== responseBusinessPhone?.[0]?.value
      ) {
        responseData.push({
          id: responseBusinessPhone?.[0]?.id,
          value: values.businessPhone,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.business_phone,
        })
      }
      const success = await goToNextStep(
        responseData,
        (currentScreen as T) ?? null
      )
      if (success) {
        setEditBusiness(false)
      }
    },
  })

  const { values: bizValues } = businessFormik
  const [editBusiness, setEditBusiness] = useState(!isInitialValidBusiness)
  const fieldNames = getFieldNames(businessFormik)

  const readOnlyBusiness = useMemo(() => {
    const defaultValues = [
      { ...questionBusinessName, responseText: bizValues.businessName },
      {
        ...questionScorpElectionDate,
        responseText: bizValues.scorpElectionDate,
      },
      { ...questionEin, responseText: bizValues.ein },
      { ...questionBusinessEmail, responseText: bizValues.businessEmail },
      { ...questionBusinessPhone, responseText: bizValues.businessPhone },
      { ...questionBusinessAddress, responseText: bizValues.businessAddress },
    ]
    const formName = filingForm?.formType?.name
    const relevantIds = isTaxEntityType(formName)
      ? getBusinessQuestionIds(formName)
      : []
    return defaultValues.filter(
      (qr) => qr.question?.id && relevantIds.includes(qr.question.id)
    )
  }, [
    bizValues.businessAddress,
    bizValues.businessEmail,
    bizValues.businessName,
    bizValues.businessPhone,
    bizValues.ein,
    bizValues.scorpElectionDate,
    filingForm?.formType?.name,
    questionBusinessAddress,
    questionBusinessEmail,
    questionBusinessName,
    questionBusinessPhone,
    questionEin,
    questionScorpElectionDate,
  ])

  const content = (
    <FormikProvider value={businessFormik}>
      <FormikScrollOnError />
      <Container text textAlign="left">
        <Grid>
          {/* READY ONLY BUSINESS FORM */}
          {!editBusiness && (
            <GridRowColumn>
              <ReadOnlyForm
                title="Business Details"
                setEditing={() => setEditBusiness(true)}
                readOnlyData={readOnlyBusiness}
              />
            </GridRowColumn>
          )}

          {/* EDITABLE BUSINESS FORM */}
          {editBusiness && (
            <GridRowColumn>
              <Card type="subsection" backgroundColor="stone40">
                <Grid>
                  <GridRowColumn>
                    <Text as="h2" color="forest">
                      Business Details
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Divider />
                  </GridRowColumn>
                  <GridRowColumn short>
                    <FormikInput
                      label={questionBusinessName?.question?.text}
                      name={fieldNames.businessName}
                      schema={makeReqStringSchema({
                        field: 'Business Name',
                      })}
                      fullWidth
                    />
                  </GridRowColumn>
                  {!is1040 && (
                    <>
                      <GridRowColumn width={8}>
                        <FormikDateInput
                          maxDate={new Date()}
                          label={questionScorpElectionDate?.question?.text}
                          placeholder="MM-DD-YYYY"
                          schema={makeDateSchema({
                            field: 'S-Corp Election Date',
                            format: DATE_FORMATS.INPUT,
                            strict: true,
                          })}
                          name={fieldNames.scorpElectionDate}
                          fullWidth
                          afterLabel={getPopupContent(
                            questionScorpElectionDate?.question?.id
                          )}
                        />
                      </GridRowColumn>
                      <GridRowColumn width={8}>
                        <FormikInput
                          label={questionEin?.question?.text}
                          name={fieldNames.ein}
                          schema={makeEinSchema({
                            field: 'ein',
                          })}
                          fullWidth
                          placeholder="12-3456789"
                          format="##-#######"
                          componentType="pattern"
                          afterLabel={getPopupContent(
                            questionEin?.question?.id
                          )}
                        />
                      </GridRowColumn>
                    </>
                  )}
                  <GridRowColumn>
                    <FormikInput
                      label={questionBusinessEmail?.question?.text}
                      name={fieldNames.businessEmail}
                      schema={makeReqStringSchema({
                        field: 'Business Email',
                      })}
                      fullWidth
                    />
                  </GridRowColumn>
                  <GridRowColumn width={8}>
                    <FormikInput
                      label={questionBusinessPhone?.question?.text}
                      schema={makeReqPhoneNumberSchema()}
                      name={fieldNames.businessPhone}
                      fullWidth
                      componentType="phone"
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <FormikLocationSearchInput
                      label="Business Address"
                      placeholder="Street address, city, state, zip code"
                      name={fieldNames.businessAddress}
                      schema={makeReqStringSchema({ field: 'Address' })}
                      fullWidth
                    />
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Divider />
                  </GridRowColumn>
                  <GridRowColumn
                    columnStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: 20,
                    }}
                  >
                    <Button
                      disabled={!isInitialValidBusiness}
                      onClick={() => {
                        businessFormik.resetForm()
                        setEditBusiness(false)
                      }}
                      style={{ border: 0 }}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={isUpdating || businessFormik.isSubmitting}
                      variant="secondary"
                      onClick={businessFormik.submitForm}
                      disabled={isUpdating || businessFormik.isSubmitting}
                    >
                      Save
                    </Button>
                  </GridRowColumn>
                </Grid>
              </Card>
            </GridRowColumn>
          )}
        </Grid>
      </Container>
    </FormikProvider>
  )

  return { content, businessFormik, editBusiness, shouldShowBusinessForm }
}

// skipcq: JS-D1001
const CheckYourDetailsPanel = <
  T extends
    | FORM_1120_S_SCREENS
    | FORM_1040_DETAILS_SCREENS
    | EXTENSION_REQUEST_SCREENS,
>({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  formId,
}: CheckDetailsProps<T>) => {
  const dispatch = useAppDispatch()
  const { currentScreen } = useSetScreen()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, taxYear)
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const is1040 = filingForm?.formType?.name === TAX_ENTITY_TYPES.form_1040
  const isBusinessFormOrSingleForm1040 = !is1040 || (is1040 && !isTwoFormFiler)
  const user = useReselector(getCurrentUser)
  const [error, setError] = useState<string>()
  const { errorAlert, triggerError } = useMissingAnswerValidation(
    'Please complete all sections and click "Save"'
  )

  const ssnMask = '***-**-****'
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  ])
  const { encrypt } = useEncryption()

  /* Questions */
  const questionFirstName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.first_name,
    taxYear
  )
  const responsesFirstName = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.first_name,
    Number(formId)
  )
  const questionLastName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.last_name,
    taxYear
  )
  const responsesLastName = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.last_name,
    Number(formId)
  )
  const questionDob = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.date_of_birth,
    taxYear
  )
  const responsesDob = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.date_of_birth,
    Number(formId)
  )
  const questionSsn = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.ssn,
    taxYear
  )
  const responsesSsn = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.ssn,
    Number(formId)
  )
  const questionOccupation = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.occupation,
    taxYear
  )
  const responsesOccupation = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.occupation,
    Number(formId)
  )
  const questionPersonalPhone = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.personal_phone,
    taxYear
  )
  const responsesPersonalPhone = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.personal_phone,
    Number(formId)
  )
  const questionHomeAddress = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_address,
    taxYear
  )
  const responsesHomeAddress = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_address,
    Number(formId)
  )

  const responseUpdated = (
    updateValue: TaxChecklistResponseValue | undefined,
    existingResponse: TaxChecklistResponse
  ) => {
    return updateValue !== null && updateValue !== existingResponse?.value
  }

  const isInitialValidPersonal = useMemo(() => {
    return Boolean(
      (responsesFirstName?.[0]?.value || user?.firstName) &&
        (responsesLastName?.[0]?.value || user?.lastName) &&
        (!is1040 || responsesDob?.[0]?.value || user?.dateOfBirth) &&
        (!isBusinessFormOrSingleForm1040 ||
          isEncrypted(responsesSsn?.[0]?.value)) &&
        (!is1040 || responsesOccupation?.[0]?.value) &&
        (!isBusinessFormOrSingleForm1040 ||
          responsesPersonalPhone?.[0]?.value ||
          user?.personalPhone) &&
        (!isBusinessFormOrSingleForm1040 ||
          responsesHomeAddress?.[0]?.value ||
          user?.homeAddress)
    )
  }, [
    responsesFirstName,
    responsesLastName,
    responsesDob,
    responsesSsn,
    responsesOccupation,
    responsesPersonalPhone,
    responsesHomeAddress,
    user,
    is1040,
    isBusinessFormOrSingleForm1040,
  ])

  const [editingPersonal, setEditingPersonal] = useState(
    !isInitialValidPersonal
  )

  const shouldSaveResponse = useCallback(
    (questionId: TaxListQuestionId, type: 'personal' | 'business') => {
      return shouldSaveResponseToQuestions(
        filingForm,
        questionId,
        type,
        isTwoFormFiler
      )
    },
    [filingForm, isTwoFormFiler]
  )

  const personalFormik = useFormik({
    initialValues: {
      firstName: responsesFirstName?.[0]?.value ?? user?.firstName,
      lastName: responsesLastName?.[0]?.value ?? user?.lastName,
      dob:
        responsesDob?.[0]?.value ??
        (user?.dateOfBirth ? formatISOFromUTC(user.dateOfBirth) : undefined),
      ssn: responsesSsn?.[0]?.value,
      confirmSsn: responsesSsn?.[0]?.value,
      occupation: responsesOccupation?.[0]?.value,
      personalPhone: responsesPersonalPhone?.[0]?.value ?? user?.personalPhone,
      address: responsesHomeAddress?.[0]?.value ?? user?.homeAddress,
    },
    enableReinitialize: true,
    isInitialValid: isInitialValidPersonal,
    onSubmit: async (values) => {
      setError(undefined)
      try {
        const responseData: Partial<TaxChecklistResponse>[] = []
        const userUpdates: Partial<UpdateUserPayload> = {}
        if (
          shouldSaveResponse(TaxListQuestionId.first_name, 'personal') &&
          responseUpdated(values.firstName, responsesFirstName?.[0]) &&
          typeof values.firstName === 'string'
        ) {
          responseData.push({
            id: responsesFirstName?.[0]?.id,
            value: values.firstName,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.first_name,
          })
          userUpdates.firstName = values.firstName
        }
        if (
          shouldSaveResponse(TaxListQuestionId.last_name, 'personal') &&
          responseUpdated(values.lastName, responsesLastName?.[0]) &&
          typeof values.lastName === 'string'
        ) {
          responseData.push({
            id: responsesLastName?.[0]?.id,
            value: values.lastName,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.last_name,
          })
          userUpdates.lastName = values.lastName
        }
        if (
          shouldSaveResponse(TaxListQuestionId.date_of_birth, 'personal') &&
          responseUpdated(values.dob, responsesDob?.[0]) &&
          typeof values.dob === 'string'
        ) {
          responseData.push({
            id: responsesDob?.[0]?.id,
            value: values.dob,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.date_of_birth,
          })
          userUpdates.dateOfBirth = DateTime.fromFormat(
            values.dob,
            DATE_FORMATS_LUXON.INPUT
          ).toISO()
        }
        if (
          shouldSaveResponse(TaxListQuestionId.ssn, 'personal') &&
          responseUpdated(values.ssn, responsesSsn?.[0]) &&
          typeof values.ssn === 'string'
        ) {
          const encryptedSsn = await encrypt(values.ssn)
          responseData.push({
            id: responsesSsn?.[0]?.id,
            value: encryptedSsn,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.ssn,
          })
        }
        if (
          shouldSaveResponse(TaxListQuestionId.occupation, 'personal') &&
          responseUpdated(values.occupation, responsesOccupation?.[0])
        ) {
          responseData.push({
            id: responsesOccupation?.[0]?.id,
            value: values.occupation,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.occupation,
          })
        }
        if (
          shouldSaveResponse(TaxListQuestionId.personal_phone, 'personal') &&
          responseUpdated(values.personalPhone, responsesPersonalPhone?.[0]) &&
          typeof values.personalPhone === 'string'
        ) {
          responseData.push({
            id: responsesPersonalPhone?.[0]?.id,
            value: values.personalPhone,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.personal_phone,
          })
          userUpdates.personalPhone = values.personalPhone
        }
        if (
          shouldSaveResponse(TaxListQuestionId.home_address, 'personal') &&
          responseUpdated(values.address, responsesHomeAddress?.[0]) &&
          typeof values.address === 'string'
        ) {
          responseData.push({
            id: responsesHomeAddress?.[0]?.id,
            value: values.address,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.home_address,
          })
          userUpdates.homeAddress = values.address
        }
        if (user?.id && Object.keys(userUpdates).length > 0) {
          await dispatch(updateUser(user.id, userUpdates))
        }
        const success = await goToNextStep(
          responseData,
          (currentScreen as T) ?? null
        )
        if (success) {
          // skipcq: JS-0357 with the way formik is set up, this needs to be defined after
          setEditingPersonal(false)
        }
        return true
      } catch (err) {
        if (err instanceof EncryptionError) {
          setError(err.displayMessage)
        } else {
          setError('An error occurred. Please try again.')
        }
        setEditingPersonal(true)
        return false
      }
    },
  })

  const { values } = personalFormik
  const fieldNames = getFieldNames(personalFormik)

  const progressSteps = useMemo(() => {
    if (is1040) {
      return [SubStepIdentifiers.checkDetails1040]
    }
    return [SubStepIdentifiers.checkDetails1120s]
  }, [is1040])

  const {
    content: businessContent,
    editBusiness,
    businessFormik,
    shouldShowBusinessForm,
  } = useBusinessDetails({
    is1040,
    isUpdating,
    isTwoFormFiler,
    formId,
    goToNextStep,
  })

  const continueSurvey = useAsyncCallback(async () => {
    if (
      personalFormik.isValid &&
      (!shouldShowBusinessForm || businessFormik.isValid)
    ) {
      await personalFormik.submitForm()
      if (shouldShowBusinessForm) {
        await businessFormik.submitForm()
      }
      return goToNextStep(null, nextScreen ?? null, {
        completedSteps: progressSteps,
      })
    }
    return false
  })

  const readOnlyPersonal = useMemo(() => {
    const defaultValues = [
      { ...questionFirstName, responseText: values.firstName },
      { ...questionLastName, responseText: values.lastName },
      { ...questionDob, responseText: values.dob },
      {
        ...questionSsn,
        responseText: values.ssn ? ssnMask : '',
      },
      { ...questionOccupation, responseText: values.occupation },
      { ...questionPersonalPhone, responseText: values.personalPhone },
      { ...questionHomeAddress, responseText: values.address },
    ]
    const formName = filingForm?.formType?.name
    const relevantIds = isTaxEntityType(formName)
      ? getPersonalQuestionIds(formName, isTwoFormFiler)
      : []
    return defaultValues.filter(
      (qr) => qr.question?.id && relevantIds.includes(qr.question.id)
    )
  }, [
    values,
    questionFirstName,
    questionLastName,
    questionDob,
    questionSsn,
    questionOccupation,
    questionPersonalPhone,
    questionHomeAddress,
    filingForm,
    isTwoFormFiler,
  ])

  return (
    // skipcq: JS-0415
    <>
      {((!personalFormik.isValid && !editingPersonal) ||
        (!businessFormik.isValid && !editBusiness)) && (
        <Alert type="error">
          Something went wrong. Please refresh the page and try again.
        </Alert>
      )}
      {errorAlert}
      <FormikProvider value={personalFormik}>
        <FormikScrollOnError />
        <Container style={{ marginTop: 56 }} text textAlign="left">
          <Grid>
            <GridRowColumn>
              <Text as="display2" textAlign="center">
                Confirm Details
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                Please look over the following and update anything that has
                changed. These answers will appear on your tax return.
              </Text>
            </GridRowColumn>
            {/* READ ONLY PERSONAL FORM */}
            {!editingPersonal && (
              <GridRowColumn>
                <ReadOnlyForm
                  title="Personal Details"
                  setEditing={() => setEditingPersonal(true)}
                  readOnlyData={readOnlyPersonal}
                />
              </GridRowColumn>
            )}
            {/* EDITABLE PERSONAL FORM */}
            {editingPersonal && (
              <GridRowColumn>
                <Card type="subsection" backgroundColor="stone40">
                  <Grid>
                    <GridRowColumn>
                      <Text as="h2">Personal Details</Text>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Divider />
                    </GridRowColumn>
                    <Grid.Row className="short">
                      <Grid.Column width={8}>
                        <FormikInput
                          label={questionFirstName?.question?.text}
                          name={fieldNames.firstName}
                          schema={makeReqStringSchema({ field: 'First Name' })}
                          fullWidth
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormikInput
                          label={questionLastName?.question?.text}
                          name={fieldNames.lastName}
                          schema={makeReqStringSchema({ field: 'Last Name' })}
                          fullWidth
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {is1040 && (
                      <GridRowColumn width={8}>
                        <FormikDateInput
                          maxDate={new Date()}
                          label={questionDob?.question?.text}
                          placeholder="MM-DD-YYYY"
                          schema={makeDateSchema({
                            field: 'Date of Birth',
                            format: DATE_FORMATS.INPUT,
                            strict: true,
                          })}
                          name={fieldNames.dob}
                          fullWidth
                        />
                      </GridRowColumn>
                    )}
                    {isBusinessFormOrSingleForm1040 && (
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FormikSSNInput
                            label={questionSsn?.question?.text}
                            name={fieldNames.ssn}
                            fullWidth
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FormikSSNInput
                            label={`Confirm ${questionSsn?.question?.text}`}
                            name={fieldNames.confirmSsn}
                            fullWidth
                            confirmationValue={values.ssn}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    {is1040 && (
                      <GridRowColumn>
                        <FormikInput
                          label={questionOccupation?.question?.text}
                          name={fieldNames.occupation}
                          schema={makeReqStringSchema({ field: 'Occupation' })}
                          fullWidth
                        />
                      </GridRowColumn>
                    )}
                    {isBusinessFormOrSingleForm1040 && (
                      <>
                        <GridRowColumn width={8}>
                          <FormikInput
                            label={questionPersonalPhone?.question?.text}
                            schema={makeReqPhoneNumberSchema()}
                            name={fieldNames.personalPhone}
                            fullWidth
                            componentType="phone"
                          />
                        </GridRowColumn>
                        <GridRowColumn>
                          <FormikLocationSearchInput
                            label="Personal Home Address"
                            placeholder="Street address, city, state, zip code"
                            name={fieldNames.address}
                            schema={makeReqStringSchema({ field: 'Address' })}
                            fullWidth
                          />
                        </GridRowColumn>
                      </>
                    )}
                    {error && (
                      <GridRowColumn>
                        <Text color="red">{error}</Text>
                      </GridRowColumn>
                    )}
                    <GridRowColumn short>
                      <Divider />
                    </GridRowColumn>
                    <GridRowColumn
                      columnStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 20,
                      }}
                    >
                      <Button
                        disabled={!isInitialValidPersonal}
                        onClick={() => {
                          personalFormik.resetForm()
                          setEditingPersonal(false)
                        }}
                        style={{ border: 0 }}
                        variant="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={isUpdating || personalFormik.isSubmitting}
                        variant="secondary"
                        disabled={isUpdating || personalFormik.isSubmitting}
                        onClick={personalFormik.submitForm}
                      >
                        Save
                      </Button>
                    </GridRowColumn>
                  </Grid>
                </Card>
              </GridRowColumn>
            )}
          </Grid>
        </Container>
      </FormikProvider>
      {shouldShowBusinessForm && businessContent}
      <Grid>
        <FormFlowFooter
          continueDisabled={
            isUpdating ||
            personalFormik.isSubmitting ||
            businessFormik.isSubmitting ||
            continueSurvey.loading
          }
          loading={continueSurvey.loading}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={
            (shouldShowBusinessForm && editBusiness) || editingPersonal
              ? triggerError
              : continueSurvey.callback
          }
        />
      </Grid>
    </>
  )
}

export default CheckYourDetailsPanel
