import { createSelector } from 'reselect'
import { ReduxState } from '../../utils/typeHelpers'
import { DateTime } from 'luxon'
import { selectCurrentAnnualTaxYear } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const selectUserCoupons = (state: ReduxState) => state.userCoupons
export const selectTaxAdvisoryCoupons = (state: ReduxState) =>
  Object.values(state.userCoupons.taxAdvisory)

/**
 * Sorted by redeemedAt descending (oldest coupon last in list)
 */
export const selectRedeemedTaxAdvisoryCouponsForTaxYear = createSelector(
  selectCurrentAnnualTaxYear,
  selectTaxAdvisoryCoupons,
  (taxYear, taxAdvisoryCoupons) => {
    return taxAdvisoryCoupons
      .filter(
        (coupon) =>
          coupon.redeemedAt &&
          DateTime.fromISO(coupon.redeemedAt).year === Number(taxYear) + 1
      )
      .sort((a, b) => {
        const aDate = (a.redeemedAt && DateTime.fromISO(a.redeemedAt)) || null
        const bDate = (b.redeemedAt && DateTime.fromISO(b.redeemedAt)) || null
        if (aDate && bDate) {
          return bDate.diff(aDate).milliseconds
        }
        return 0
      })
  }
)

export const selectOldestRedeemedTaxAdvisoryCoupon = createSelector(
  selectRedeemedTaxAdvisoryCouponsForTaxYear,
  (taxAdvisoryCoupons) =>
    taxAdvisoryCoupons[taxAdvisoryCoupons.length - 1] ?? null
)
