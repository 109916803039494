import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReselector } from '../../utils/sharedHooks'
import {
  ADMIN_REMOVE_COUPON_KEY,
  adminRemoveCoupon,
  fetchAdminUserCoupons,
} from './adminUserCoupons.actions'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  GridRowColumn,
  Table,
  Text,
} from '../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'
import { UserCoupon, UserCouponProductName } from './userCoupons.slice'
import { formatCurrency } from '../../utils/currencyHelpers'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import AdminAddCouponModal from './AdminAddCouponModal'
import { useAppDispatch } from '../../utils/typeHelpers'
import { getFetchError } from '../../reducers/fetch'

const CouponTable = ({
  coupons,
  deleteCoupon,
}: {
  coupons: UserCoupon[]
  deleteCoupon: (userCouponId: number) => void
}) => {
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Discount</Table.HeaderCell>
          <Table.HeaderCell>Redeemed</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {coupons.map((coupon, index) => (
          <Table.Row key={`${coupon.id}-${index}`}>
            {
              <Table.Cell>
                {coupon.productName !== UserCouponProductName.taxAdvisory
                  ? coupon.productName
                  : `${coupon.productName} - ${coupon.metadata?.durationInMinutes} minutes`}
              </Table.Cell>
            }
            <Table.Cell>
              {coupon.amountOff
                ? formatCurrency(coupon.amountOff)
                : coupon.percentOff
                  ? `${coupon.percentOff}%`
                  : 'N/A'}
            </Table.Cell>
            <Table.Cell>
              {coupon.redeemedAt
                ? DateTime.fromISO(coupon.redeemedAt).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_FULL
                  )
                : ''}
            </Table.Cell>
            <Table.Cell>
              {!coupon.redeemedAt && (
                <Button onClick={() => deleteCoupon(coupon.id)} variant="link">
                  Remove
                </Button>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const AdminUserCouponAssignment = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [showRedeemed, setShowRedeemed] = useState(false)
  const [couponsForUser, setCouponsForUser] = useState<UserCoupon[]>([])
  const unredeemedCoupons = useMemo(
    () => couponsForUser.filter((c) => !c.redeemedAt),
    [couponsForUser]
  )
  const deleteError = useReselector(getFetchError, ADMIN_REMOVE_COUPON_KEY)

  const deleteCoupon = useCallback(
    async (userCouponId: number) => {
      const res = await adminRemoveCoupon({ userId, userCouponId })(dispatch)
      if (res?.deletedId) {
        setCouponsForUser([
          ...couponsForUser.filter((coupon) => coupon.id !== res.deletedId),
        ])
      }
    },
    [couponsForUser, dispatch, userId]
  )

  const closeModal = useCallback(
    (newCoupon?: UserCoupon) => {
      // add coupon
      if (newCoupon) {
        setCouponsForUser([...couponsForUser, newCoupon])
      }
      setOpenModal(false)
    },
    [couponsForUser]
  )

  useEffect(() => {
    const loadCoupons = async () => {
      const res = await fetchAdminUserCoupons(userId, {
        includeRedeemed: true,
      })(dispatch)
      if (res) {
        setCouponsForUser(res)
      }
    }

    loadCoupons()
  }, [dispatch, userId])

  return (
    <Card fullWidth>
      <Grid>
        <GridRowColumn>
          <Text as="h2">User Coupons</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Checkbox
            label="Show Redeemed"
            value={showRedeemed}
            onChange={() => setShowRedeemed(!showRedeemed)}
            toggle
          />
        </GridRowColumn>
        <GridRowColumn>
          <CouponTable
            deleteCoupon={deleteCoupon}
            coupons={showRedeemed ? couponsForUser : unredeemedCoupons}
          />
        </GridRowColumn>
        {deleteError?.message && (
          <GridRowColumn>
            <Alert type="error">{deleteError.message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn short centerContent>
          <Button variant="primary" onClick={() => setOpenModal(true)}>
            Add Coupon +
          </Button>
        </GridRowColumn>
      </Grid>
      <AdminAddCouponModal
        userId={userId}
        open={openModal}
        close={closeModal}
      />
    </Card>
  )
}

export default AdminUserCouponAssignment
