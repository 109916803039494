import { Alert } from '../../../../../components/BaseComponents'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../../utils/dateHelpers'
import {
  EndOfYearReviewSubmission,
  EndOfYearSubmissionStatus,
} from '../../types'
import {
  EOYBookkeepingFollowupSubstep,
  YearEndModuleStatusOptions,
} from '../../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import './styles.scss'

export type SubmissionBannerProps = {
  submission: EndOfYearReviewSubmission | undefined | null
}
const SubmissionBanner = ({ submission }: SubmissionBannerProps) => {
  if (
    submission?.followupStatus === YearEndModuleStatusOptions.upcoming &&
    submission?.userSubmissionCount > 0
  ) {
    return (
      <Alert
        title="User does not have a verified S-Corp Election Date"
        closeable={false}
        type="warn"
        className="eoy-admin__submission-banner-followup"
      >
        Please wait for this user&apos;s status to enter READY FOR REVIEW to
        action.
      </Alert>
    )
  }

  if (
    submission?.status === EndOfYearSubmissionStatus.waitingOnHeard &&
    submission?.substepIdentifier ===
      EOYBookkeepingFollowupSubstep.balanceSheetGeneration
  ) {
    return (
      <Alert
        title="Manual Balance Sheet Required"
        closeable={false}
        type="warn"
        className="eoy-admin__submission-banner-followup"
      >
        This user requires a manual balance sheet. Once you&apos;re done, click
        Complete to complete their bookkeeping.
      </Alert>
    )
  }

  if (submission?.status === EndOfYearSubmissionStatus.actionRequired) {
    return (
      <Alert
        title="Waiting on user follow-up"
        closeable={false}
        type="info"
        className="eoy-admin__submission-banner-followup"
      >
        Please wait for them to complete follow-up before continuing with their
        year-end bookkeeping.
      </Alert>
    )
  }

  if (submission?.status === EndOfYearSubmissionStatus.complete) {
    const date = isoToUTCDateTime(
      submission.lastUserSubmittedAt ?? submission.updatedAt
    ).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)

    return (
      <Alert
        title="Submission complete"
        closeable={false}
        type="success"
        className="eoy-admin__submission-banner-complete"
      >
        The user completed their year-end bookkeeping on {date}.
      </Alert>
    )
  }

  return null
}

export default SubmissionBanner
