import { Grid } from 'semantic-ui-react'

import { Card, GridRowColumn, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import LearnWithHeardItem, {
  LearnWithHeardItemProps,
} from './LearnWithHeardItem'
import {
  BookkeepingOnboardingStep,
  OnboardingStepPhase,
  TaxesOnboardingStep,
} from '../../Onboarding/UserOnboardingSteps/onboarding.reducer'

const LearnWithHeard = () => {
  const LearnWithHeardItems: LearnWithHeardItemProps[] = [
    {
      key: 'bookkeeping',
      imageBackgroundColor: Colors.sunrise,
      imageSrc:
        'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.png',
      imageAlt: 'books',
      title: 'Bookkeeping',
      description:
        'Learn why bookkeeping matters, how we help you save time, and what you need to do on a monthly basis.',
      navigateTo: '/onboarding-v2/learning/bookkeeping',
      timeItTakes: '5 minutes',
      onboardingStepPhase: OnboardingStepPhase.ahaMomentBookkeeping,
      completionStep: BookkeepingOnboardingStep.transactionsClarifiedSuccess,
    },
    {
      key: 'readyForTaxes',
      imageBackgroundColor: Colors.lightGreen,
      imageSrc:
        'https://heard-images.s3.amazonaws.com/assets/calculator_list.svg',
      imageAlt: 'calculator',
      title: 'Get ready for taxes',
      description:
        'Understand the tax process, how we help you avoid penalties, and how we maximize your tax savings.',
      navigateTo: '/onboarding-v2/learning/taxes',
      timeItTakes: '5 minutes',
      onboardingStepPhase: OnboardingStepPhase.ahaReadyForTaxes,
      completionStep: TaxesOnboardingStep.taxesSuccess,
    },
  ]

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h1">Learn with Heard</Text>
        <Text>Set your therapy practice up for success.</Text>
      </GridRowColumn>
      <Card.Group itemsPerRow={LearnWithHeardItems.length > 2 ? 3 : 2}>
        {LearnWithHeardItems.map((item) => (
          <LearnWithHeardItem {...item} key={item.key} />
        ))}
      </Card.Group>
    </Grid>
  )
}

export default LearnWithHeard
