import { useCallback, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  Dropdown,
  GridRowColumn,
  Loader,
  Modal,
  Text,
} from '../../components/BaseComponents'
import { useFetchResponse, useReselector } from '../../utils/sharedHooks'
import {
  ADMIN_ADD_COUPON_KEY,
  adminAddCoupon,
  FETCH_USER_COUPON_TYPES_KEY,
  fetchUserCouponTypes,
} from './adminUserCoupons.actions'
import { useAppDispatch } from '../../utils/typeHelpers'
import { UserCoupon, UserCouponProductName } from './userCoupons.slice'
import { getFetchError, getIsFetching } from '../../reducers/fetch'
import { Grid } from 'semantic-ui-react'

const AdminAddCouponModal = ({
  userId,
  open,
  close,
}: {
  userId: number
  open: boolean
  close: (newCoupon?: UserCoupon) => void
}) => {
  const dispatch = useAppDispatch()
  const [error, setError] = useState<string>()
  const userCouponTypes = useFetchResponse(fetchUserCouponTypes)
  const loading = useReselector(getIsFetching, FETCH_USER_COUPON_TYPES_KEY)
  const typeDropDownOptions = useMemo(
    () =>
      userCouponTypes?.taxConsultationCoupons.map((coupon) => ({
        key: coupon.id,
        text: coupon.name,
        value: coupon.id,
      })) ?? [],
    [userCouponTypes]
  )
  const [selectedType, setSelectedType] = useState<string>()
  const closeModal = useCallback(
    (userCoupon?: UserCoupon) => {
      setError(undefined)
      setSelectedType(undefined)
      close(userCoupon)
    },
    [close]
  )
  const updateError = useReselector(getFetchError, ADMIN_ADD_COUPON_KEY)

  const createCoupon = useCallback(async () => {
    setError(undefined)
    const productName = userCouponTypes?.taxConsultationCoupons.find(
      (coupon) => coupon.id === selectedType
    )
      ? UserCouponProductName.taxAdvisory
      : ''
    if (!productName) {
      setError('Invalid coupon type')
      return false
    }
    if (!selectedType) {
      setError('Please select a coupon type')
      return false
    }
    const res = await adminAddCoupon({
      userId,
      couponId: selectedType,
      productName,
    })(dispatch)
    if (res) {
      closeModal(res)
      return true
    }
    return false
  }, [
    dispatch,
    selectedType,
    userId,
    userCouponTypes?.taxConsultationCoupons,
    closeModal,
  ])

  return (
    <Modal size="tiny" open={open} onClose={() => closeModal()} closeIcon>
      <Modal.Header>
        <Text as="h2">Add User Coupon for User {userId}</Text>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Loader loading={loading} />
          <GridRowColumn>
            <Dropdown
              fullWidth
              label="Coupon Type"
              options={typeDropDownOptions}
              value={selectedType}
              onChange={(value) => setSelectedType(value)}
              placeholder="Select a coupon"
            />
          </GridRowColumn>
          {(error || updateError?.message) && (
            <GridRowColumn short>
              <Alert type="error">{error ?? updateError?.message}</Alert>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondaryLink" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={createCoupon}>
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AdminAddCouponModal
