import {
  YearEndModuleStatusOptions,
  FormFilingJobSubstep,
  FormFilingJobSubstepId,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  getDefaultButtonText,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
  getModuleComplementaryText,
} from '../yearEndModuleStatus.helpers'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../utils/dateHelpers'

const getDescription = (
  status: YearEndModuleStatusOptions,
  isTwoFormFiler: boolean,
  filingJobSubstepId?: FormFilingJobSubstepId | null,
  paymentDueDate?: string | null
) => {
  switch (status) {
    case YearEndModuleStatusOptions.upcoming: {
      const liveConsultText =
        'Complete your other tasks to reserve a live consultation with your tax preparer.'
      const bodyText = isTwoFormFiler ? (
        <>
          After finishing your business tax return, your tax preparer will help
          you finalize and file your personal tax return.
          <div style={{ paddingTop: 16 }} />
          {liveConsultText}
        </>
      ) : (
        <>
          We’ll connect you with a tax preparer from our partner TaxFyle.
          They’ll work with you to finalize and submit your return.
          <div style={{ paddingTop: 16 }} />
          {liveConsultText}
        </>
      )
      return (
        <UpcomingStatusTextWrapper moduleType={YearEndModuleType.file1040}>
          {bodyText}
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      switch (filingJobSubstepId) {
        case FormFilingJobSubstep.jobStarted:
          return 'Your return has been started.'
        case FormFilingJobSubstep.notClaimed:
          return 'We’re matching you to a tax preparer who will handle your tax return. Your bookkeeping and tax information will be sent to them.'
        case FormFilingJobSubstep.inProgress:
          return 'Your tax preparer is currently working on your tax return. They’ll reach out if they have any questions.'
        case FormFilingJobSubstep.draftApproved:
          return 'Your tax preparer will now send you a form to sign to give them permission to e-file on your behalf.'
        case FormFilingJobSubstep.signedAndApproved:
          return 'We’re filing your Form 1040 with the IRS.'
        default:
          return 'Your tax preparer is working on your return.'
      }
    }
    case YearEndModuleStatusOptions.actionRequired: {
      switch (filingJobSubstepId) {
        case FormFilingJobSubstep.startedUserCTA:
          return 'Your return has been started.'
        case FormFilingJobSubstep.inProgressNeedsUserAction:
          return 'Your tax preparer has questions about your information.'
        case FormFilingJobSubstep.draftReadyForReview:
          return 'Your tax return draft is ready for review.'
        case FormFilingJobSubstep.signatureRequested:
          return 'Your tax preparer has sent you a form to sign to give them permission to e-file your return on your behalf.'
        case FormFilingJobSubstep.updatePaymentInfo: {
          const paymentDueDateFormatted = paymentDueDate
            ? convertUtcToLocalDate(paymentDueDate)?.toFormat(
                DATE_FORMATS_LUXON.MONTH_DAY_LONG
              )
            : 'April 15'
          return (
            <>
              Your Form 1040 has been successfully filed with the IRS. Please
              update Heard with the amount of your tax payment or refund.
              <br />
              <br />
              <b>If you owe, you must pay by {paymentDueDateFormatted}</b>.{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/21886543684119-How-to-pay-federal-income-tax"
                newPage
                size="small"
                style={{
                  textDecoration: 'underline',
                  color: Colors.charcoal,
                  textDecorationColor: Colors.charcoal,
                }}
              >
                How to pay federal income tax
              </Link>
            </>
          )
        }
        case FormFilingJobSubstep.rejectedByIRS:
          return (
            <>
              Unfortunately, your tax return was <b>rejected</b> by the IRS.
              Please work with your tax preparer to resolve any issues with your
              return.
            </>
          )
        default:
          return 'Your tax return is being processed.'
      }
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Your tax return has been filed.'
    }
    case YearEndModuleStatusOptions.cancelled: {
      return 'Your tax filing has been cancelled. No further action needed.'
    }
    default:
      return null
  }
}

const getAlertBody = (dueDate: string | null) => {
  const daysUntilDueDate = getDeadlineApproachingDaysUntilDueDate(dueDate)
  if (daysUntilDueDate === null) {
    return null
  }
  const link = (
    <Link
      href="https://support.joinheard.com/hc/en-us/articles/16958669406743-Why-did-I-receive-an-IRS-underpayment-penalty"
      newPage
      size="small"
      style={{
        textDecoration: 'underline',
        color: Colors.charcoal,
        textDecorationColor: Colors.charcoal,
      }}
    >
      More info
    </Link>
  )
  if (daysUntilDueDate <= 0) {
    return (
      <Text as="bodySm">
        After that, you may be at risk of penalties and interest. {link}
      </Text>
    )
  }
  return (
    <Text as="bodySm">
      This tax return is now overdue. You may be at risk of penalties and
      interest. Please file it ASAP to avoid additional fines. {link}
    </Text>
  )
}

const getButtonText = (
  status: YearEndModuleStatusOptions,
  substepIdentifier: FormFilingJobSubstepId | null | undefined
) => {
  if (substepIdentifier === FormFilingJobSubstep.updatePaymentInfo) {
    return 'Update Payment or Refund'
  }
  return getDefaultButtonText(status)
}

const File1040Card = ({
  status,
  dueDate,
  taxYear,
  isTwoFormFiler,
  substepIdentifier,
  paymentDueDate,
}: YearEndModuleStatusDynamicDataParams & {
  isTwoFormFiler: boolean
  paymentDueDate?: string | null
}) => {
  const substepId = substepIdentifier as
    | FormFilingJobSubstepId
    | null
    | undefined
  const description = getDescription(
    status,
    isTwoFormFiler,
    substepId,
    paymentDueDate
  )
  const { onActionClick, modal } = useModuleActionButtonBehavior(
    YearEndModuleType.file1040,
    status,
    null, // substep url is not used for this module in year end home card
    substepId
  )
  const showButtonOn =
    substepIdentifier !== FormFilingJobSubstep.notClaimed
      ? [
          YearEndModuleStatusOptions.upcoming,
          YearEndModuleStatusOptions.actionRequired,
          YearEndModuleStatusOptions.waitingOnHeard,
          YearEndModuleStatusOptions.cancelled,
        ]
      : []

  return (
    <>
      <YearEndModuleBaseCard
        key={YearEndModuleType.file1040}
        status={status}
        dueDate={dueDate}
        substepIdentifier={substepIdentifier}
        taxYear={taxYear}
        header={getModuleNameCopy(YearEndModuleType.file1040)}
        imageUrl="https://heard-images.s3.amazonaws.com/assets/1040-form.svg"
        description={<Text as="bodySm">{description}</Text>}
        timer={{
          unit: 'days',
          value: '7-10',
          complementaryText: getModuleComplementaryText(
            YearEndModuleType.file1040
          ),
        }}
        deadlineApproaching={{
          ...defaultDeadlineApproaching,
          alertBody: getAlertBody(dueDate),
        }}
        button={{
          showOn: showButtonOn,
          text: getButtonText(status, substepId),
          onActionClick,
        }}
      />
      {modal}
    </>
  )
}

export default File1040Card
