import {
  YearEndModuleType,
  YearEndModuleStatusOptions,
  EOYBookkeepingFollowupSubstep,
  FormFilingJobSubstep,
  YearEndModuleSubstepId,
} from './yearEndModuleStatus.slice'
import { Icon } from '../../components/BaseComponents'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Colors } from '../../styles/theme'
import { DATE_FORMATS_LUXON, isoToLocalDate } from '../../utils/dateHelpers'
import { ReactNode } from 'react'
import { getModuleNameCopy } from './yearEndModuleStatus.helpers'

interface NextStepStatusPillProps {
  moduleType?: YearEndModuleType
  status?: YearEndModuleStatusOptions
  dueDate?: string | null
  substepIdentifier?: YearEndModuleSubstepId | null
}

export interface NextStepPill {
  icon: ReactNode
  title: string | null
  subtext: string | null
  backgroundColor: keyof typeof Colors
  noLink?: boolean
}

export interface NextStepPillWithClickBehavior extends NextStepPill {
  onActionClick: () => void
  modal?: ReactNode
}

const SealExclamationIcon = <Icon icon={solid('seal-exclamation')} />
const HourglassIcon = <Icon icon={solid('hourglass')} />
const CommentQuestionIcon = <Icon icon={solid('comment-question')} />
const SignatureIcon = <Icon icon={solid('signature')} />

const PILLS = {
  BK_ACTION_REQUIRED_FOLLOW_UP: {
    title: 'Action Required',
    subtext: 'Follow-up required to complete bookkeeping.',
    icon: SealExclamationIcon,
  },
  BK_ACTION_REQUIRED_REVIEW_AND_SIGN_OFF: {
    title: 'Action Required',
    subtext: 'Review and Sign off on your books.',
    icon: SealExclamationIcon,
  },
  RESPOND_TO_TAX_PREPARER_QUESTIONS_JOB_STARTED: {
    title: 'Respond to tax preparer',
    subtext: 'Your return has been started.',
    icon: SealExclamationIcon,
  },
  RESPOND_TO_TAX_PREPARER_QUESTIONS: {
    title: 'Respond to tax preparer',
    subtext: 'They have questions about your information.',
    icon: CommentQuestionIcon,
  },
  RESPOND_TO_TAX_PREPARER_DRAFT_READY: {
    title: 'Respond to tax preparer',
    subtext: 'Your tax return draft is ready for review.',
    icon: SealExclamationIcon,
  },
  RESPOND_TO_TAX_PREPARER_SIGN_AND_SUBMIT: {
    title: 'Respond to tax preparer',
    subtext: 'Sign and submit your tax return.',
    icon: SignatureIcon,
  },
  NO_ACTION_NEEDED_AT_THIS_TIME: {
    title: null,
    subtext: 'No action needed at this time.',
    icon: HourglassIcon,
    backgroundColor: Colors.lightGray,
    noLink: true,
  },
  TAX_RETURN_DRAFT_IN_PROGRESS: {
    title: null,
    subtext: 'Your tax return draft is in progress.',
    icon: HourglassIcon,
    noLink: true,
  },
  PREPARE_TAX_FILING_FOR_SIGNATURE: {
    title: null,
    subtext: 'Preparing tax filing for your signature.',
    icon: HourglassIcon,
    noLink: true,
  },
  EXTENSION_REQUEST_IN_PROGRESS: {
    title: null,
    subtext: 'Your extension request is in progress.',
    icon: HourglassIcon,
    noLink: true,
  },
  START_TAX_SEASON_KICKOFF: {
    title: 'Start Tax Season Kickoff',
    subtext: 'Let us know your end of year tax needs',
    icon: <Icon icon={solid('sparkles')} />,
  },
  NO_ACTION_REQUIRED: {
    title: 'No action required',
    subtext: 'We’ll let you know if we need anything else from you.',
    icon: HourglassIcon,
    noLink: true,
  },
  YOUR_NEXT_STEP: {
    title: 'Your next step',
    subtext: null,
    icon: <Icon icon={solid('sparkles')} />,
  },
  CONTINUE_WORKING_ON: {
    title: 'Continue working on',
    subtext: null,
    icon: <Icon icon={solid('pen')} />,
  },
  UPDATE_HEARD_WITH_PAYMENT: {
    title: 'Update Heard with a refund or payment amount',
    subtext:
      'Let us know if you got a refund or had to pay a balance for your personal tax return.',
    icon: <Icon icon={solid('dollar-sign')} />,
  },
}

const handleEOYBookkeepingFollowup = (
  status: YearEndModuleStatusOptions,
  substepIdentifier: YearEndModuleSubstepId | null | undefined
): NextStepPill => {
  if (status === YearEndModuleStatusOptions.actionRequired) {
    if (substepIdentifier === EOYBookkeepingFollowupSubstep.finalUserApproval) {
      return {
        ...PILLS.BK_ACTION_REQUIRED_REVIEW_AND_SIGN_OFF,
        backgroundColor: 'sunrise',
      }
    }
    return {
      ...PILLS.BK_ACTION_REQUIRED_FOLLOW_UP,
      backgroundColor: 'sunrise',
    }
  }
  return {
    ...PILLS.NO_ACTION_NEEDED_AT_THIS_TIME,
    backgroundColor: 'stone',
  }
}

const handleExtensionJob = (
  status: YearEndModuleStatusOptions
): NextStepPill => {
  if (status === YearEndModuleStatusOptions.actionRequired) {
    return {
      ...PILLS.RESPOND_TO_TAX_PREPARER_QUESTIONS,
      backgroundColor: 'sunrise',
    }
  }
  return {
    ...PILLS.EXTENSION_REQUEST_IN_PROGRESS,
    backgroundColor: 'stone',
  }
}

const handleFilingJobs = (
  status: YearEndModuleStatusOptions,
  substepIdentifier: YearEndModuleSubstepId | null | undefined
): NextStepPill => {
  if (status === YearEndModuleStatusOptions.actionRequired) {
    if (substepIdentifier === FormFilingJobSubstep.startedUserCTA) {
      return {
        ...PILLS.RESPOND_TO_TAX_PREPARER_QUESTIONS_JOB_STARTED,
        backgroundColor: 'sunrise',
      }
    } else if (substepIdentifier === FormFilingJobSubstep.draftReadyForReview) {
      return {
        ...PILLS.RESPOND_TO_TAX_PREPARER_DRAFT_READY,
        backgroundColor: 'sunrise',
      }
    } else if (substepIdentifier === FormFilingJobSubstep.signatureRequested) {
      return {
        ...PILLS.RESPOND_TO_TAX_PREPARER_SIGN_AND_SUBMIT,
        backgroundColor: 'sunrise',
      }
    } else if (substepIdentifier === FormFilingJobSubstep.updatePaymentInfo) {
      return {
        ...PILLS.UPDATE_HEARD_WITH_PAYMENT,
        backgroundColor: 'sunrise',
      }
    }
    // Default to respond to tax preparer questions if none of the above
    return {
      ...PILLS.RESPOND_TO_TAX_PREPARER_QUESTIONS,
      backgroundColor: 'sunrise',
    }
  }
  // Waiting on heard (tax preparer)
  if (substepIdentifier === FormFilingJobSubstep.inProgress) {
    return {
      ...PILLS.TAX_RETURN_DRAFT_IN_PROGRESS,
      backgroundColor: 'stone',
    }
  } else if (substepIdentifier === FormFilingJobSubstep.draftApproved) {
    return {
      ...PILLS.PREPARE_TAX_FILING_FOR_SIGNATURE,
      backgroundColor: 'stone',
    }
  }
  return {
    ...PILLS.NO_ACTION_NEEDED_AT_THIS_TIME,
    backgroundColor: 'stone',
  }
}

const getNextStepStatusPill = ({
  moduleType,
  status,
  dueDate,
  substepIdentifier,
}: NextStepStatusPillProps): NextStepPill => {
  if (!moduleType || !status) {
    return {
      ...PILLS.NO_ACTION_REQUIRED,
      backgroundColor: 'lightGray',
    }
  }

  switch (moduleType) {
    case YearEndModuleType.eoyBookkeepingFollowup:
      return handleEOYBookkeepingFollowup(status, substepIdentifier)
    case YearEndModuleType.fileExtensionRequest:
      return handleExtensionJob(status)
    case YearEndModuleType.file1040:
    case YearEndModuleType.file1120S:
      return handleFilingJobs(status, substepIdentifier)
    default: {
      if (
        [
          YearEndModuleStatusOptions.waitingOnHeard,
          YearEndModuleStatusOptions.upcoming,
          YearEndModuleStatusOptions.complete,
          YearEndModuleStatusOptions.cancelled,
        ].includes(status)
      ) {
        return {
          ...PILLS.NO_ACTION_REQUIRED,
          backgroundColor: 'lightGray',
        }
      }
      const localDate = isoToLocalDate(dueDate)
      const moduleNameCopy = getModuleNameCopy(moduleType)
      const subtext = localDate
        ? `${moduleNameCopy} (Due ${localDate.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)})`
        : moduleNameCopy

      if (
        status === YearEndModuleStatusOptions.earlyStart ||
        status === YearEndModuleStatusOptions.upNext
      ) {
        return {
          ...PILLS.YOUR_NEXT_STEP,
          subtext,
          backgroundColor: 'blush',
        }
      }
      return {
        ...PILLS.CONTINUE_WORKING_ON,
        subtext,
        backgroundColor: 'blush',
      }
    }
  }
}

export default getNextStepStatusPill
