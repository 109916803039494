import { useNavigate } from 'react-router-dom'
import { selectCurrentAnnualTaxDetails } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectIsCurrentUserScorp,
  selectIsCurrentUserSoleProp,
} from '../../../../selectors/user.selectors'
import { Alert, Button, Text } from '../../../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'
import {
  DATE_FORMATS_LUXON,
  convertUtcToLocalDate,
} from '../../../../utils/dateHelpers'
import { DateTime } from 'luxon'
import { AnnualTaxDetail } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { YearEndModuleType } from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { selectDeadlineBannerEligibility } from '../annualTaxFilings.selector'
import { getCompleteYearEndModuleStatuses } from '../../../YearEndModuleStatus/yearEndModuleStatus.selectors'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'

type ModuleInfo = {
  type: YearEndModuleType
  dueDate: string
} | null

const getBannerCopy = (moduleInfo: ModuleInfo) => {
  if (!moduleInfo) {
    return ''
  }
  const { type, dueDate } = moduleInfo
  const baseText = "Don't Forget! You need to"
  const endText = 'otherwise Heard will help you file an extension.'

  switch (type) {
    case YearEndModuleType.eoyBookkeeping:
      return `${baseText} complete your end of year Accounting Survey by ${dueDate}, ${endText}`
    case YearEndModuleType.eoyBookkeepingFollowup:
      return `${baseText} address all Bookkeeper follow-ups by ${dueDate}, ${endText}`
    case YearEndModuleType.taxQuestionnaire:
      return `${baseText} complete your Tax Questionnaire by ${dueDate}, ${endText}`
    case YearEndModuleType.submitExtensionRequest:
      return `${baseText} complete your extension request survey by ${dueDate} in order for Heard to help you file an extension.`
    case YearEndModuleType.verifyTaxEntity:
    case YearEndModuleType.file1099Nec:
    case YearEndModuleType.fileExtensionRequest:
    case YearEndModuleType.file1120S:
    case YearEndModuleType.file1040:
      return ''
    default:
      return type satisfies never
  }
}

const getRelevantModule = (
  taxDetails: AnnualTaxDetail,
  isScorp: boolean,
  isSoleProp: boolean,
  completedModules: { moduleType: YearEndModuleType }[]
): ModuleInfo => {
  const now = DateTime.now()
  const oneWeekFromNow = now.plus({ weeks: 1 })

  const isModuleCompleted = (moduleType: YearEndModuleType) => {
    return completedModules.some((module) => module.moduleType === moduleType)
  }

  if (isSoleProp) {
    const dueDate = taxDetails?.form_1040_eoy_survey_due_date
    if (!dueDate) return null

    const due = convertUtcToLocalDate(dueDate)?.endOf('day')
    if (
      due &&
      now <= due &&
      due <= oneWeekFromNow &&
      !isModuleCompleted(YearEndModuleType.eoyBookkeeping)
    ) {
      return {
        type: YearEndModuleType.eoyBookkeeping,
        dueDate: due.toFormat(DATE_FORMATS_LUXON.MONTH_DAY),
      }
    }
  }

  if (isScorp) {
    const modules = [
      {
        type: YearEndModuleType.eoyBookkeepingFollowup,
        dueDate: taxDetails?.form_1120_s_close_books_due_date,
      },
      {
        type: YearEndModuleType.taxQuestionnaire,
        dueDate: taxDetails?.form_1120_s_tq_due_date,
      },
      {
        type: YearEndModuleType.submitExtensionRequest,
        dueDate: taxDetails?.form_1120_s_extension_survey_due_date,
      },
    ]

    const upcomingModules = modules
      .map((mod) => {
        const due = convertUtcToLocalDate(mod.dueDate)?.endOf('day')
        return due &&
          now <= due &&
          due <= oneWeekFromNow &&
          !isModuleCompleted(mod.type)
          ? { type: mod.type, due }
          : null
      })
      .filter((mod) => mod !== null) as {
      type: YearEndModuleType
      due: DateTime
    }[]
    if (upcomingModules.length > 0) {
      const earliest = upcomingModules.sort(
        (a, b) => a.due.toMillis() - b.due.toMillis()
      )[0]
      return {
        type: earliest.type,
        dueDate: earliest.due.toFormat(DATE_FORMATS_LUXON.MONTH_DAY),
      }
    }
  }

  return null
}

const DeadlineBanner = () => {
  const navigate = useNavigate()
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const isSoleProp = useReselector(selectIsCurrentUserSoleProp)
  const shouldShowBanner = useReselector(selectDeadlineBannerEligibility)
  const completedModules = useReselector(getCompleteYearEndModuleStatuses)

  const shouldShowDeadlinebanner = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableYeDeadlineBanner,
    false
  )

  if (!shouldShowDeadlinebanner) {
    return null
  }

  if (!taxDetails) return null

  const relevantModule = getRelevantModule(
    taxDetails,
    isScorp,
    isSoleProp,
    completedModules
  )

  if (!shouldShowBanner || !relevantModule) return null

  const relevantModuleCopy = getBannerCopy(relevantModule)

  return (
    <Alert type="warn">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text>{relevantModuleCopy}</Text>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              variant="secondaryLink"
              onClick={() => navigate('/taxes/annual')}
            >
              Submit your survey
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Alert>
  )
}

export default DeadlineBanner
