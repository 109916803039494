import { useEffect, useState } from 'react'
import {
  fetchTaxConsultationRequests,
  submitTaxConsultationRequestCSAT,
  TaxConsultationRequest,
  TaxConsultationServiceRequestStatus,
} from './taxConsultationRequest.actions'
import { useUsersnapCustomDisplayLogicWidget } from '../../../components/shared/UsersnapSurvey'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TaxConsultationCSATSurvey = () => {
  const dispatch = useAppDispatch()
  const [latestCompletedRequest, setLatestCompletedRequest] =
    useState<TaxConsultationRequest>()

  useEffect(() => {
    const loadRequests = async () => {
      const res = (await fetchTaxConsultationRequests()(dispatch)) ?? []
      // sort by descending createdAt
      const request = res
        .filter(
          (r) =>
            r.status === TaxConsultationServiceRequestStatus.completed &&
            r.csatSubmittedAt === null
        )
        .sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        })?.[0]
      if (request) {
        setLatestCompletedRequest(request)
      }
    }
    loadRequests()
  }, [dispatch])

  useUsersnapCustomDisplayLogicWidget({
    projectKey: process.env.VITE_USERSNAP_TAX_CONSULTATION_CSAT,
    shouldShow: Boolean(latestCompletedRequest?.id),
    maxTimesShown: 3,
    showAgainAfterDays: 6,
    onSubmit: () => {
      if (!latestCompletedRequest?.id) return
      dispatch(submitTaxConsultationRequestCSAT(latestCompletedRequest.id))
    },
  })
  return null
}

export default TaxConsultationCSATSurvey
