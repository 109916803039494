import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { FormikProvider, useFormik } from 'formik'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Card,
  FormikDateInput,
  FormikInput,
  FormikLocationSearchInput,
  getFieldNames,
  GridRowColumn,
  Icon,
  Link,
  makeDateSchema,
  makeReqEmailSchema,
  makeReqPhoneNumberSchema,
  makeReqStringSchema,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { UPDATE_USER_KEY, updateUser } from '../../actions/userActions'
import { DATE_FORMATS_LUXON, formatISOFromUTC } from '../../utils/dateHelpers'
import PracticeProfileFormCta from './PracticeProfileFormCta'
import { getFetchError } from '../../reducers/fetch'
import PageHeader from '../../components/shared/PageHeader'
import { useAppDispatch } from '../../utils/typeHelpers'

const PersonalInformationForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useReselector(getCurrentUser)
  const error = useReselector(getFetchError, UPDATE_USER_KEY)

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      dateOfBirth:
        (user?.dateOfBirth && formatISOFromUTC(user.dateOfBirth)) || '',
      personalEmail: user?.personalEmail,
      personalPhone: user?.personalPhone,
      homeAddress: user?.homeAddress,
    },

    onSubmit: async ({
      firstName,
      lastName,
      dateOfBirth,
      personalEmail,
      personalPhone,
      homeAddress,
    }) => {
      const res = await dispatch(
        updateUser(user?.id, {
          firstName,
          lastName,
          dateOfBirth: dateOfBirth
            ? DateTime.fromFormat(dateOfBirth, DATE_FORMATS_LUXON.INPUT)
                .toUTC()
                .startOf('day')
                .toISO()
            : null,
          personalEmail: personalEmail || null,
          personalPhone: personalPhone || null,
          homeAddress: homeAddress || null,
        })
      )

      if (res) {
        navigate('/practice/profile')
      }
    },
  })

  const { submitForm, isValid, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <Grid>
      <GridRowColumn>
        <Link to="/practice/profile">
          <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
          Back to Practice Profile
        </Link>
      </GridRowColumn>
      <GridRowColumn>
        <PageHeader header="Personal Information" />
      </GridRowColumn>
      <GridRowColumn>
        <Card type="subsection" backgroundColor="stone40">
          <FormikProvider value={formik}>
            <Grid>
              {error && (
                <GridRowColumn>
                  <Alert type="error">{error.message}</Alert>
                </GridRowColumn>
              )}
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.firstName}
                  label="First Name"
                  fullWidth
                  schema={makeReqStringSchema({ field: 'first name' })}
                />
              </GridRowColumn>
              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.lastName}
                  label="Last Name"
                  fullWidth
                  schema={makeReqStringSchema({ field: 'last name' })}
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikDateInput
                  name={fieldNames.dateOfBirth}
                  label="Date of Birth"
                  fullWidth
                  maxDate={new Date()}
                  schema={makeDateSchema({ required: false })}
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.personalEmail}
                  label="Personal Email"
                  fullWidth
                  type="email"
                  schema={makeReqEmailSchema({ required: false })}
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikInput
                  name={fieldNames.personalPhone}
                  label="Personal Phone"
                  fullWidth
                  componentType="phone"
                  schema={makeReqPhoneNumberSchema({ required: false })}
                />
              </GridRowColumn>

              <GridRowColumn computer={9} tablet={9} mobile={16}>
                <FormikLocationSearchInput
                  name={fieldNames.homeAddress}
                  label="Personal Address"
                  fullWidth
                />
              </GridRowColumn>

              <Grid.Row />

              <PracticeProfileFormCta
                submitForm={submitForm}
                isValid={isValid}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </FormikProvider>
        </Card>
      </GridRowColumn>
    </Grid>
  )
}

export default PersonalInformationForm
