import { createSelector } from 'reselect'
import pickBy from 'lodash/pickBy'

import { ReduxState } from '../utils/typeHelpers'
import { RoleName } from '../reducers/admin/allRolesReducer'
import {
  FILING_STATUSES,
  FilingStatusOptions,
  TAX_ENTITY_TYPES,
} from '../features/Taxes/taxConstants'
import { isProduction } from '../utils/envHelpers'
import moment from 'moment'
import { parseAddressString } from '../components/shared/LocationSearchInput'
import {
  ELIGIBLE_FREE_TRIAL_PARAM_VALUES,
  FREE_TRIAL_PROMO,
} from '../features/Signup/helpers'
import {
  EndOfYearReconciliationLog,
  Membership,
  MEMBERSHIP_STATUS,
  MembershipScopes,
  MembershipStatusScopes,
  TrialStatus,
  User,
} from '../reducers/auth/userReducer'
import { UserWithAdminInfo } from '../reducers/admin/allUsersReducer'
import {
  basicBKDetails,
  basicBudgetingDetails,
  basicTaxDetails,
  groupBKDetails,
  groupBudgetingDetails,
  annualGroupScorpDetails,
  annualGroupTaxDetails,
  soloScorpBKDetails,
  soloScorpBudgetingDetails,
  annualSoloScorpScorpDetails,
  monthlySoloScorpTaxDetails,
  soloSolePropBKDetails,
  soloSolePropBudgetingDetails,
  soloSolePropScorpDetails,
  annualSoloSolePropTaxDetails,
  PlanDetail,
  annualSoloScorpTaxDetails,
  monthlySoloSolePropTaxDetails,
  monthlyGroupTaxDetails,
  generalCUBKDetails,
  monthlySoloScorpScorpDetails,
  monthlyGroupScorpDetails,
} from '../features/Signup/ChoosePlan/helpers'
import { isNil, sortBy } from 'lodash'
import { AnnualTaxFiling } from '../features/Taxes/AnnualTaxes/annualTaxFilings.slice'
import { selectHeardProductSubscription } from '../reducers/subscription.slice'
import { STRIPE_PRICE_MONTH_INTERVAL } from '../actions/settings/billingActions'

const getAdmin = (state: ReduxState) => state.admin

export const getAllUsers = createSelector(getAdmin, (admin) => admin.allUsers)

export const getAllUsersById = createSelector(
  getAllUsers,
  (allUsers) => allUsers.byId
)

export const getAllAdminUsersById = createSelector(
  getAllUsersById,
  (usersById) => pickBy(usersById, (val) => val.admin)
)

export const getAllBookkeepersById = createSelector(
  getAllUsersById,
  (usersById) =>
    pickBy(
      usersById,
      (val) =>
        val.admin &&
        val.roles &&
        Boolean(val.roles.some((role) => role.name === RoleName.Bookkeeper))
    )
)

export const getUserById = createSelector(
  getAllUsersById,
  (_: unknown, userId: string | number | undefined) => userId,
  (usersById, userId) => {
    return userId && usersById[Number(userId)]
      ? usersById[Number(userId)]
      : undefined
  }
)

export const selectFinancialProfileIdByUserId = createSelector(
  getUserById,
  (user) => user?.financialProfile?.id
)

// This is very inefficient with current redux setup so use sparingly
export const getUserByFinancialProfileId = createSelector(
  getAllUsersById,
  (_: unknown, financialProfileId: number) => financialProfileId,
  (allUsers, financialProfileId) =>
    Object.values(allUsers).find(
      (user) => user.financialProfile?.id === financialProfileId
    )
)

export const getUserNameById = createSelector(
  getUserById,
  (user) => (user && `${user.firstName} ${user.lastName}`) || undefined
)

export const getFinancialProfileByUserId = createSelector(
  getUserById,
  (user) => user?.financialProfile || undefined
)

const getAuth = (state: ReduxState) => state.auth

// Initially user state is {} then gets populated with user data.  This can be really annoying when typing because you
// need to check to make sure that every property exists. If `id` exists UserState will be `User` and not `Partial<User>`
// Technically this can be a mapped instead of casted but it will be too easy to miss things if the interface is updated
export const getCurrentUser = createSelector(getAuth, (auth) =>
  auth.user.id ? (auth.user as User) : null
)

export const selectUserName = createSelector(getCurrentUser, (user) =>
  user ? `${user.firstName} ${user.lastName}` : ''
)

export const getUserIsAdmin = createSelector(
  getCurrentUser,
  (user) => user?.admin
)

export const getUserCreatedAt = createSelector(
  getCurrentUser,
  (user) => user?.createdAt
)

export const getFinancialProfile = createSelector(
  getCurrentUser,
  (user) => user?.financialProfile
)

export const selectTaxProfileLastReviewed = createSelector(
  getFinancialProfile,
  (fp) => fp?.taxProfileLastReviewed
)

export const selectQteWizardLastStartedAt = createSelector(
  getFinancialProfile,
  (fp) => fp?.qteWizardLastStartedAt
)

export const selectIsFilingJointly = createSelector(
  getFinancialProfile,
  (fp) => fp?.filingStatus === FILING_STATUSES.married_filing_jointly
)

export const selectFilingStatusText = createSelector(
  getFinancialProfile,
  (fp) =>
    FilingStatusOptions.find(({ value }) => value === fp?.filingStatus)?.text ||
    ''
)

export const selectIsCurrentUserScorp = createSelector(
  getFinancialProfile,
  (financialProfile) =>
    financialProfile?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s
)

export const selectIsCurrentUserSoleProp = createSelector(
  getFinancialProfile,
  (financialProfile) =>
    financialProfile?.taxEntityType === TAX_ENTITY_TYPES.form_1040 ||
    financialProfile?.taxEntityType === TAX_ENTITY_TYPES.schedule_c
)

export const selectParsedAddress = createSelector(
  getFinancialProfile,
  (financialProfile) => parseAddressString(financialProfile?.businessAddress)
)

export const getIsPayrollEnabled = createSelector(getCurrentUser, (user) =>
  Boolean(user?.payrollEnabledAt)
)

export const getInterestedInGepAt = createSelector(
  getCurrentUser,
  (user) => user?.interestedInGepAt
)

export const selectUserRoles = createSelector(
  getCurrentUser,
  (user) => user?.roles || []
)

export const selectIsAccountManager = createSelector(selectUserRoles, (roles) =>
  Boolean(roles.some((role) => role.name === RoleName.AccountManager))
)

export const getIsAdministrator = createSelector(selectUserRoles, (roles) =>
  Boolean(roles.some((role) => role.name === RoleName.Administrator))
)

export const selectIsBookkeeper = createSelector(selectUserRoles, (roles) =>
  Boolean(roles.some((role) => role.name === RoleName.Bookkeeper))
)

export const selectIsAccountingOpsManager = createSelector(
  selectUserRoles,
  (roles) =>
    Boolean(roles.some((role) => role.name === RoleName.AccountingOpsManager))
)

export const selectIsTaxCoordinator = createSelector(selectUserRoles, (roles) =>
  Boolean(roles.some((role) => role.name === RoleName.TaxCoordinator))
)

export const getIsTaxTeam = createSelector(selectUserRoles, (roles) =>
  Boolean(
    roles.some(
      (role) =>
        role.name === RoleName.TaxPreparer ||
        role.name === RoleName.TaxCoordinator ||
        role.name === RoleName.Administrator
    )
  )
)

export const selectFilteredUserRecords = createSelector(
  getCurrentUser,
  getAllUsersById,
  selectIsAccountManager,
  (_: unknown, filterManagedUsers?: boolean) => filterManagedUsers,
  (ownUser, usersById, isAccountManager, filterManagedUsers) => {
    // If users aren't filtered or if this user isn't an account manager return everything
    if (!ownUser || !filterManagedUsers || !isAccountManager) {
      return usersById
    }

    return pickBy(usersById, (user) => user.ownerId === ownUser.id)
  }
)

const getPrimaryMembershipFromUser = (
  user: User | UserWithAdminInfo | null | undefined
) =>
  user?.memberships?.length
    ? user.memberships.filter((membership) => membership.isPrimary)[0]
    : null

export const selectPrimaryMembership = createSelector(
  getCurrentUser,
  getPrimaryMembershipFromUser
)

export const membershipIsIn = (
  scope: MembershipScopes,
  membership: Membership | null
) => {
  if (!membership?.status) {
    return false
  }
  return MembershipStatusScopes[scope].includes(membership.status)
}

export const selectShouldHaveSubscription = createSelector(
  getCurrentUser,
  selectPrimaryMembership,
  (user, membership) =>
    user &&
    !user.admin &&
    !user.qaDetails &&
    membershipIsIn(MembershipScopes.allActiveIncludingUnpaid, membership)
)

export const selectHasAppAccess = createSelector(
  getCurrentUser,
  selectPrimaryMembership,
  (user, membership) => {
    // If user is an admin or a QA user they have access
    if (user?.admin || user?.qaDetails) {
      return true
    }
    if (membership) {
      const hasValidMembershipStatus = membershipIsIn(
        MembershipScopes.activePayingOrTrial,
        membership
      )
      const hasStripeSubscription = Boolean(membership.stripeSubscription)
      return hasStripeSubscription && hasValidMembershipStatus
    }

    return null
  }
)

export const selectIsUserMembershipPendingOrUnpaid = createSelector(
  selectPrimaryMembership,
  (membership) =>
    membership?.status && ['pending', 'unpaid'].includes(membership.status)
)

// Returns when the membership was started for current user.  This isn't set for some older users so fallback to create date
export const selectMembershipStart = createSelector(
  getUserCreatedAt,
  selectPrimaryMembership,
  (userCreatedAt, primaryMembership) => {
    const trialStartDate =
      primaryMembership?.status === MEMBERSHIP_STATUS.trial ||
      primaryMembership?.status === MEMBERSHIP_STATUS.paid
        ? primaryMembership?.trialStartDate
        : undefined
    return trialStartDate || primaryMembership?.startDate || userCreatedAt
  }
)

export const getLastOnboardingScreen = createSelector(
  getCurrentUser,
  (user) => user?.lastOnboardingScreen
)

export const getReferralLink = createSelector(getCurrentUser, (user) => {
  const userReferralCode = user?.referralCode ?? ''

  return isProduction()
    ? `https://joinheard.referral-factory.com/${userReferralCode}/join`
    : `https://staging-joinheard.referral-factory.com/${userReferralCode}/join`
})

export const isFreeTrialPromoCode = createSelector(
  selectPrimaryMembership,
  (membership) => {
    return (
      membership?.freeTrialPromoCode === FREE_TRIAL_PROMO &&
      membership?.status === MEMBERSHIP_STATUS.trial
    )
  }
)
export const getFreeTrialStatus = createSelector(
  getCurrentUser,
  selectPrimaryMembership,
  (user, membership) => {
    const trialEndDate = membership?.trialEndDate

    if (!user || !trialEndDate) {
      return TrialStatus.unredeemed
    }

    const NOW = moment()
    const trialHasEnded = NOW.isSameOrAfter(moment(trialEndDate))

    // The trial is currently active
    if (membership?.status === MEMBERSHIP_STATUS.trial && !trialHasEnded) {
      return TrialStatus.active
    }

    // The trial was cancelled by the user
    if (membership?.status === MEMBERSHIP_STATUS.canceled) {
      return TrialStatus.inactive
    }

    // The trial has ended
    if (trialHasEnded) {
      return TrialStatus.inactive
    }

    return TrialStatus.unredeemed
  }
)

export const getFreeTrialEndsAt = createSelector(
  selectPrimaryMembership,
  (membership) => (membership?.trialEndDate ? membership?.trialEndDate : '')
)

export const getReferralCode = createSelector(
  getCurrentUser,
  (user) => user?.referralCode
)

export const selectIsGroupPractice = createSelector(
  getCurrentUser,
  (user) => user?.financialProfile?.practiceType === 'group'
)

export const selectMembershipIsIn = createSelector(
  selectPrimaryMembership,
  (_: unknown, scope: MembershipScopes) => scope,
  (membership, scope) => membershipIsIn(scope, membership)
)

export const isCancelling = createSelector(
  selectPrimaryMembership,
  (membership) => membership?.status === MEMBERSHIP_STATUS.cancelling
)

export const selectIsSigningUp = createSelector(
  selectPrimaryMembership,
  (membership) => membership?.status === MEMBERSHIP_STATUS.signingUp
)

/*
 * Returns true if the user is has ever initiated a reactivation
 * (was cancelled and is either in the process of reactivating or has reactivated)
 */
export const selectIsReactivatedUser = createSelector(getCurrentUser, (user) =>
  Boolean(user?.reactivatedAt)
)

export const hasManualRequest = createSelector(
  selectPrimaryMembership,
  (membership) => {
    return membership?.manualCancellationRequestedAt
  }
)

export const isCanceled = createSelector(
  selectPrimaryMembership,
  (membership) => membership?.status === MEMBERSHIP_STATUS.canceled
)

export const selectIsFreeTrialEnabled = createSelector(
  getFreeTrialStatus,
  (_: unknown, freeTrialSearchParamValue: string | null) =>
    freeTrialSearchParamValue,
  (
    _: unknown,
    _freeTrialSearchParamValue: string | null,
    basicPlanEnabled: boolean
  ) => basicPlanEnabled,
  (userFreeTrialStatus, freeTrialSearchParamValue, basicPlanEnabled) =>
    Boolean(
      freeTrialSearchParamValue &&
        ELIGIBLE_FREE_TRIAL_PARAM_VALUES.includes(freeTrialSearchParamValue) &&
        userFreeTrialStatus === TrialStatus.unredeemed &&
        !basicPlanEnabled
    )
)

export const selectTaxEntityPracticeTypeDescriptor = createSelector(
  getCurrentUser,
  selectIsGroupPractice,
  (user, isGroupPractice) => {
    let result = ''
    if (
      !user?.financialProfile?.practiceType ||
      !user?.financialProfile?.taxEntityType
    ) {
      return result
    }
    const { taxEntityType } = user.financialProfile

    result = `${isGroupPractice ? 'Group' : 'Solo'} Practice ${
      taxEntityType === TAX_ENTITY_TYPES.form_1040 ||
      taxEntityType === TAX_ENTITY_TYPES.schedule_c
        ? 'Sole Proprietor'
        : taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
            taxEntityType === TAX_ENTITY_TYPES.form_1120
          ? 'S Corporation'
          : ''
    }`

    return result
  }
)

export const selectPrimaryMembershipForUser = createSelector(
  getUserById,
  getPrimaryMembershipFromUser
)

export const selectAdminUserState = createSelector(
  selectPrimaryMembershipForUser,
  (membership) =>
    membership?.status === MEMBERSHIP_STATUS.unpaid
      ? 'unpaid'
      : membership?.status
)

export const selectRolledOutInstitutionIds = (state: ReduxState) =>
  state.auth.rolledOutInstitutions.rolledOutInstitutionIds

const getTaxSectionDetails = ({
  basicPlanEnabled,
  isSolo,
  isSCorporation,
  annualPlanSelected,
  isSoleProp,
  practiceType,
  includeTaxes,
}: {
  basicPlanEnabled: boolean
  isSolo: boolean
  isSCorporation: boolean
  annualPlanSelected: boolean
  isSoleProp: boolean
  practiceType: string
  includeTaxes: boolean
}) => {
  let taxDetails: PlanDetail[] = []
  if (basicPlanEnabled) {
    taxDetails = basicTaxDetails
  } else if (isSolo && isSCorporation) {
    if (annualPlanSelected) {
      taxDetails = annualSoloScorpTaxDetails({ withPromo: includeTaxes })
    } else {
      taxDetails = monthlySoloScorpTaxDetails
    }
  } else if (isSolo && isSoleProp) {
    if (annualPlanSelected) {
      taxDetails = annualSoloSolePropTaxDetails({ withPromo: includeTaxes })
    } else {
      taxDetails = monthlySoloSolePropTaxDetails
    }
  } else if (practiceType === 'group') {
    if (annualPlanSelected) {
      taxDetails = annualGroupTaxDetails({ withPromo: includeTaxes })
    } else {
      taxDetails = monthlyGroupTaxDetails
    }
  } else {
    taxDetails = annualSoloSolePropTaxDetails({ withPromo: includeTaxes })
  }
  return taxDetails
}

const getSCorpSectionDetails = ({
  isSolo,
  isSCorporation,
  annualPlanSelected,
  isSoleProp,
  practiceType,
  includeTaxes,
}: {
  isSolo: boolean
  isSCorporation: boolean
  annualPlanSelected: boolean
  isSoleProp: boolean
  practiceType: string
  includeTaxes: boolean
}) => {
  let scorpDetails: PlanDetail[] = []
  if (isSolo && isSCorporation) {
    if (annualPlanSelected) {
      scorpDetails = annualSoloScorpScorpDetails({ withPromo: includeTaxes })
    } else {
      scorpDetails = monthlySoloScorpScorpDetails
    }
  } else if (isSolo && isSoleProp) {
    scorpDetails = soloSolePropScorpDetails
  } else if (practiceType === 'group') {
    if (annualPlanSelected) {
      scorpDetails = annualGroupScorpDetails({ withPromo: includeTaxes })
    } else {
      scorpDetails = monthlyGroupScorpDetails
    }
  } else {
    scorpDetails = soloSolePropScorpDetails
  }
  return scorpDetails
}

export const selectProductDescription = createSelector(
  getCurrentUser,
  (_: unknown, basicPlanEnabled: boolean) => basicPlanEnabled,
  (
    _: unknown,
    __: unknown,
    sections: Array<
      'taxes' | 'bookkeeping' | 'catch_up_bookkeeping' | 'budget' | 'scorp'
    >
  ) => sections,
  (_: unknown, __: unknown, ___: unknown, taxYear: string) => taxYear,
  (
    _: unknown,
    __: unknown,
    ___: unknown,
    ____: unknown,
    annualPlanSelected?: boolean
  ) => annualPlanSelected,
  (
    _: unknown,
    __: unknown,
    ___: unknown,
    ____: unknown,
    _____: unknown,
    includeTaxes?: boolean
  ) => includeTaxes,
  (
    currentUser,
    basicPlanEnabled,
    sections,
    taxYear,
    annualPlanSelected = false,
    includeTaxes = false
  ) => {
    if (
      !currentUser?.financialProfile?.taxEntityType ||
      !currentUser?.financialProfile?.practiceType
    ) {
      return null
    }

    const { practiceType, taxEntityType } = currentUser.financialProfile

    const isSolo = practiceType === 'solo' || practiceType === 'getting_started'

    const isSCorporation =
      taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
      taxEntityType === TAX_ENTITY_TYPES.form_1120

    const isSoleProp =
      taxEntityType === TAX_ENTITY_TYPES.form_1040 ||
      taxEntityType === TAX_ENTITY_TYPES.schedule_c

    const taxDetails = getTaxSectionDetails({
      basicPlanEnabled,
      isSolo,
      isSCorporation,
      annualPlanSelected,
      isSoleProp,
      practiceType,
      includeTaxes,
    })
    const scorpDetails = getSCorpSectionDetails({
      isSolo,
      isSCorporation,
      annualPlanSelected,
      isSoleProp,
      practiceType,
      includeTaxes,
    })

    const bkDetails = basicPlanEnabled
      ? basicBKDetails
      : isSolo && isSCorporation
        ? soloScorpBKDetails
        : isSolo && isSoleProp
          ? soloSolePropBKDetails
          : practiceType === 'group'
            ? groupBKDetails
            : soloSolePropBKDetails

    const budgetingDetails = basicPlanEnabled
      ? basicBudgetingDetails
      : isSolo && isSCorporation
        ? soloScorpBudgetingDetails
        : isSolo && isSoleProp
          ? soloSolePropBudgetingDetails
          : practiceType === 'group'
            ? groupBudgetingDetails
            : soloSolePropBudgetingDetails

    const nextTaxYear = String(Number(taxYear) + 1)
    // The year the taxes are filed (not the tax year - e.g. 2025 taxes will be filed in 2026)
    const nextFilingTaxYear = String(Number(taxYear) + 2)
    return [
      {
        title: includeTaxes ? 'Taxes' : `${nextTaxYear} Taxes`,
        subTitle: includeTaxes ? null : `(filed in ${nextFilingTaxYear})`,
        isVisible: sections.includes('taxes'),
        details: taxDetails,
      },
      {
        title: 'Bookkeeping',
        isVisible: sections.includes('bookkeeping'),
        details: bkDetails,
      },
      {
        title: 'Catch up Bookkeeping',
        isVisible: sections.includes('catch_up_bookkeeping'),
        details: generalCUBKDetails,
      },
      {
        title: 'Budgeting & Tracking',
        isVisible: sections.includes('budget'),
        details: budgetingDetails,
      },
      {
        title: 'S Corporation',
        isVisible: !basicPlanEnabled && sections.includes('scorp'),
        details: scorpDetails,
      },
    ]
  }
)
export const getIsRequiredStepsOnboarding = createSelector(
  getCurrentUser,
  (currentUser) => isNil(currentUser?.onboardingItemsCompletedAt)
)

// Admin selector only
export const userEndOfYearReconciliationLogsAreCompleted = (
  year: string,
  userEndOfYearReconciliationLogs?: EndOfYearReconciliationLog[] | null
) =>
  Boolean(
    sortBy(
      userEndOfYearReconciliationLogs?.filter(
        (log) => log.value === year || !log.value
      ),
      'createdAt'
    ).at(-1)?.value // .at(-1) retrieves the last item in the list
  )

export const adminIsUserCanceled = createSelector(getUserById, (user) => {
  const primaryMembership = user?.memberships?.filter(
    (membership) => membership.isPrimary
  )[0]
  return (
    primaryMembership && primaryMembership.status === MEMBERSHIP_STATUS.canceled
  )
})
//Used to check if user has a free trial that does not need BK servicing
export const adminIsUserNoServiceFreeTrial = createSelector(
  getUserById,
  (user) => {
    const primaryMembership = user?.memberships?.filter(
      (membership) => membership.isPrimary
    )[0]
    return Boolean(
      primaryMembership?.freeTrialPromoCode &&
        ELIGIBLE_FREE_TRIAL_PARAM_VALUES.includes(
          primaryMembership.freeTrialPromoCode
        ) &&
        primaryMembership.status === MEMBERSHIP_STATUS.trial
    )
  }
)

// User selector only
export const selectIsEndOfYearReconCompleted = createSelector(
  getCurrentUser,
  (_: unknown, year: string) => year,
  (user, year) =>
    Boolean(
      sortBy(
        user?.userEndOfYearReconciliationLogs?.filter(
          (log) => log.value === year || !log.value
        ),
        'createdAt'
      ).at(-1)?.value // .at(-1) retrieves the last item in the list
    )
)

export const HIGH_THRESHOLD_STATES = ['CA', 'NY']

export const selectSCorpRequiredIncome = createSelector(
  getCurrentUser,
  (user) =>
    HIGH_THRESHOLD_STATES.includes(user?.financialProfile?.homeState ?? '')
      ? 100
      : 60
)
/**
 * Returns true if User is a late joiner for the current tax filer and is paying month
 *
 * This means they cannot self serve cancel as they have an annual commitment
 */
export const selectIsUserLateJoinerWithCommitment = createSelector(
  selectHeardProductSubscription,
  (_: unknown, annualTaxFiling?: AnnualTaxFiling) => annualTaxFiling,
  (heardPrimarySubscription, annualTaxFiling) => {
    // If user is a late joiner, check their primary subscription
    if (annualTaxFiling?.isLateJoiner) {
      const primarySubscriptionInterval =
        heardPrimarySubscription?.items.default.interval
      return primarySubscriptionInterval === STRIPE_PRICE_MONTH_INTERVAL
    } else {
      return false
    }
  }
)
