import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { isArray } from 'lodash'

import {
  FormikCheckboxMulti,
  FormikDropdown,
  FormikInput,
  FormikLabelError,
  FormikScrollOnError,
  getFieldNames,
  GridRowColumn,
  makeNumberSchema,
  makeReqStringSchema,
  makeStringArraySchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  TaxListOptionId,
  TaxListQuestionId,
  useTaxListQuestionRes,
} from '../../service'
import { selectIsMarriedFilingJointlyOrQualifyingWidow } from '../../taxChecklist.selectors'
import { TaxChecklistResponseValue } from '../../taxChecklistQuestion.slice'
import { deleteTaxQuestionnaireResponses } from '../../taxChecklistQuestion.actions'
import { Form1040DetailsProps } from '.'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const healthcareInfoQuestionIds = [
  TaxListQuestionId.healthcare_coverage_type,
  TaxListQuestionId.healthcare_coverage_explanation,
  TaxListQuestionId.healthcare_coverage_type_spouse,
  TaxListQuestionId.healthcare_had_account,
  TaxListQuestionId.healthcare_account_types,
  TaxListQuestionId.healthcare_all_qualified_expenses,
  TaxListQuestionId.healthcare_distributions_amount_in_cents,
]

const getRequiredDocCategories = (props: {
  [TaxListQuestionId.healthcare_had_account]:
    | TaxChecklistResponseValue
    | undefined
  [TaxListQuestionId.healthcare_account_types]:
    | TaxChecklistResponseValue
    | undefined
  [TaxListQuestionId.healthcare_coverage_type]:
    | TaxChecklistResponseValue
    | undefined
  [TaxListQuestionId.healthcare_coverage_type_spouse]:
    | TaxChecklistResponseValue
    | undefined
}) => {
  const requiredDocCategories: UserDocumentCategoryIdentifier[] = []

  if (
    props[TaxListQuestionId.healthcare_had_account] &&
    Array.isArray(props[TaxListQuestionId.healthcare_account_types]) &&
    props[TaxListQuestionId.healthcare_account_types].length > 0
  ) {
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form1099sa)
  }

  const combinedCoverageTypes = [
    ...(isArray(props[TaxListQuestionId.healthcare_coverage_type])
      ? props[TaxListQuestionId.healthcare_coverage_type]
      : []),
    ...(isArray(props[TaxListQuestionId.healthcare_coverage_type_spouse])
      ? props[TaxListQuestionId.healthcare_coverage_type_spouse]
      : []),
  ]

  if (
    combinedCoverageTypes.includes(TaxListOptionId.health_insurance_employer)
  ) {
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form1095c)
  }

  if (
    combinedCoverageTypes.includes(TaxListOptionId.health_insurance_marketplace)
  ) {
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form1095a)
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form1099hc)
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form3895)
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form8692)
  }

  if (combinedCoverageTypes.includes(TaxListOptionId.health_insurance_other)) {
    requiredDocCategories.push(UserDocumentCategoryIdentifier.form1095b)
  }

  return requiredDocCategories
}

const HealthcareInfoPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1040DetailsProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)
  const dispatch = useAppDispatch()

  const coverageTypeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_coverage_type,
    formId,
  })

  const coverageExplanationQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_coverage_explanation,
    formId,
  })

  const coverageTypeSpouseQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_coverage_type_spouse,
    formId,
  })

  const hadAccountQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_had_account,
    formId,
  })

  const accountTypeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_account_types,
    formId,
  })

  const allQualifiedExpensesQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_all_qualified_expenses,
    formId,
  })

  const distributionAmountQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.healthcare_distributions_amount_in_cents,
    formId,
    isCurrency: true,
  })

  const showSpouseQuestion = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    taxYear
  )

  const skippedQuestions = useSkipQuestion(healthcareInfoQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.healthcare_coverage_type]:
        coverageTypeQR.initialResponse || [],
      [TaxListQuestionId.healthcare_coverage_explanation]:
        coverageExplanationQR.initialResponse,
      [TaxListQuestionId.healthcare_coverage_type_spouse]:
        coverageTypeSpouseQR.initialResponse || [],
      [TaxListQuestionId.healthcare_had_account]: hadAccountQR.initialResponse,
      [TaxListQuestionId.healthcare_account_types]:
        accountTypeQR.initialResponse,
      [TaxListQuestionId.healthcare_all_qualified_expenses]:
        allQualifiedExpensesQR.initialResponse,
      [TaxListQuestionId.healthcare_distributions_amount_in_cents]:
        distributionAmountQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responses = [
        coverageTypeQR.getResponseData(values),
        coverageExplanationQR.getResponseData(values),
        coverageTypeSpouseQR.getResponseData(values),
        hadAccountQR.getResponseData(values),
        accountTypeQR.getResponseData(values),
        allQualifiedExpensesQR.getResponseData(values),
        distributionAmountQR.getResponseData(values),
      ]

      let deleteSuccess = true
      const idsToDelete: number[] = []

      if (
        values[TaxListQuestionId.healthcare_coverage_type] !==
          TaxListOptionId.health_insurance_other &&
        coverageExplanationQR.responseId
      ) {
        idsToDelete.push(coverageExplanationQR.responseId)
      }

      if (
        (values[TaxListQuestionId.healthcare_all_qualified_expenses] === true ||
          values[TaxListQuestionId.healthcare_had_account] === false) &&
        distributionAmountQR.responseId
      ) {
        idsToDelete.push(distributionAmountQR.responseId)
      }

      if (values[TaxListQuestionId.healthcare_had_account] === false) {
        if (accountTypeQR.responseId) {
          idsToDelete.push(accountTypeQR.responseId)
        }
        if (allQualifiedExpensesQR.responseId) {
          idsToDelete.push(allQualifiedExpensesQR.responseId)
        }
      }

      if (idsToDelete.length > 0) {
        deleteSuccess = Boolean(
          await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
        )
      }
      if (deleteSuccess) {
        await goToNextStep(responses, nextScreen ?? null, {
          completedSteps: [
            SubStepIdentifiers.updateBusinessAndHealthcareInformation,
          ],
        })
      }
    },
  })

  const { values, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Update Healthcare Information
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Please update the following information for {taxYear}. These answers
            will appear on your tax return.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikDropdown
            name={fieldNames[TaxListQuestionId.healthcare_coverage_type]}
            label={coverageTypeQR.label}
            options={coverageTypeQR.options?.map((o) => ({
              text: o.text,
              value: o.id,
              key: o.id,
            }))}
            placeholder="Select all that apply"
            schema={createSkipSchema(
              values,
              TaxListQuestionId.healthcare_coverage_type,
              makeStringArraySchema()
            )}
            fullWidth
            multiple
          />
        </GridRowColumn>
        <GridRowColumn short {...tqSmallSpace}>
          <SkipQuestion
            questionId={TaxListQuestionId.healthcare_coverage_type}
          />
        </GridRowColumn>

        {isArray(values[TaxListQuestionId.healthcare_coverage_type]) &&
          values[TaxListQuestionId.healthcare_coverage_type].includes(
            TaxListOptionId.health_insurance_other
          ) && (
            <>
              <GridRowColumn {...tqSmallSpace}>
                <FormikInput
                  name={
                    fieldNames[
                      TaxListQuestionId.healthcare_coverage_explanation
                    ]
                  }
                  label={coverageExplanationQR.label}
                  placeholder="Enter explanation"
                  schema={createSkipSchema(
                    values,
                    TaxListQuestionId.healthcare_coverage_explanation,
                    makeReqStringSchema()
                  )}
                  fullWidth
                />
              </GridRowColumn>
              <GridRowColumn short {...tqSmallSpace}>
                <SkipQuestion
                  questionId={TaxListQuestionId.healthcare_coverage_explanation}
                />
              </GridRowColumn>
            </>
          )}
        {showSpouseQuestion && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikDropdown
                name={
                  fieldNames[TaxListQuestionId.healthcare_coverage_type_spouse]
                }
                label={coverageTypeSpouseQR.label}
                options={coverageTypeSpouseQR.options?.map((o) => ({
                  text: o.text,
                  value: o.id,
                  key: o.id,
                }))}
                placeholder="Select all that apply"
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.healthcare_coverage_type_spouse,
                  makeStringArraySchema()
                )}
                fullWidth
                multiple
              />
            </GridRowColumn>
            <GridRowColumn short {...tqSmallSpace}>
              <SkipQuestion
                questionId={TaxListQuestionId.healthcare_coverage_type_spouse}
              />
            </GridRowColumn>
          </>
        )}

        <TQYesNoResponse questionResponse={hadAccountQR} skippable />

        {values[TaxListQuestionId.healthcare_had_account] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikLabelError
                name={fieldNames[TaxListQuestionId.healthcare_account_types]}
                label={accountTypeQR.label}
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.healthcare_account_types,
                  makeStringArraySchema()
                )}
              />
            </GridRowColumn>
            {accountTypeQR.options.map((o) => (
              <GridRowColumn key={o.id} short {...tqSmallSpace}>
                <FormikCheckboxMulti
                  schema={createSkipSchema(
                    values,
                    TaxListQuestionId.healthcare_account_types,
                    makeStringArraySchema()
                  )}
                  name={fieldNames[TaxListQuestionId.healthcare_account_types]}
                  value={o.id}
                  label={<Text as="bodyLg">{o.text}</Text>}
                  style={{ paddingLeft: 10 }}
                  variant="default"
                />
              </GridRowColumn>
            ))}
            <GridRowColumn short {...tqSmallSpace}>
              <SkipQuestion
                questionId={TaxListQuestionId.healthcare_account_types}
              />
            </GridRowColumn>

            <TQYesNoResponse
              questionResponse={allQualifiedExpensesQR}
              skippable
              afterLabel={
                <Popup
                  content={
                    <Grid>
                      <GridRowColumn>
                        <Text as="h3">Qualified expenses</Text>
                      </GridRowColumn>
                      <GridRowColumn short>
                        <Text>
                          Qualified medical expenses are expenses that generally
                          would qualify for the medical and dental expenses
                          deduction. Examples include unreimbursed expenses for
                          doctors, dentists, and hospitals. Wellness-related
                          expenses (exercises classes, massages) do not count.
                        </Text>
                      </GridRowColumn>
                    </Grid>
                  }
                />
              }
            />
            {values[TaxListQuestionId.healthcare_all_qualified_expenses] ===
              false && (
              <>
                <GridRowColumn {...tqSmallSpace}>
                  <FormikInput
                    componentType="currency"
                    name={
                      fieldNames[
                        TaxListQuestionId
                          .healthcare_distributions_amount_in_cents
                      ]
                    }
                    label={distributionAmountQR.label}
                    schema={makeNumberSchema({ allowedDecimals: 2 })}
                    fullWidth
                    placeholder="$0.00"
                  />
                </GridRowColumn>
                <GridRowColumn short {...tqSmallSpace}>
                  <SkipQuestion
                    questionId={
                      TaxListQuestionId.healthcare_distributions_amount_in_cents
                    }
                  />
                </GridRowColumn>
              </>
            )}
          </>
        )}
        <GridRowColumn {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={getRequiredDocCategories(values)}
        />

        <FormFlowFooter
          loading={isSubmitting}
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default HealthcareInfoPanel
