import {
  YearEndModuleStatus,
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import { Text, Accordion, Icon } from '../../../components/BaseComponents'
import { useMemo, useState } from 'react'
import VerifyTaxEntityCard from './VerifyTaxEntityCard'
import EoyBookkeepingCard from './EOYBookkeepingCard'
import EOYBookkeepingFollowupCard from './EOYBookkeepingFollowupCard'
import TaxQuestionnaireCard from './TaxQuestionnaireCard'
import SubmitExtensionRequestCard from './SubmitExtensionRequestCard'
import FileExtensionRequestCard from './FileExtensionRequestCard'
import File1040Card from './File1040Card'
import File1120SCard from './File1120SCard'
import File1099NECCard from './File1099NECCard'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from 'styled-components'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Colors } from '../../../styles/theme'
import { TaxFormType } from '../../Taxes/AnnualTaxes/Questionnaires/constants'
import { ExtensionReason } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { selectTaxDetailsByYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useReselector } from '../../../utils/sharedHooks'

interface BaseYearEndModuleProgressListProps {
  taxYear: string
  annualTaxFormNeeds?: Array<TaxFormType> | null
  extensionReason?: ExtensionReason | null
  hasSubmitted1099NEC?: boolean | null
  mostUrgentModule: YearEndModuleType | null
}
interface YearEndModuleInternalProgressListProps
  extends BaseYearEndModuleProgressListProps {
  yearEndModules: YearEndModuleStatus[]
  endLineOnLast?: boolean
}
export interface YearEndModuleProgressListProps
  extends BaseYearEndModuleProgressListProps {
  completeModules: YearEndModuleStatus[]
  incompleteModules: YearEndModuleStatus[]
}

const getComponentForYearEndModule = ({
  yeModule,
  taxYear,
  annualTaxFormNeeds,
  extensionReason,
  hasSubmitted1099NEC,
  form1040PaymentDueDate,
}: BaseYearEndModuleProgressListProps & {
  yeModule: YearEndModuleStatus
  form1040PaymentDueDate?: string | null
}) => {
  const isTwoFormFiler = Boolean(
    annualTaxFormNeeds && annualTaxFormNeeds.length > 1
  )
  const isScorp = annualTaxFormNeeds?.includes(TaxFormType.form1120s)
  const props = {
    ...yeModule,
    taxYear,
  }
  switch (yeModule.moduleType) {
    case YearEndModuleType.verifyTaxEntity:
      return <VerifyTaxEntityCard {...props} />
    case YearEndModuleType.file1099Nec:
      return (
        <File1099NECCard {...props} hasSubmitted1099NEC={hasSubmitted1099NEC} />
      )
    case YearEndModuleType.eoyBookkeeping:
      return <EoyBookkeepingCard {...props} isScorp={isScorp} />
    case YearEndModuleType.eoyBookkeepingFollowup:
      return <EOYBookkeepingFollowupCard {...props} isScorp={isScorp} />
    case YearEndModuleType.taxQuestionnaire: {
      const needs = annualTaxFormNeeds || []
      return <TaxQuestionnaireCard {...props} annualTaxFormNeeds={needs} />
    }
    case YearEndModuleType.submitExtensionRequest:
      return (
        <SubmitExtensionRequestCard
          {...props}
          extensionReason={extensionReason}
        />
      )
    case YearEndModuleType.fileExtensionRequest:
      return <FileExtensionRequestCard {...props} />
    case YearEndModuleType.file1040:
      return (
        <File1040Card
          {...props}
          isTwoFormFiler={isTwoFormFiler}
          paymentDueDate={form1040PaymentDueDate}
        />
      )
    case YearEndModuleType.file1120S:
      return <File1120SCard {...props} />
    default:
      yeModule.moduleType satisfies never
      return null
  }
}

interface StatusCircleProps {
  backgroundColor?: string
  borderColor?: string
  icon?: IconDefinition
  isMostUrgent?: boolean
  zeroTopMargin?: boolean
}

const statusCircleWidth = 32
const StatusCircle = styled.div<StatusCircleProps>`
  width: ${statusCircleWidth}px;
  height: ${statusCircleWidth}px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor || Colors.white};
  ${(props) => props.borderColor && `border: 2px solid ${props.borderColor};`}
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  ${(props) =>
    props.isMostUrgent &&
    `&:after {
      content: " ";
      display: block;
      margin-left: 7rem;
      padding-right: 5rem;
      border-top: 2px solid ${props.borderColor || props.backgroundColor};
`}
`
const StatusLabel = (props: StatusCircleProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: props.zeroTopMargin ? 0 : 16,
      }}
    >
      <StatusCircle {...props}>
        {props.icon && <Icon color="white" icon={props.icon} />}
      </StatusCircle>
    </div>
  )
}

const getStatusColor = (status: YearEndModuleStatusOptions) => {
  switch (status) {
    case YearEndModuleStatusOptions.inProgress:
    case YearEndModuleStatusOptions.upNext:
      return Colors.orange
    case YearEndModuleStatusOptions.actionRequired:
      return Colors.yellow
    case YearEndModuleStatusOptions.waitingOnHeard:
      return Colors.blue
    case YearEndModuleStatusOptions.missed:
    case YearEndModuleStatusOptions.overdue:
      return Colors.red
    case YearEndModuleStatusOptions.cancelled:
    case YearEndModuleStatusOptions.upcoming:
      return Colors.mediumGray
    case YearEndModuleStatusOptions.complete:
      return Colors.accentGreen
    case YearEndModuleStatusOptions.earlyStart:
      return Colors.moss
    default:
      return status satisfies never
  }
}

const getStatusLabel = (
  status: YearEndModuleStatusOptions,
  isMostUrgent: boolean
) => {
  switch (status) {
    case YearEndModuleStatusOptions.inProgress:
    case YearEndModuleStatusOptions.upNext: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          icon={solid('hourglass')}
        />
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.earlyStart: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          icon={solid('lock')}
        />
      )
    }
    case YearEndModuleStatusOptions.complete: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          icon={solid('check')}
        />
      )
    }
    case YearEndModuleStatusOptions.missed: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
          icon={solid('xmark')}
        />
      )
    }
    case YearEndModuleStatusOptions.cancelled: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
          icon={solid('xmark')}
        />
      )
    }
    default:
      return status satisfies never
  }
}

const VerticalLineContainer = styled.div<{
  status: YearEndModuleStatusOptions
  last?: boolean
}>`
  background: ${(props) =>
    props.last
      ? 'none'
      : `linear-gradient(${Colors.gray}, ${Colors.gray}) no-repeat
    border-box left 50% top 16px /2px 100%;`};
  margin-bottom: -4rem;
  margin-right: 24px;
`
const YearEndItemContainer = styled.div`
  display: flex;
  padding: 1rem 0;
`

const YearEndListWithStatusIcons = (
  props: YearEndModuleInternalProgressListProps
) => {
  const { yearEndModules, mostUrgentModule, endLineOnLast } = props
  const shouldEndLineOnLast = endLineOnLast ?? true
  const moduleCount = yearEndModules.length
  const form1040PaymentDueDate = useReselector(
    selectTaxDetailsByYear,
    props.taxYear
  )?.form_1040_irs_due_date
  return (
    <div>
      {yearEndModules.map((yeModule, i) => {
        const component = getComponentForYearEndModule({
          ...props,
          yeModule,
          form1040PaymentDueDate,
        })
        const isMostUrgent = mostUrgentModule === yeModule.moduleType
        return (
          <YearEndItemContainer key={yeModule.id}>
            <VerticalLineContainer
              status={yeModule.status}
              last={shouldEndLineOnLast && i === moduleCount - 1}
            >
              {getStatusLabel(yeModule.status, isMostUrgent)}
            </VerticalLineContainer>
            <div
              style={{
                flexGrow: 1,
              }}
            >
              {component}
            </div>
          </YearEndItemContainer>
        )
      })}
    </div>
  )
}

const CompletedModules = (props: YearEndModuleInternalProgressListProps) => {
  const { yearEndModules } = props
  const [accordionOpen, setAccordionOpen] = useState(false)
  const completedCount = yearEndModules.length
  const title = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 24,
          paddingBottom: accordionOpen ? 0 : '1rem',
        }}
      >
        {!accordionOpen && (
          <StatusLabel
            backgroundColor={getStatusColor(
              YearEndModuleStatusOptions.complete
            )}
            icon={solid('check')}
            zeroTopMargin
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 12,
            backgroundColor: Colors.stone40,
            borderRadius: 8,
            marginLeft: accordionOpen ? 55 : 24,
          }}
        >
          <Text as="h3" color="green">
            {accordionOpen
              ? 'Hide completed'
              : `Show ${completedCount} completed`}
          </Text>
          <Icon
            icon={
              accordionOpen ? regular('chevron-up') : regular('chevron-down')
            }
            style={{ marginLeft: 8 }}
            size="1x"
            color="green"
          />
        </div>
      </div>
    ),
    [accordionOpen, completedCount]
  )
  const content = useMemo(() => {
    return <YearEndListWithStatusIcons {...props} />
  }, [props])

  if (yearEndModules.length === 0) {
    return null
  }
  return (
    <Accordion
      variant="text"
      title={title}
      onOpenClick={setAccordionOpen}
      iconColor="transparent"
      content={content}
    />
  )
}

const YearEndModuleProgressList = (props: YearEndModuleProgressListProps) => {
  const { completeModules, incompleteModules, ...rest } = props
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text as="h1">Your Progress</Text>

      <CompletedModules yearEndModules={completeModules} {...rest} />
      <YearEndListWithStatusIcons
        yearEndModules={incompleteModules}
        {...rest}
      />
    </div>
  )
}
export default YearEndModuleProgressList
