import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  Card,
  FormikDropdown,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  FormikScrollOnError,
  GridRowColumn,
  Icon,
  IconButton,
  makeNumberSchema,
  makeReqBoolSchema,
  makeReqStringSchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectTaxListQuestion } from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { IncomeAndLiabilitiesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../../../utils/currencyHelpers'
import {
  getNewRetirementPlanGroupId,
  selectRetirementContributionResponses,
} from './helpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const retirementContributedQuestionIds = [
  TaxListQuestionId.retirement_plan_type,
  TaxListQuestionId.retirement_contributed_amount_in_cents,
  TaxListQuestionId.retirement_needs_help,
  TaxListQuestionId.retirement_plan_rolled_over,
  TaxListQuestionId.retirement_distribution,
]

const RetirementContributedPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId } = useParams()

  const questionRetirementPlanType = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_plan_type,
    taxYear
  )
  const dropdownOptions = questionRetirementPlanType?.options?.map((o) => ({
    text: o.text,
    value: o.id,
    key: o.id,
  }))

  const questionRetirementContributedAmountInCents = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_contributed_amount_in_cents,
    taxYear
  )

  const questionNeedsHelp = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_needs_help,
    taxYear
  )

  const questionRetirementRolledOver = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_plan_rolled_over,
    taxYear
  )

  const questionRetirementDistribution = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_distribution,
    taxYear
  )

  const responseValuesByGroupId = useReselector(
    selectRetirementContributionResponses,
    Number(formId)
  )

  // Needs to be memoized so group id doesn't change on render
  const initialFirstRetirementPlan = useMemo(
    () => ({
      groupId: getNewRetirementPlanGroupId(),
      retirementPlanType: null,
      retirementContributedAmountInCents: null,
      needsHelp: null,
      retirementRolledOver: null,
      retirementDistribution: null,
    }),
    []
  )

  const formik = useFormik({
    initialValues: {
      retirementPlans: Object.values(responseValuesByGroupId ?? {}).length
        ? Object.values(responseValuesByGroupId ?? {}).map((plan) => ({
            groupId: plan.groupId,
            retirementPlanType: plan.retirementPlanType?.value,
            retirementContributedAmountInCents: centsToDollars(
              Number(plan.retirementContributedAmountInCents?.value)
            ),
            needsHelp: plan.needsHelp?.value,
            retirementRolledOver: plan.retirementRolledOver?.value,
            retirementDistribution: plan.retirementDistribution?.value,
          }))
        : [initialFirstRetirementPlan],
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      retirementPlans: yup.array().of(
        yup.object().shape({
          retirementPlanType: makeReqStringSchema(),
          retirementContributedAmountInCents: makeNumberSchema({
            allowedDecimals: 2,
          }),
          needsHelp: makeReqBoolSchema(),
          retirementRolledOver: makeReqBoolSchema(),
          retirementDistribution: makeReqBoolSchema(),
        })
      ),
    }),
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []

      values.retirementPlans.forEach((retirementPlan) => {
        const currentPlan = responseValuesByGroupId?.[retirementPlan.groupId]

        if (
          retirementPlan.retirementPlanType !==
          currentPlan?.retirementPlanType?.value
        ) {
          responseData.push({
            id: currentPlan?.retirementPlanType?.id,
            value: retirementPlan.retirementPlanType,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.retirement_plan_type,
            groupId: retirementPlan.groupId,
          })
        }
        if (
          retirementPlan.retirementContributedAmountInCents !==
          Number(currentPlan?.retirementContributedAmountInCents?.value)
        ) {
          responseData.push({
            id: currentPlan?.retirementContributedAmountInCents?.id,
            value: dollarsToCents(
              Number(retirementPlan.retirementContributedAmountInCents)
            ),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.retirement_contributed_amount_in_cents,
            groupId: retirementPlan.groupId,
          })
        }
        if (retirementPlan.needsHelp !== currentPlan?.needsHelp?.value) {
          responseData.push({
            id: currentPlan?.needsHelp?.id,
            value: retirementPlan.needsHelp,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.retirement_needs_help,
            groupId: retirementPlan.groupId,
          })
        }
        if (
          retirementPlan.retirementRolledOver !==
          currentPlan?.retirementRolledOver?.value
        ) {
          responseData.push({
            id: currentPlan?.retirementRolledOver?.id,
            value: retirementPlan.retirementRolledOver,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.retirement_plan_rolled_over,
            groupId: retirementPlan.groupId,
          })
        }
        if (
          retirementPlan.retirementDistribution !==
          currentPlan?.retirementDistribution?.value
        ) {
          responseData.push({
            id: currentPlan?.retirementDistribution?.id,
            value: retirementPlan.retirementDistribution,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.retirement_distribution,
            groupId: retirementPlan.groupId,
          })
        }
      })
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.retirement],
      })
    },
  })

  const { values, submitForm, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Retirement
          </Text>
        </GridRowColumn>
        <FieldArray name="retirementPlans">
          {({ push, remove }) => (
            <>
              {values.retirementPlans.map((retirementPlan, index) => {
                const planFieldName = `retirementPlans.${index}.retirementPlanType`
                const contributionFieldName = `retirementPlans.${index}.retirementContributedAmountInCents`
                const needsHelpFieldName = `retirementPlans.${index}.needsHelp`
                const rolledOverFieldName = `retirementPlans.${index}.retirementRolledOver`
                const distributionFieldName = `retirementPlans.${index}.retirementDistribution`

                return (
                  <GridRowColumn key={retirementPlan.groupId} {...tqSmallSpace}>
                    <Card backgroundColor="stone40">
                      <Grid>
                        <GridRowColumn
                          short
                          columnStyle={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingBottom: 0,
                          }}
                        >
                          <Text as="h2">Retirement Plan {index + 1}</Text>
                          <IconButton
                            icon={light('trash-can')}
                            style={{
                              cursor: 'pointer',
                              float: 'right',
                            }}
                            onClick={() => remove(index)}
                          />
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Divider />
                        </GridRowColumn>
                        <GridRowColumn>
                          <FormikDropdown
                            name={planFieldName}
                            label={questionRetirementPlanType.question?.text}
                            options={dropdownOptions}
                            placeholder="Select"
                            fullWidth
                            afterLabel={
                              <Popup
                                content={
                                  <Grid>
                                    <GridRowColumn>
                                      <Text as="h3">
                                        Where to find your contribution amount
                                      </Text>
                                    </GridRowColumn>
                                    <GridRowColumn short>
                                      <Text>
                                        You can find this amount on{' '}
                                        <b>Form 5498</b>, which shares how much
                                        contributions were made for the tax year
                                      </Text>
                                    </GridRowColumn>
                                  </Grid>
                                }
                              />
                            }
                          />
                        </GridRowColumn>
                        <GridRowColumn>
                          <FormikInput
                            name={contributionFieldName}
                            label={
                              questionRetirementContributedAmountInCents
                                .question?.text
                            }
                            componentType="currency"
                            placeholder="Enter number"
                            fullWidth
                          />
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Divider />
                        </GridRowColumn>
                        <GridRowColumn>
                          <FormikLabelError
                            name={needsHelpFieldName}
                            label={questionNeedsHelp.question?.text}
                          />
                        </GridRowColumn>
                        <Grid.Row className="short">
                          <Grid.Column width={8}>
                            <FormikRadioToggleButton
                              fullWidth
                              name={needsHelpFieldName}
                              value
                            >
                              Yes
                            </FormikRadioToggleButton>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <FormikRadioToggleButton
                              fullWidth
                              name={needsHelpFieldName}
                              value={false}
                            >
                              No
                            </FormikRadioToggleButton>
                          </Grid.Column>
                        </Grid.Row>
                        {retirementPlan.needsHelp && (
                          <GridRowColumn short>
                            <Alert>
                              Your tax preparer will help you calculate this
                              amount so you can make the contribution before the
                              deadline.
                            </Alert>
                          </GridRowColumn>
                        )}
                        <GridRowColumn short>
                          <Divider />
                        </GridRowColumn>
                        <GridRowColumn>
                          <FormikLabelError
                            name={rolledOverFieldName}
                            label={questionRetirementRolledOver.question?.text}
                            afterLabel={
                              <Popup
                                content={
                                  <Grid>
                                    <GridRowColumn>
                                      <Text as="h3">
                                        Retirement plan rollovers
                                      </Text>
                                    </GridRowColumn>
                                    <GridRowColumn short>
                                      <Text>
                                        “Rolling over” a retirement plan refers
                                        to the transferring of funds from one
                                        retirement account to another.
                                      </Text>
                                    </GridRowColumn>
                                  </Grid>
                                }
                              />
                            }
                          />
                        </GridRowColumn>
                        <Grid.Row className="short">
                          <Grid.Column width={8}>
                            <FormikRadioToggleButton
                              fullWidth
                              name={rolledOverFieldName}
                              value
                            >
                              Yes
                            </FormikRadioToggleButton>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <FormikRadioToggleButton
                              fullWidth
                              name={rolledOverFieldName}
                              value={false}
                            >
                              No
                            </FormikRadioToggleButton>
                          </Grid.Column>
                        </Grid.Row>
                        <GridRowColumn short>
                          <Divider />
                        </GridRowColumn>
                        <GridRowColumn>
                          <FormikLabelError
                            name={distributionFieldName}
                            label={
                              questionRetirementDistribution.question?.text
                            }
                          />
                        </GridRowColumn>
                        <Grid.Row className="short">
                          <Grid.Column width={8}>
                            <FormikRadioToggleButton
                              fullWidth
                              name={distributionFieldName}
                              value
                            >
                              Yes
                            </FormikRadioToggleButton>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <FormikRadioToggleButton
                              fullWidth
                              name={distributionFieldName}
                              value={false}
                            >
                              No
                            </FormikRadioToggleButton>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Card>
                  </GridRowColumn>
                )
              })}

              <GridRowColumn {...tqSmallSpace}>
                <Button
                  style={{ margin: '0 auto' }}
                  variant="secondary"
                  onClick={() =>
                    push({
                      groupId: getNewRetirementPlanGroupId(),
                      retirementPlanType: null,
                      retirementContributedAmountInCents: null,
                      needsHelp: null,
                      retirementRolledOver: null,
                      retirementDistribution: null,
                    })
                  }
                  size="large"
                >
                  <Icon style={{ marginRight: 8 }} icon={light('plus')} />
                  Add Retirement Plan
                </Button>
              </GridRowColumn>
            </>
          )}
        </FieldArray>

        <GridRowColumn {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>

        <UploadDocumentSubSection
          categories={
            values.retirementPlans.some((plan) => plan.retirementPlanType)
              ? [
                  UserDocumentCategoryIdentifier.form1099r,
                  UserDocumentCategoryIdentifier.form5498,
                ]
              : []
          }
        />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default RetirementContributedPanel
