import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'

import { MembershipScopes, User } from '../reducers/auth/userReducer'
import { AsyncReturnType, ReduxState } from './typeHelpers'
import { initialStateSecurityState } from '../reducers/auth/securityReducer'
import { PayrollProfile } from '../features/Payroll/payrollProfile.slice'
import { transformUserToTeamMembers } from '../reducers/taxTeam.slice'
import { membershipIsIn } from '../selectors/user.selectors'
import { FETCH_PAYROLL_PROFILE_KEY } from '../features/Payroll/payrollActions'
const usePendo = process.env.VITE_USE_PENDO === 'true'

interface SessionPayload {
  authenticated: boolean
  user: User
}

// Add pendo to global window namespace
declare global {
  interface Window {
    pendo: pendo.Pendo
  }
}

export const checkLoggedIn = async () => {
  let preloadedState: Partial<ReduxState> | undefined
  const sessionResponse = await axios.get<SessionPayload>('/finances/session')

  const { authenticated, user } = sessionResponse.data
  if (authenticated) {
    datadogRum.setUser({
      id: user.id.toString(),
    })

    let payrollResponse
    // Fetch payroll profile here so sidebar options don't change after load
    if (!user.admin && user.payrollEnabledAt) {
      payrollResponse = await axios.get<PayrollProfile | null>(
        '/finances/api/v2/payroll_profiles'
      )
    }

    preloadedState = {
      auth: {
        user,
        security: {
          ...initialStateSecurityState,
          isAuthenticated: true,
          isAdmin: Boolean(user.admin),
          isFinancesActive: Boolean(user.isFinancesActive),
        },
        rolledOutInstitutions: {
          rolledOutInstitutionIds: [],
        },
      },
      // Set fetch state so payroll profile doesn't reload again later
      fetch: payrollResponse
        ? {
            [FETCH_PAYROLL_PROFILE_KEY]: {
              isLoading: false,
              didInvalidate: false,
              receivedAt: Date.now(),
            },
          }
        : {},
      payrollProfile: payrollResponse?.data || null,
      taxTeam: transformUserToTeamMembers(user),
    }

    if (usePendo) {
      const pendo = window.pendo
      const primaryMembership =
        user.memberships?.find((membership) => membership.isPrimary) ?? null
      const isActiveMember = membershipIsIn(
        MembershipScopes.activePayingOrTrial,
        primaryMembership
      )

      const pendoVisitorState = isActiveMember
        ? 'active'
        : (primaryMembership?.status ?? null)
      pendo.initialize({
        visitor: {
          id: `user-${user.uuid}`,
          admin: Boolean(user.admin),
          state: pendoVisitorState,
        },

        /* 
          Pendo Values
        */
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        account: {
          id: `user-${user.uuid}`,
          admin: Boolean(user.admin),
          is_paying: isActiveMember,
          creationDate: user.createdAt,
        },
      })
    }
  }

  return preloadedState
}

export type PreloadedState = AsyncReturnType<typeof checkLoggedIn>
