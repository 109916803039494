import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import {
  EOYBookkeepingSubstep,
  EOYBookkeepingSubstepId,
  TQSubstep,
  TQSubstepId,
  upsertUserYearEndModuleStatus,
  YearEndModuleStatusOptions,
  YearEndModuleSubstepId,
  YearEndModuleType,
  YearEndModuleStatusRawOptions,
  EOYBookkeepingFollowupSubstep,
  FormFilingJobSubstep,
  fetchUserYearEndModuleStatuses,
} from './yearEndModuleStatus.slice'
import {
  useFetchResponseOnInterval,
  useReselector,
} from '../../utils/sharedHooks'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchTaxFyleExtensionJobUrl,
  fetchTaxFyleJobUrl,
} from '../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import FileUploadModal from '../../components/FileUpload/FileUploadModal'
import { selectUserDocumentCategoryByInternalName } from '../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { useAppDispatch } from '../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { selectAnnualTaxFilingForCurrentTaxYear } from '../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../Taxes/AnnualTaxes/annualTaxFilingForms.selector'
import {
  FETCH_ANNUAL_TAX_OUTCOMES_KEY,
  fetchAnnualTaxOutcomesIfNeeded,
  selectAnnualTaxOutcomesByFormId,
} from '../Taxes/AnnualTaxes/annualTaxOutcome.slice'
import { AnnualTax1040WrapUpModal } from '../Taxes/AnnualTaxes/components/AnnualTaxWrapUpCard'
import { selectIsFetchingForKeys } from '../../reducers/fetch'
import { Loader } from '../../components/BaseComponents'

type LastWorkedOnSubsteps = EOYBookkeepingSubstepId | TQSubstepId
const lastWorkedOnSubsteps: LastWorkedOnSubsteps[] = [
  ...Object.values(EOYBookkeepingSubstep),
  ...Object.values(TQSubstep),
]

const lastWorkedOnSubstepsMap: Record<
  LastWorkedOnSubsteps,
  string | ((taxYear?: string) => string)
> = {
  [EOYBookkeepingSubstep.disconnectedAccounts]: 'Review disconnected accounts',
  [EOYBookkeepingSubstep.uploadMissingStatements]:
    'Upload missing bank statements',
  [EOYBookkeepingSubstep.unclarifiedTransactions]:
    'Review “Uncategorized” transactions',
  [EOYBookkeepingSubstep.otherIncome]: 'Review “Other Income” transactions',
  [EOYBookkeepingSubstep.otherExpenses]: 'Review “Other Expenses” transactions',
  [EOYBookkeepingSubstep.personalAccountBusinessIncome]:
    'Business income in personal accounts',
  [EOYBookkeepingSubstep.personalAccountBusinessExpense]:
    'Business expenses in personal accounts',
  [EOYBookkeepingSubstep.ownerInvestments]: 'Review owner’s investments',
  [EOYBookkeepingSubstep.ownerDistributions]: 'Review owner’s distributions',
  [EOYBookkeepingSubstep.yearEndDocs]: 'Upload year-end documents',
  [EOYBookkeepingSubstep.initialSubmissionReview]: 'Review and submit',
  [TQSubstep.checkYourDetails]: 'Check your details',
  [TQSubstep.tqBigChanges]: (taxYear) => {
    return taxYear ? `Changes in ${taxYear}` : 'Changes this tax year'
  },
  [TQSubstep.tqDeductionsCredits]: 'Deductions and Credits',
  [TQSubstep.tqIncomeLiabilities]: 'Non-therapy income and liabilities',
  [TQSubstep.tqUploadDocuments]: 'Upload documents',
  [TQSubstep.tqAboutYourBusiness]: 'About your business',
  [TQSubstep.tqMissingQtePayments]: 'Missing QTE payments',
}

export const getSubstepURL = (
  taxYear?: string | null,
  substepIdentifier?: YearEndModuleSubstepId | null
): string | null => {
  if (!substepIdentifier || !taxYear) {
    return null
  }
  switch (substepIdentifier) {
    case EOYBookkeepingSubstep.disconnectedAccounts:
      return `/${taxYear}/review-disconnected-accounts`
    case EOYBookkeepingSubstep.uploadMissingStatements:
      return `/${taxYear}/upload-missing-bank-statements`
    case EOYBookkeepingSubstep.unclarifiedTransactions:
      return `/${taxYear}/categorize-transactions`
    case EOYBookkeepingSubstep.otherIncome:
      return `/${taxYear}/other-income`
    case EOYBookkeepingSubstep.otherExpenses:
      return `/${taxYear}/other-expenses`
    case EOYBookkeepingSubstep.personalAccountBusinessIncome:
      return `/${taxYear}/business-income-in-personal`
    case EOYBookkeepingSubstep.personalAccountBusinessExpense:
      return `/${taxYear}/business-expenses-in-personal`
    case EOYBookkeepingSubstep.ownerInvestments:
      return `/${taxYear}/review-owners-investments`
    case EOYBookkeepingSubstep.ownerDistributions:
      return `/${taxYear}/review-owners-distributions`
    case EOYBookkeepingSubstep.yearEndDocs:
      return `/${taxYear}/upload-documents-intro`
    case EOYBookkeepingSubstep.initialSubmissionReview:
      return `/${taxYear}/first-submission`
    case EOYBookkeepingFollowupSubstep.userFollowup:
      return `/${taxYear}/follow-up`
    case EOYBookkeepingFollowupSubstep.finalUserApproval:
      return `/${taxYear}/final-review-intro`
    default:
      return null
  }
}

export const getSubstepIdentifierLastWorkedOnCopy = (
  status: YearEndModuleStatusOptions,
  substepIdentifier: YearEndModuleSubstepId | null | undefined,
  taxYear?: string
): string | null => {
  const id = substepIdentifier as LastWorkedOnSubsteps
  if (
    ![
      YearEndModuleStatusOptions.inProgress,
      YearEndModuleStatusOptions.overdue,
    ].includes(status) ||
    !substepIdentifier ||
    !lastWorkedOnSubsteps.includes(id)
  ) {
    return null
  }
  const copy = lastWorkedOnSubstepsMap[id]
  if (typeof copy === 'function') {
    return copy(taxYear)
  }
  return copy
}

const getFormOrFilingId = (
  moduleType: YearEndModuleType | null,
  formId1040?: number,
  formId1120S?: number,
  filingId?: number
) => {
  if (!moduleType) {
    return {}
  }
  if (moduleType === YearEndModuleType.fileExtensionRequest) {
    return {
      filingId,
    }
  } else if (moduleType === YearEndModuleType.file1120S) {
    return {
      formId: formId1120S,
    }
  } else if (moduleType === YearEndModuleType.file1040) {
    return {
      formId: formId1040,
    }
  }
  // Only return valid form/filing id for the module types that need it
  return {}
}

const shouldStopFetching = (
  moduleType: YearEndModuleType | null,
  status: YearEndModuleStatusOptions | null
) => {
  // If module or status are null we're likely in a loading state, don't terminate fetching
  if (!moduleType || !status) {
    return false
  }
  const moduleIsNotTaxfyleJob =
    moduleType &&
    ![
      YearEndModuleType.fileExtensionRequest,
      YearEndModuleType.file1040,
      YearEndModuleType.file1120S,
    ].includes(moduleType)
  const statusHasNoJobUrl = status === YearEndModuleStatusOptions.upcoming
  // If the module is not a taxfyle job or the status is upcoming, we don't need to fetch
  return moduleIsNotTaxfyleJob || statusHasNoJobUrl
}

export const useModuleActionButtonBehavior = (
  moduleType: YearEndModuleType | null,
  status: YearEndModuleStatusOptions | null,
  substepURL?: string | null,
  substepId?: YearEndModuleSubstepId | null
): { onActionClick: () => void; modal?: ReactNode } => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState({
    verifyTaxEntity: false,
    update1040PaymentInfo: false,
  })
  const cp261DocumentCategory = useReselector(
    selectUserDocumentCategoryByInternalName,
    UserDocumentCategoryIdentifier.cp261Notice
  )
  const filing = useReselector(selectAnnualTaxFilingForCurrentTaxYear)
  const form1040 = useReselector(select1040FormForYear, filing?.year)
  const form1120S = useReselector(select1120sFormForYear, filing?.year)
  const outcomes = useReselector(selectAnnualTaxOutcomesByFormId, form1040?.id)

  useEffect(() => {
    dispatch(fetchAnnualTaxOutcomesIfNeeded(form1040?.id))
  }, [dispatch, form1040?.id])
  const isFetchingTaxOutcomes = useReselector(selectIsFetchingForKeys, [
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_ANNUAL_TAX_OUTCOMES_KEY,
  ])

  const result = getFormOrFilingId(
    moduleType,
    form1040?.id,
    form1120S?.id,
    filing?.id
  )
  const fetchApiCall = useMemo(
    () =>
      moduleType === YearEndModuleType.fileExtensionRequest
        ? fetchTaxFyleExtensionJobUrl
        : fetchTaxFyleJobUrl,
    [moduleType]
  )
  // Taxfyle links expire after 5 minutes, so refresh every 4 minutes
  const linkRes = useFetchResponseOnInterval(
    fetchApiCall,
    240000,
    {
      stopFetching: shouldStopFetching(moduleType, status),
    },
    result.formId || result.filingId
  )
  if (!moduleType) {
    return { onActionClick: () => {} }
  }

  switch (moduleType) {
    case YearEndModuleType.verifyTaxEntity: {
      const onUpload = () => {
        // may want to move this to backend
        upsertUserYearEndModuleStatus({
          moduleType: YearEndModuleType.verifyTaxEntity,
          status: YearEndModuleStatusOptions.waitingOnHeard,
        })(dispatch)
      }
      return {
        onActionClick: () => {
          setModalOpen({ ...modalOpen, verifyTaxEntity: true })
        },
        modal: cp261DocumentCategory?.type ? (
          <FileUploadModal
            open={modalOpen.verifyTaxEntity}
            close={() => setModalOpen({ ...modalOpen, verifyTaxEntity: false })}
            userFacing
            documentType={cp261DocumentCategory.type}
            categoryId={cp261DocumentCategory.id}
            setUploadedFile={onUpload}
          />
        ) : null,
      }
    }
    case YearEndModuleType.taxQuestionnaire:
      return {
        onActionClick: () => navigate('/taxes/annual/tax_checklist/'),
      }
    case YearEndModuleType.submitExtensionRequest:
      return {
        onActionClick: () => navigate('/taxes/annual/extension_request'),
      }
    case YearEndModuleType.eoyBookkeeping:
      return {
        onActionClick: () =>
          navigate(
            `/taxes/annual/complete-year-end-bookkeeping${substepURL ? substepURL : ''}`
          ),
      }
    case YearEndModuleType.file1099Nec: {
      const link =
        status === YearEndModuleStatusOptions.actionRequired
          ? '/taxes/annual'
          : '/taxes/annual/file_1099_nec/verify_business'

      return {
        onActionClick: () => navigate(link),
      }
    }
    case YearEndModuleType.eoyBookkeepingFollowup:
      return {
        onActionClick: () =>
          navigate(
            `/taxes/annual/review-and-close-books${substepURL ? substepURL : ''}`
          ),
      }
    case YearEndModuleType.fileExtensionRequest:
    case YearEndModuleType.file1040: {
      if (substepId === FormFilingJobSubstep.updatePaymentInfo) {
        if (isFetchingTaxOutcomes) {
          return {
            onActionClick: () => {
              setModalOpen({ ...modalOpen, update1040PaymentInfo: true })
            },
            modal: <Loader />,
          }
        }
        return {
          onActionClick: () => {
            setModalOpen({ ...modalOpen, update1040PaymentInfo: true })
          },
          modal: outcomes && (
            <AnnualTax1040WrapUpModal
              modalOpen={modalOpen.update1040PaymentInfo}
              onClose={() => {
                setModalOpen({ ...modalOpen, update1040PaymentInfo: false })
                dispatch(fetchUserYearEndModuleStatuses())
              }}
              year={filing?.year}
              outcomes={outcomes}
            />
          ),
        }
      }
      return {
        onActionClick: () => {
          window.open(linkRes?.jobUrl, '_self', 'noreferrer')
        },
      }
    }
    case YearEndModuleType.file1120S: {
      return {
        onActionClick: () => {
          window.open(linkRes?.jobUrl, '_self', 'noreferrer')
        },
      }
    }
    default:
      return moduleType satisfies never
  }
}

export const getModuleNameCopy = (moduleType: YearEndModuleType) => {
  switch (moduleType) {
    case YearEndModuleType.verifyTaxEntity:
      return 'Verify tax entity status'
    case YearEndModuleType.taxQuestionnaire:
      return 'Complete Tax Questionnaire'
    case YearEndModuleType.fileExtensionRequest:
      return 'File Extension Request'
    case YearEndModuleType.submitExtensionRequest:
      return 'Submit Extension Request'
    case YearEndModuleType.eoyBookkeeping:
      return 'Complete Year-End Bookkeeping'
    case YearEndModuleType.file1099Nec:
      return 'File 1099-NEC'
    case YearEndModuleType.eoyBookkeepingFollowup:
      return 'Review and close books'
    case YearEndModuleType.file1120S:
      return 'File Business Tax Return (Form 1120-S)'
    case YearEndModuleType.file1040:
      return 'File Personal Tax Return (Form 1040)'
    default:
      return moduleType satisfies never
  }
}

export const getBlockedByText = (
  blockedByModule: YearEndModuleType[],
  isBlockedByOnboarding = false,
  launchDate?: DateTime | null
) => {
  const launchDateText = launchDate?.isValid ? (
    <b>on {launchDate.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}</b>
  ) : (
    ' '
  )
  const baseText = <>Available {launchDateText} after completing</>
  const fallbackText = 'Coming soon'
  const modulesNames = blockedByModule.map((moduleType) =>
    getModuleNameCopy(moduleType)
  )
  const blockingNames: string[] = modulesNames.length > 0 ? modulesNames : []

  if (isBlockedByOnboarding) {
    blockingNames.push('Onboarding Tasks on your Heard Home page')
  }
  if (blockingNames.length === 0) {
    if (launchDate) {
      return <>Available {launchDateText}</>
    }
    return <b>{fallbackText}</b>
  }
  return (
    <>
      {baseText}{' '}
      {blockingNames.length < 3 ? (
        blockingNames.map((name, index) => (
          <span key={name}>
            <b>{name}</b>
            {index < blockingNames.length - 1 ? ' and ' : ''}
          </span>
        ))
      ) : (
        <>
          {blockingNames.slice(0, blockingNames.length - 1).map((name) => (
            <span key={name}>
              <b>{name}</b>,{' '}
            </span>
          ))}
          and <b>{blockingNames[blockingNames.length - 1]}</b>
        </>
      )}
    </>
  )
}

export const getModuleComplementaryText = (moduleType: YearEndModuleType) => {
  switch (moduleType) {
    case YearEndModuleType.fileExtensionRequest:
    case YearEndModuleType.file1120S:
    case YearEndModuleType.file1040:
      return ', depending on how quickly you respond to your tax preparer.'
    case YearEndModuleType.eoyBookkeepingFollowup:
      return ', depending on how quickly you respond to your bookkeeper.'
    default:
      return ''
  }
}

export const getYearModuleStatusRawOptionsTag = (
  status: YearEndModuleStatusRawOptions
) => {
  switch (status) {
    case YearEndModuleStatusRawOptions.upNext:
      return 'Not Started'
    case YearEndModuleStatusRawOptions.earlyStart:
      return 'Early Start'
    case YearEndModuleStatusRawOptions.inProgress:
      return 'In Progress'
    case YearEndModuleStatusRawOptions.overdue:
      return 'Overdue'
    case YearEndModuleStatusRawOptions.missed:
      return 'Missed'
    case YearEndModuleStatusRawOptions.upcoming:
      return 'Blocked'
    case YearEndModuleStatusRawOptions.waitingOnHeard:
      return 'Waiting On Heard/Taxfyle'
    case YearEndModuleStatusRawOptions.actionRequired:
      return 'Waiting on Therapist'
    case YearEndModuleStatusRawOptions.complete:
      return 'Complete'
    case YearEndModuleStatusRawOptions.cancelled:
      return 'Cancelled'
    case YearEndModuleStatusRawOptions.notApplicable:
      return 'Not Applicable'
    case YearEndModuleStatusRawOptions.requiresKickoffSurvey:
      return 'Blocked - No TSK'
    default:
      return status satisfies never
  }
}
