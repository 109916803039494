import { PostOnboardingTaskIdents } from '../../../Onboarding/config'
import FileUploadModal from '../../../../components/FileUpload/FileUploadModal'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectDocumentCategoryByUserActionItemId } from '../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import OnboardingEstimatedTaxPaymentsModal from '../../../Onboarding/OnboardingEstimatedTaxPaymentsModal'
import { markUserActionItemCompleteIfExists } from '../service'
import NoDocumentCheckboxLabel from '../../../../components/FileUpload/NoDocumentCheckboxLabel'
import { selectUserActionItemById } from '../userActionItems.selectors'
import PayrollComplianceModal from '../../PayrollComplianceModal'
import { PayrollTaskIdents } from '../../../Payroll/helpers'
import { useAnalyticsTrack } from '../../../Amplitude'
import { UserActionItemActionItemIdentifiers } from '../userActionItems.slice'
import { SmsConsentModal } from '../../../SmsConsent/SmsConsentModal'
import { SmsConsentSource } from '../../../SmsConsent/smsConsentReducer'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

const OVERRIDE_CHECKBOX_CATEGORIES: string[] = [
  PostOnboardingTaskIdents.UPLOAD_TAX_RETURN_1040,
  PostOnboardingTaskIdents.UPLOAD_TAX_RETURN_1120S,
]

const UserActionListItemExtras = ({
  userActionItemId,
  open,
  onClose,
}: {
  userActionItemId: number
  open: boolean
  onClose: () => void
}) => {
  const track = useAnalyticsTrack()
  const userActionItem = useReselector(
    selectUserActionItemById,
    userActionItemId
  )
  const documentCategory = useReselector(
    selectDocumentCategoryByUserActionItemId,
    userActionItemId
  )
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  if (!userActionItem) {
    return null
  }

  const {
    actionItem: { identifier },
    templateValue,
  } = userActionItem

  if (identifier === PostOnboardingTaskIdents.ENTER_QTE_PAYMENTS) {
    return <OnboardingEstimatedTaxPaymentsModal open={open} onClose={onClose} />
  }

  if (identifier === PayrollTaskIdents.REVIEW_PAYROLL_COMPLIANCE) {
    return (
      <PayrollComplianceModal
        open={open}
        onClose={onClose}
        onSuccess={() => {
          onClose()
          markUserActionItemCompleteIfExists(identifier, (event, properties) =>
            track(event, properties)
          )
        }}
      />
    )
  }

  if (documentCategory?.type && identifier) {
    return (
      <FileUploadModal
        year={
          userActionItem?.templateValue
            ? userActionItem.templateValue
            : currentTaxYear
        }
        open={open}
        close={onClose}
        userFacing
        documentType={documentCategory.type}
        categoryId={documentCategory.id}
        setUploadedFile={() =>
          markUserActionItemCompleteIfExists(identifier, (event, properties) =>
            track(event, properties)
          )
        }
        overrideCallback={() => {
          markUserActionItemCompleteIfExists(identifier, (event, properties) =>
            track(event, properties)
          )
        }}
        overrideCheckboxLabel={
          OVERRIDE_CHECKBOX_CATEGORIES.includes(identifier) && templateValue ? (
            `I did not file my ${templateValue} tax returns`
          ) : (
            <NoDocumentCheckboxLabel />
          )
        }
      />
    )
  }

  if (identifier === UserActionItemActionItemIdentifiers.smsOptIn) {
    return (
      <SmsConsentModal
        open={open}
        source={SmsConsentSource.ACTION_ITEM}
        onClose={onClose}
      />
    )
  }

  return null
}

export default UserActionListItemExtras
