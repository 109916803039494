import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import {
  getUserDocumentsByCategoryInternalName,
  selectSortedTaxReturnDocumentsForYear,
} from '../../../UserDocuments/userDocuments.selector'
import { useNavigate } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Colors } from '../../../../styles/theme'

const centeredColumnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const CompletedAllModulesCard = ({
  taxYear,
  optedOutOfTaxesWithHeard,
}: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
}) => {
  const navigate = useNavigate()
  const user = useReselector(getCurrentUser)
  const balanceSheet = useReselector(
    getUserDocumentsByCategoryInternalName,
    UserDocumentCategoryIdentifier.balanceSheet
  )
  const profitAndLoss = useReselector(
    getUserDocumentsByCategoryInternalName,
    UserDocumentCategoryIdentifier.profitAndLoss
  )

  const businessTaxReturnsForYear = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    taxYear
  ).business

  const personalTaxReturnsForYear = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    taxYear
  ).personal

  const content = optedOutOfTaxesWithHeard ? (
    <Text>We&apos;ve reviewed and closed your books for the year.</Text>
  ) : (
    <Text>
      The IRS has accepted your filing(s). Thanks for allowing us to guide you
      along the way. You can find your returns below and in the{' '}
      <Button onClick={() => navigate('/taxes/annual')} variant="link">
        Tax Return Archive.
      </Button>
    </Text>
  )

  const downloadLinks = optedOutOfTaxesWithHeard
    ? [
        {
          url: balanceSheet[0]?.signedUrl,
          title: balanceSheet[0]?.fileDescription,
        },
        {
          url: profitAndLoss[0]?.signedUrl,
          title: profitAndLoss[0]?.fileDescription,
        },
      ]
    : [
        {
          url: businessTaxReturnsForYear[0]?.signedUrl,
          title: businessTaxReturnsForYear[0]?.fileDescription,
        },
        {
          url: personalTaxReturnsForYear[0]?.signedUrl,
          title: personalTaxReturnsForYear[0]?.fileDescription,
        },
      ]

  return (
    <Card
      style={{
        maxWidth: '800px',
        marginBottom: '64px',
        backgroundColor: Colors.natural,
      }}
    >
      <Card.Content>
        <Grid>
          <GridRowColumn columnStyle={centeredColumnStyle}>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/celebration.svg"
              style={{
                width: '250px',
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </GridRowColumn>
          <GridRowColumn columnStyle={centeredColumnStyle}>
            <Text as="display2">Congrats, {user?.firstName}!</Text>
          </GridRowColumn>
          <GridRowColumn
            columnStyle={{ textAlign: 'center', justifyItems: 'center' }}
          >
            {content}
          </GridRowColumn>
          <GridRowColumn columnStyle={{ ...centeredColumnStyle, gap: 16 }}>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {downloadLinks.map((link) =>
                link.url ? (
                  <li key={link.title}>
                    <Link href={link.url} newPage size="small">
                      <Icon
                        icon={regular('file-lines')}
                        style={{ marginRight: '8px' }}
                      />
                      {link.title}
                    </Link>
                  </li>
                ) : null
              )}
            </ul>
          </GridRowColumn>
        </Grid>
      </Card.Content>
    </Card>
  )
}

export default CompletedAllModulesCard
