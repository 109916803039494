import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FormikProvider, useFormik } from 'formik'
import { DateTime } from 'luxon'

import {
  Accordion,
  Button,
  Card,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikScrollOnError,
  FormikSSNInput,
  getFieldName,
  GridRowColumn,
  Icon,
  Link,
  makeDateSchema,
  makeReqStringSchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import { Colors } from '../../../../../../styles/theme'
import {
  useFetchResponse,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import RemoveDependentModal from './RemoveDependentModal'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
} from '../../../../../../utils/dateHelpers'
import { Form1040DetailsProps, FORM_1040_DETAILS_SCREENS } from '.'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponseByFormIdAndDependentId,
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  createOrUpdateDependents,
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  DELETED_DEPENDENTS_KEY,
  deleteDependents,
  deleteTaxQuestionnaireResponses,
  DependentRelationship,
  FETCH_DEPENDENTS_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  fetchDependents,
  postUpdateTaxQuestionnaireResponses,
  UPSERT_DEPENDENTS_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  UserDependent,
} from '../../taxChecklistQuestion.actions'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import ReadOnlyForm from '../../Shared/ReadOnlyForm'
import {
  createIsSomeEnum,
  useAppDispatch,
} from '../../../../../../utils/typeHelpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { StepUpdates } from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import {
  EncryptionError,
  useEncryption,
} from '../../../../../../utils/evervault'
import { tqBigSpace } from '../../helpers'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { skipQuestionId, useSkipQuestion } from '../../Shared/SkipQuestion'
import useMissingAnswerValidation from '../../Shared/UseMissingAnswerValidation'

const isDependentRelationship = createIsSomeEnum(DependentRelationship)

interface FormProps {
  dependent: Partial<UserDependent>
  index: number
  removeDependent: ({
    index,
    dependentId,
  }: {
    index: number
    dependentId?: number
  }) => void
  goToNextStep: (
    data: Partial<TaxChecklistResponse>[] | null,
    newScreen: FORM_1040_DETAILS_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => FORM_1040_DETAILS_SCREENS
  ) => Promise<boolean>
  loadDependents: () => void
}

export const dependentQuestionIds = [
  TaxListQuestionId.dependent_full_name,
  TaxListQuestionId.dependent_date_of_birth,
  TaxListQuestionId.dependent_ssn,
  TaxListQuestionId.dependent_relationship,
]

const DependentForm = ({
  dependent,
  index,
  goToNextStep,
  removeDependent,
  loadDependents,
}: FormProps) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const ssnMask = '***-**-****'
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    UPSERT_DEPENDENTS_KEY,
    DELETED_DEPENDENTS_KEY,
  ])
  const [error, setError] = useState<string>()
  const { encrypt } = useEncryption()

  const questionName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_full_name,
    taxYear
  )
  const responseName = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_full_name,
    formId,
    dependent.id
  )
  const questionRelationship = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_relationship,
    taxYear
  )
  const responseRelationship = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_relationship,
    formId,
    dependent.id
  )
  const questionDob = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_date_of_birth,
    taxYear
  )
  const responseDob = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_date_of_birth,
    formId,
    dependent.id
  )
  const questionSsn = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.dependent_ssn,
    taxYear
  )
  const responseSsn = useReselector(
    selectTaxListQuestionResponseByFormIdAndDependentId,
    TaxListQuestionId.dependent_ssn,
    formId,
    dependent.id
  )

  // dependent ssns are stored in 2 separate tables
  // if no checklist response is set yet, fall back to the value from the user_dependents table
  const ssn = useMemo(
    () =>
      typeof responseSsn?.value === 'string'
        ? responseSsn.value
        : dependent.encryptedSsn,
    [responseSsn, dependent.encryptedSsn]
  )

  // note: validateOnMount doesn't work with enableReinitialize - https://github.com/jaredpalmer/formik/issues/3051
  const formik = useFormik({
    initialValues: {
      dependentName: responseName?.value ?? dependent.name,
      relationship:
        responseRelationship?.value ?? dependent.relationship?.toUpperCase(),
      dependentDob:
        responseDob?.value ??
        (typeof dependent.dateOfBirth === 'string'
          ? DateTime.fromISO(dependent.dateOfBirth)
              .toUTC()
              .toFormat(DATE_FORMATS_LUXON.INPUT)
          : undefined),
      dependentSsn: ssn,
      confirmDependentSsn: ssn,
    },
    isInitialValid: Boolean(
      (responseName?.value ?? dependent.name) &&
        (responseRelationship?.value ?? dependent.relationship) &&
        (responseDob?.value ?? dependent.dateOfBirth) &&
        ssn
    ),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setError(undefined)
      try {
        const encryptedSsn = await encrypt(values.dependentSsn)
        const responseData: Partial<TaxChecklistResponse>[] = [
          {
            id: responseName?.id,
            value: values.dependentName,
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_full_name,
            userDependentId: dependent.id,
          },
          {
            id: responseRelationship?.id,
            value: values.relationship ?? undefined,
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_relationship,
            userDependentId: dependent.id,
          },
          {
            id: responseDob?.id,
            value: values.dependentDob ?? undefined,
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_date_of_birth,
            userDependentId: dependent.id,
          },
          {
            id: responseSsn?.id,
            value: encryptedSsn,
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_ssn,
            userDependentId: dependent.id,
          },
        ]
        const userDependentRelationship =
          typeof values.relationship === 'string'
            ? values.relationship.toLowerCase()
            : undefined
        const userDependentUpdates: Partial<UserDependent> = {
          id: dependent.id,
          name:
            typeof values.dependentName === 'string'
              ? values.dependentName
              : undefined,
          relationship: isDependentRelationship(userDependentRelationship)
            ? userDependentRelationship
            : undefined,
          dateOfBirth:
            typeof values.dependentDob === 'string'
              ? (DateTime.fromFormat(
                  values.dependentDob,
                  DATE_FORMATS_LUXON.INPUT
                ).toISO() ?? undefined)
              : undefined,
          encryptedSsn,
        }
        let updateSuccess = true
        let userDependentId: number | null = null
        if (Object.keys(userDependentUpdates).length > 0) {
          const upsertResponse = await createOrUpdateDependents([
            userDependentUpdates,
          ])(dispatch)
          updateSuccess = Boolean(
            upsertResponse?.length && upsertResponse.length > 0
          )
          userDependentId = upsertResponse?.[0]?.id ?? null
        }
        if (updateSuccess && responseData.length > 0 && userDependentId) {
          await goToNextStep(
            responseData.map((rd) => ({ ...rd, userDependentId })),
            FORM_1040_DETAILS_SCREENS.skipNavigation
          )
          // skipcq: JS-0357 -- used before definition
          setEditing(false)
          loadDependents()
        }
        return true
      } catch (err) {
        if (err instanceof EncryptionError) {
          setError(err.displayMessage)
        } else {
          setError('An error occurred. Please try again.')
        }
        // skipcq: JS-0357 -- used before definition
        setEditing(true)
        return false
      }
    },
  })

  const { values, isValid, submitForm, resetForm, isSubmitting } = formik
  const [editing, setEditing] = useState(!isValid)

  const readOnly = useMemo(() => {
    return [
      { ...questionName, responseText: values.dependentName },
      { ...questionDob, responseText: values.dependentDob },
      {
        ...questionSsn,
        responseText: values.dependentSsn ? ssnMask : '',
      },
      {
        ...questionRelationship,
        responseText: questionRelationship?.options?.find(
          (o) => o.id === values.relationship
        )?.text,
      },
    ]
  }, [questionDob, questionName, questionRelationship, questionSsn, values])

  const onCancel = useCallback(() => {
    if (!dependent.id) {
      removeDependent({ index })
    }
    resetForm()
    setEditing(false)
  }, [dependent.id, index, removeDependent, resetForm])

  // User should not be able to submit dependents born after tax year
  const maxDate = DateTime.fromObject({ year: Number(taxYear) })
    .endOf('year')
    .toJSDate()

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <RemoveDependentModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        removeDependent={() =>
          removeDependent({ index, dependentId: dependent.id })
        }
      />
      {/* READ ONLY FORM */}
      {!editing && (
        <ReadOnlyForm
          title={`Dependent ${index + 1}`}
          setEditing={() => setEditing(true)}
          readOnlyData={readOnly}
        />
      )}
      {/* EDITABLE FORM */}
      {editing && (
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <GridRowColumn
              columnStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text as="h2" color="forest">
                Dependent {index + 1}
              </Text>
              {dependent.id && (
                <Button
                  style={{ borderColor: Colors.red }}
                  variant="secondary"
                  onClick={() => setModalOpen(true)}
                >
                  <Icon size="1x" color="red" icon={regular('trash-can')} />
                </Button>
              )}
            </GridRowColumn>
            <GridRowColumn short>
              <Divider />
            </GridRowColumn>
            <GridRowColumn short>
              <FormikInput
                label={questionName?.question?.text}
                name={getFieldName<typeof values>('dependentName')}
                schema={makeReqStringSchema({
                  field: 'full name',
                })}
                placeholder="Enter First and Last Name"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn width={8}>
              <FormikDateInput
                maxDate={maxDate}
                label={questionDob?.question?.text}
                placeholder="MM-DD-YYYY"
                name={getFieldName<typeof values>('dependentDob')}
                schema={makeDateSchema({
                  field: 'date of birth',
                  format: DATE_FORMATS.INPUT,
                  strict: true,
                  maxDate: maxDate.toISOString(),
                })}
                fullWidth
              />
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormikSSNInput
                  label={questionSsn?.question?.text}
                  name={getFieldName<typeof values>('dependentSsn')}
                  fullWidth
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikSSNInput
                  label={`Confirm ${questionSsn?.question?.text}`}
                  name={getFieldName<typeof values>('confirmDependentSsn')}
                  fullWidth
                  confirmationValue={values.dependentSsn}
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn width={8}>
              <FormikDropdown
                label={questionRelationship?.question?.text}
                name={getFieldName<typeof values>('relationship')}
                options={questionRelationship?.options?.map((o) => ({
                  text: o.text,
                  value: o.id,
                  key: o.id,
                }))}
                schema={makeReqStringSchema({ field: 'relationship' })}
                fullWidth
                afterLabel={
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text as="h3">Dependent relationships</Text>
                        </GridRowColumn>
                        <GridRowColumn short>
                          <Text>
                            If none of the dependent relationships listed here
                            apply, select <b>Other</b>.
                          </Text>
                        </GridRowColumn>
                      </Grid>
                    }
                    trigger={
                      <Icon
                        size="1x"
                        style={{ marginLeft: 4 }}
                        icon={regular('question-circle')}
                        color="mediumGray"
                      />
                    }
                  />
                }
              />
            </GridRowColumn>
            <GridRowColumn>
              <Divider />
            </GridRowColumn>
            {error && (
              <GridRowColumn>
                <Text color="red">{error}</Text>
              </GridRowColumn>
            )}
            <GridRowColumn
              short
              columnStyle={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 20,
              }}
            >
              <Button
                onClick={onCancel}
                style={{ border: 0 }}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                loading={isUpdating || isSubmitting}
                variant="secondary"
                onClick={submitForm}
                disabled={isUpdating || isSubmitting}
              >
                Save
              </Button>
            </GridRowColumn>
          </Grid>
        </Card>
      )}
    </FormikProvider>
  )
}

const DependentsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1040DetailsProps) => {
  const dispatch = useAppDispatch()
  const [dependents, setDependents] = useState<Partial<UserDependent>[]>([])
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const { errorAlert, triggerError } = useMissingAnswerValidation(
    'Please add at least one dependent'
  )

  const allDependentResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    dependentQuestionIds,
    formId
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPSERT_DEPENDENTS_KEY,
    DELETED_DEPENDENTS_KEY,
  ])

  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_DEPENDENTS_KEY,
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
  ])

  const claimingDependentsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.has_dependents,
    formId,
  })

  // Derived response
  const responseNumberOfDependents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_dependents,
    formId
  )
  const responseChildTaxCredit = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.child_tax_credit,
    formId
  )

  const loadDependents = useCallback(async () => {
    const existing = await fetchDependents()(dispatch)
    if (existing?.length && existing.length > 0) {
      setDependents(existing)
    }
  }, [dispatch])

  const dependentsOnInitialLoad = useFetchResponse(fetchDependents)

  const hasDependentsUnder17 = useMemo(() => {
    return dependents.some((d) => {
      const endOfTaxYear = DateTime.fromFormat(`${taxYear}-12-31`, 'yyyy-LL-dd')
      const dob = d.dateOfBirth && DateTime.fromISO(d.dateOfBirth).toUTC()
      return dob && endOfTaxYear.diff(dob, 'years').years < 17
    })
  }, [dependents, taxYear])

  //this effect is meant to only happen on initial page load to account for the case where the therapist
  //has user dependents but has no checklist responses yet for those dependents.
  useEffect(() => {
    const loadInitialDependents = async () => {
      if (!dependentsOnInitialLoad || isFetching) {
        return
      }

      const responseData: Partial<TaxChecklistResponse>[] = []
      //if the dependent exists but there are no tax checklist responses, seed the responses
      for (const dependent of dependentsOnInitialLoad) {
        const responseName = allDependentResponses.find(
          (r) =>
            r.userDependentId === dependent.id &&
            r.questionId === TaxListQuestionId.dependent_full_name
        )
        const responseRelationship = allDependentResponses.find(
          (r) =>
            r.userDependentId === dependent.id &&
            r.questionId === TaxListQuestionId.dependent_relationship
        )
        const responseDob = allDependentResponses.find(
          (r) =>
            r.userDependentId === dependent.id &&
            r.questionId === TaxListQuestionId.dependent_date_of_birth
        )

        if (dependent.name && !responseName) {
          responseData.push({
            value: dependent.name,
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_full_name,
            userDependentId: dependent.id,
          })
        }
        if (dependent.relationship && !responseRelationship) {
          responseData.push({
            value: dependent.relationship.toUpperCase(),
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_relationship,
            userDependentId: dependent.id,
          })
        }
        if (dependent.dateOfBirth && !responseDob) {
          responseData.push({
            value: DateTime.fromISO(dependent.dateOfBirth)
              .toUTC()
              .toFormat(DATE_FORMATS_LUXON.INPUT),
            annualTaxFilingFormId: formId,
            questionId: TaxListQuestionId.dependent_date_of_birth,
            userDependentId: dependent.id,
          })
        }
      }
      let responseSuccess = true
      if (responseData.length > 0) {
        responseSuccess = Boolean(
          await postUpdateTaxQuestionnaireResponses(responseData)(dispatch)
        )
      }
      if (responseSuccess) {
        setDependents(dependentsOnInitialLoad)
      }
    }

    loadInitialDependents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //we don't want this effect happening again, so we are not going to add a dependency to allDependentResponses
    dependentsOnInitialLoad,
    dispatch,
    formId,
    isFetching,
  ])

  const skippedQuestions = useSkipQuestion([TaxListQuestionId.has_dependents])

  const dependentsForm = useFormik({
    initialValues: {
      [TaxListQuestionId.has_dependents]: claimingDependentsQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse | undefined>[] = []

      responseData.push(claimingDependentsQR.getResponseData(values))

      const numberOfDependents =
        values[TaxListQuestionId.has_dependents] !== true
          ? 0
          : dependents.length
      if (numberOfDependents !== responseNumberOfDependents?.[0]?.value) {
        responseData.push({
          id: responseNumberOfDependents?.[0]?.id,
          value:
            values[TaxListQuestionId.has_dependents] !== true
              ? 0
              : dependents.length,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.number_of_dependents,
        })
      }
      if (responseChildTaxCredit?.[0]?.value !== hasDependentsUnder17) {
        responseData.push({
          id: responseChildTaxCredit?.[0]?.id,
          value: hasDependentsUnder17,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.child_tax_credit,
        })
      }

      let deleteSuccess = true
      if (values[TaxListQuestionId.has_dependents] === false) {
        if (allDependentResponses.length > 0) {
          deleteSuccess = Boolean(
            await dispatch(
              deleteTaxQuestionnaireResponses(
                allDependentResponses.map((r) => r.id)
              )
            )
          )
        }
        if (deleteSuccess && dependents.filter((d) => d.id).length > 0) {
          deleteSuccess = Boolean(
            await dispatch(
              deleteDependents(dependents.flatMap((d) => d.id ?? []))
            )
          )
        }
      }
      if (deleteSuccess) {
        await goToNextStep(responseData, nextScreen ?? null, {
          completedSteps: [SubStepIdentifiers.spouseAndDependentInformation],
        })
      }
    },
  })

  const { values, submitForm, isSubmitting } = dependentsForm

  const removeDependent = useCallback(
    async ({ index, dependentId }: { index: number; dependentId?: number }) => {
      let success = true
      if (dependentId) {
        const responsesToDelete = allDependentResponses.flatMap((r) =>
          r.userDependentId === dependentId ? [r.id] : []
        )
        success = Boolean(await dispatch(deleteDependents([dependentId])))
        if (success) {
          success = Boolean(
            await dispatch(deleteTaxQuestionnaireResponses(responsesToDelete))
          )
        }
      }
      if (success) {
        setDependents((old) => {
          const newList = [...old]
          newList.splice(index, 1)
          return newList
        })
      }
      return true
    },
    [dispatch, allDependentResponses]
  )

  const addDependent = useCallback(() => {
    setDependents((old) => [...old, {}])
  }, [])

  return (
    // skipcq: JS-0415
    <FormikProvider value={dependentsForm}>
      <FormikScrollOnError />
      <Grid>
        {errorAlert}
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Your Dependents
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Please look over the following and update anything that has changed.
            These answers will appear on your tax return.
          </Text>
        </GridRowColumn>
        <TQYesNoResponse
          questionResponse={claimingDependentsQR}
          requiresFollowup
          skippable
          onYesClick={() => {
            if (dependents.length === 0) {
              addDependent()
            }
          }}
        />
        {values[TaxListQuestionId.has_dependents] &&
          dependents.map((dep, index) => (
            <GridRowColumn key={dep.id ?? index}>
              <DependentForm
                index={index}
                dependent={dep}
                removeDependent={removeDependent}
                goToNextStep={goToNextStep}
                loadDependents={loadDependents}
              />
            </GridRowColumn>
          ))}
        {values[TaxListQuestionId.has_dependents] === true && (
          <GridRowColumn centerContent>
            <Button variant="secondary" onClick={() => addDependent()}>
              <Icon
                size="1x"
                icon={regular('plus')}
                style={{ marginRight: 8 }}
              />
              <Text>Add Dependent</Text>
            </Button>
          </GridRowColumn>
        )}
        <Grid.Row />
        <GridRowColumn {...tqBigSpace}>
          <Accordion
            style={{
              borderTop: '1px solid #E0E0E0',
              borderBottom: '1px solid #E0E0E0',
            }}
            contentStyle={{ paddingTop: 0 }}
            backgroundColor="white"
            title="Who qualifies as a dependent?"
            content={
              <Grid>
                <GridRowColumn>
                  <Text as="bodyLg">
                    A child or relative of the taxpayer who meets the IRS&apos;s
                    qualifying tests. You can find those tests{' '}
                    <Link
                      as="secondaryLink"
                      newPage
                      size="large"
                      href="https://apps.irs.gov/app/vita/content/globalmedia/4491_dependency_exemptions.pdf"
                    >
                      here
                    </Link>
                    .
                  </Text>
                </GridRowColumn>
              </Grid>
            }
          />
        </GridRowColumn>
        <FormFlowFooter
          continueDisabled={isUpdating || isSubmitting}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={
            values[TaxListQuestionId.has_dependents] &&
            (!dependents.length || !dependents[0]?.name)
              ? triggerError
              : submitForm
          }
        />
      </Grid>
    </FormikProvider>
  )
}

export default DependentsPanel
