import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { convertUtcToLocalDate } from '../../../../utils/dateHelpers'

/*
Update the Deadline for the TSK to be a rolling two weeks for people who join 1/2 onwards, and then March 3 (S-corp) and April 1 (Sole Prop) for all therapists who join Feb 17 and Mar 18, respectively.

For s-corps who join between 1/1 and 2/17, the due date will be a rolling two weeks. Then those who join after 2/17, the due date should be fixed to 3/3. 

For sole props who join between 1/1 and 3/18, the due date will be a rolling two weeks. Then those who join after 3/18, the due date should be fixed to 4/1. 

*/

export const getTskDueDateForUser = ({
  membershipStartDate,
  taxEntityType,
  originalTskDueDate,
  newUserCutOffAt,
  form1020SCloseBooksDueDate,
  form1020SExtensionSurveyDueDate,
  form1040CloseBooksDueDate,
  form1040ExtensionSurveyDueDate,
}: {
  membershipStartDate: string | undefined
  taxEntityType: TAX_ENTITY_TYPES | null | undefined
  originalTskDueDate: string | undefined
  newUserCutOffAt: string | undefined
  form1020SCloseBooksDueDate: string | undefined
  form1020SExtensionSurveyDueDate: string | undefined
  form1040CloseBooksDueDate: string | undefined
  form1040ExtensionSurveyDueDate: string | undefined
}) => {
  // If membershipStartDate is undefined, fall back to original due date or return null
  if (!membershipStartDate) {
    return originalTskDueDate ? convertUtcToLocalDate(originalTskDueDate) : null
  }

  const joinDate = convertUtcToLocalDate(membershipStartDate)
  if (!joinDate) return null

  // Use original due date for all users who joined before 1/1/2025
  const cutoffYear = convertUtcToLocalDate(newUserCutOffAt)
  if (!cutoffYear) return null

  if (joinDate < cutoffYear) {
    return originalTskDueDate
      ? convertUtcToLocalDate(originalTskDueDate)
      : joinDate
  }

  const twoWeeksFromJoin = joinDate.plus({ days: 14 })

  // S-Corp
  if (
    taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
    taxEntityType === TAX_ENTITY_TYPES.form_1120
  ) {
    const scorpCutoffDate = convertUtcToLocalDate(form1020SCloseBooksDueDate)
    const scorpFixedDueDate = convertUtcToLocalDate(
      form1020SExtensionSurveyDueDate
    )
    if (!scorpCutoffDate || !scorpFixedDueDate) return null

    // If joined before cutoff date, use rolling two weeks but capped at fixed due date
    if (joinDate < scorpCutoffDate) {
      return twoWeeksFromJoin <= scorpFixedDueDate
        ? twoWeeksFromJoin
        : scorpFixedDueDate
    }
    // If joined after cutoff date, use fixed due date
    return scorpFixedDueDate
  }

  // Sole Prop
  if (taxEntityType === TAX_ENTITY_TYPES.form_1040) {
    const solePropCutoffDate = convertUtcToLocalDate(form1040CloseBooksDueDate)
    const solePropFixedDueDate = convertUtcToLocalDate(
      form1040ExtensionSurveyDueDate
    )
    if (!solePropCutoffDate || !solePropFixedDueDate) return null

    // If joined before cutoff date, use rolling two weeks but capped at fixed due date
    if (joinDate < solePropCutoffDate) {
      return twoWeeksFromJoin <= solePropFixedDueDate
        ? twoWeeksFromJoin
        : solePropFixedDueDate
    }
    // If joined after cutoff date, use fixed due date
    return solePropFixedDueDate
  }

  // Default fallback to original due date or null if undefined
  return originalTskDueDate ? convertUtcToLocalDate(originalTskDueDate) : null
}
