import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

import { useReselector } from '../../../utils/sharedHooks'
import WelcomeToPayroll from './WelcomeToPayroll'
import { fetchPayrollProfileIfNeeded } from '../payrollActions'
import {
  selectShouldShowPayrollConfirmationPage,
  selectShouldShowPayrollWelcomePage,
} from '../payroll.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { PayrollInterestOutroContent } from './InterestFlow/PayrollInterestOutro'

const PayrollUpsell = () => {
  const dispatch = useAppDispatch()
  const shouldShowPayrollWelcomePage = useReselector(
    selectShouldShowPayrollWelcomePage
  )
  const shouldShowPayrollConfirmationPage = useReselector(
    selectShouldShowPayrollConfirmationPage
  )

  useEffect(() => {
    dispatch(fetchPayrollProfileIfNeeded())
  }, [dispatch])

  if (shouldShowPayrollWelcomePage) {
    return <WelcomeToPayroll />
  } else if (shouldShowPayrollConfirmationPage) {
    return (
      <Grid>
        <PayrollInterestOutroContent />
      </Grid>
    )
  }

  return null
}

export default PayrollUpsell
