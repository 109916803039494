import { useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Divider, Grid } from 'semantic-ui-react'

import { Form1120sProps } from '.'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId, useTaxListQuestionRes } from '../service'
import { selectTaxListQuestionResponsesByFormId } from '../taxChecklist.selectors'
import {
  FormikDropdown,
  GridRowColumn,
  Popup,
  Text,
  getFieldName,
  makeStringArraySchema,
  FormikScrollOnError,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { NeedHelpTip } from '../NeedHelpTip'
import { skipQuestionId, useSkipQuestion } from '../Shared/SkipQuestion'
import UploadDocumentSubSection from '../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { TQYesNoResponse } from '../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../helpers'

export const updateDetailsQuestionsIds = [
  TaxListQuestionId.multi_state_business_states,
  TaxListQuestionId.shareholder_info_changed,
]

const UpdateDetailsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1120sProps) => {
  const { formId } = useParams()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const skippedQuestions = useSkipQuestion([
    TaxListQuestionId.shareholder_info_changed,
  ])

  const multiStateBusinessStatesQR = useTaxListQuestionRes({
    formId: Number(formId),
    questionId: TaxListQuestionId.multi_state_business_states,
  })

  const shareHolderInfoChangedQR = useTaxListQuestionRes({
    formId: Number(formId),
    questionId: TaxListQuestionId.shareholder_info_changed,
  })

  // Derived TF responses
  const responseNumberStatesConductedBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_states_conducted_business,
    Number(formId)
  )

  const dropdownOptions = multiStateBusinessStatesQR.options
    ?.map((o) => ({
      text: o.text,
      value: o.id,
      key: o.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text))

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.multi_state_business_states]:
        multiStateBusinessStatesQR.initialResponse,
      [TaxListQuestionId.shareholder_info_changed]:
        shareHolderInfoChangedQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseUpdates = [
        multiStateBusinessStatesQR.getResponseData(values),
        shareHolderInfoChangedQR.getResponseData(values),
      ]

      if (
        Array.isArray(values[TaxListQuestionId.multi_state_business_states]) &&
        values[TaxListQuestionId.multi_state_business_states].length !==
          responseNumberStatesConductedBiz?.[0]?.value
      ) {
        responseUpdates.push({
          id: responseNumberStatesConductedBiz?.[0]?.id,
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_states_conducted_business,
          value: values[TaxListQuestionId.multi_state_business_states].length,
          needsAssistance: null,
        })
      }
      await goToNextStep(responseUpdates, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.checkDetails1120s],
      })
    },
  })

  const { values, isSubmitting, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <NeedHelpTip />
        </GridRowColumn>
        <Grid.Row />
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Update Details for {taxYear}
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Please update the following information for {taxYear}. These answers
            will appear on your tax return.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikDropdown
            search
            name={getFieldName<typeof values>(
              TaxListQuestionId.multi_state_business_states
            )}
            label={multiStateBusinessStatesQR.label}
            options={dropdownOptions}
            schema={makeStringArraySchema()}
            multiple
            placeholder="Select all that apply"
            clearable
            fullWidth
            afterLabel={
              <Popup
                content={
                  <Grid>
                    <GridRowColumn>
                      <Text as="h3">Registering business in another state</Text>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Text>
                        Select all states in which you’ve seen clients.
                      </Text>
                    </GridRowColumn>
                  </Grid>
                }
              />
            }
          />
        </GridRowColumn>

        <TQYesNoResponse
          questionResponse={shareHolderInfoChangedQR}
          skippable
        />

        <GridRowColumn {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={[
            values[TaxListQuestionId.shareholder_info_changed]
              ? UserDocumentCategoryIdentifier.businessIncorporation
              : undefined,
          ]}
        />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default UpdateDetailsPanel
