import { useCallback } from 'react'
import { Document } from './types'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import { IsMarkedNotRelevantFunction } from './not-relevant-documents'

export const useDocuments = ({
  stepContextDocuments = [],
  userDocuments = [],
}: {
  stepContextDocuments?: Document[]
  userDocuments: UserDocument[]
}) => {
  const filterDocuments = useCallback(
    (categoryInternalName: string, provider: string, documentName?: string) => {
      const uploadedIds =
        stepContextDocuments
          ?.filter(
            (doc) =>
              doc.categoryCode === categoryInternalName &&
              doc.provider === provider &&
              doc.name === documentName &&
              !doc.notRelevant
          )
          .map((doc) => doc.documentId) ?? []
      return userDocuments.filter((doc) => uploadedIds.includes(doc.id))
    },
    [stepContextDocuments, userDocuments]
  )

  const getDocument = useCallback(
    (categoryInternalName: string, provider: string, documentName?: string) => {
      const uploadedDoc = stepContextDocuments?.find(
        (doc) =>
          doc.categoryCode === categoryInternalName &&
          doc.provider === provider &&
          doc.name === documentName
      )
      return (
        uploadedDoc ?? {
          categoryCode: categoryInternalName,
          provider,
          name: documentName,
        }
      )
    },
    [stepContextDocuments]
  )

  const getStatus = useCallback(
    (
      categoryInternalName: string,
      provider: string,
      isMarkedNotRelevant?: IsMarkedNotRelevantFunction,
      documentName?: string
    ) => {
      const hasDocs =
        filterDocuments(categoryInternalName, provider, documentName).length > 0
      const notRelevant = isMarkedNotRelevant?.(
        categoryInternalName,
        provider,
        documentName
      )
      return hasDocs || notRelevant ? 'success' : 'none'
    },
    [filterDocuments]
  )

  return {
    filterDocuments,
    getStatus,
    getDocument,
  }
}
