import axios from 'axios'
import { fetchWrapper } from '../../reducers/fetch'
import { UserCoupon, UserCouponProductName } from './userCoupons.slice'

export interface CouponType {
  id: string
  name: string
  amountOff: number | null
  percentOff: number | null
  metadata: Record<string, string> | null
}

export interface TaxConsultationCouponType extends CouponType {
  metadata: {
    durationInMinutes: string
    couponType: 'tax_advisory'
  }
}

export const FETCH_ADMIN_USER_COUPONS_KEY = 'FETCH_ADMIN_USER_COUPONS_KEY'
export const fetchAdminUserCoupons = (
  userId: number | string,
  params: {
    includeRedeemed?: boolean
    productName?: UserCouponProductName
  }
) =>
  fetchWrapper({
    fetchKey: FETCH_ADMIN_USER_COUPONS_KEY,
    fetchFunction: async () => {
      const res = await axios.get<UserCoupon[]>(
        `/finances/api/v2/admin/user_coupons/${userId}`,
        {
          params,
        }
      )
      return res.data
    },
  })

export const ADMIN_ADD_COUPON_KEY = 'ADMIN_ADD_COUPON_KEY'
export const adminAddCoupon = ({
  userId,
  couponId,
  productName,
}: {
  userId: number | string
  couponId: string
  productName: UserCouponProductName
}) =>
  fetchWrapper({
    fetchKey: ADMIN_ADD_COUPON_KEY,
    fetchFunction: async () => {
      const res = await axios.post<UserCoupon>(
        `/finances/api/v2/admin/user_coupons/${userId}/add`,
        {
          couponId,
          productName,
        }
      )
      return res.data
    },
  })

export const ADMIN_REMOVE_COUPON_KEY = 'ADMIN_REMOVE_COUPON_KEY'
export const adminRemoveCoupon = ({
  userId,
  userCouponId,
}: {
  userId: number | string
  userCouponId: number
}) =>
  fetchWrapper({
    fetchKey: ADMIN_REMOVE_COUPON_KEY,
    fetchFunction: async () => {
      const res = await axios.delete<{ deletedId: number }>(
        `/finances/api/v2/admin/user_coupons/${userId}/remove/${userCouponId}`
      )
      return res.data
    },
  })

export const FETCH_USER_COUPON_TYPES_KEY = 'FETCH_USER_COUPON_TYPES_KEY'
export const fetchUserCouponTypes = () =>
  fetchWrapper({
    fetchKey: FETCH_USER_COUPON_TYPES_KEY,
    fetchFunction: async () => {
      const res = await axios.get<{
        taxConsultationCoupons: CouponType[]
      }>('/finances/api/v2/admin/user_coupons/types')
      return res.data
    },
  })
