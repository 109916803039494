import axios from 'axios'
import { fetchWrapper } from '../../../reducers/fetch'

enum TaxServiceRequestType {
  consultation = 'CONSULTATION',
}

export interface TaxServiceRequest {
  id: number
  userId: number
  requestType: TaxServiceRequestType
  externalRequestId: string | null
  status: string
  taxfyleJobId: string
  paidAt: string | null
  invoiceId: string | null
  userCouponId: number | null
  userRequestedAt: string | null
  csatSubmittedAt: string | null
  createdAt: string
  updatedAt: string
}

export enum TaxConsultationServiceRequestStatus {
  paymentPending = 'PAYMENT_PENDING',
  paymentFailed = 'PAYMENT_FAILED',
  paymentSucceeded = 'PAYMENT_SUCCEEDED',
  approvalPending = 'APPROVAL_PENDING',
  approvalReceived = 'APPROVAL_RECEIVED',
  missed = 'MISSED',
  completed = 'COMPLETED',
}

export const inProgressConsultationStatuses = [
  TaxConsultationServiceRequestStatus.paymentSucceeded,
  TaxConsultationServiceRequestStatus.approvalPending,
  TaxConsultationServiceRequestStatus.approvalReceived,
]

export interface TaxConsultationRequest extends TaxServiceRequest {
  /** this is the TaxServiceRequest id */
  serviceRequestId: number
  status: TaxConsultationServiceRequestStatus
  durationInMinutes: 30 | 60
  taxfyleCallId: number | null
}

export const FETCH_TAX_CONSULTATIONS_KEY = 'FETCH_TAX_CONSULTATIONS_KEY'
export const fetchTaxConsultationRequests = () =>
  fetchWrapper({
    fetchKey: FETCH_TAX_CONSULTATIONS_KEY,
    fetchFunction: async () => {
      const json = await axios.get<TaxConsultationRequest[]>(
        '/finances/api/v1/tax_consultations/request/list'
      )
      return json.data
    },
  })

export const FETCH_TAX_CONSULTATION_KEY = 'FETCH_TAX_CONSULTATION_KEY'
export const fetchTaxConsultationRequest = (id: number | string) =>
  fetchWrapper({
    fetchKey: FETCH_TAX_CONSULTATION_KEY,
    fetchFunction: async () => {
      const json = await axios.get<TaxConsultationRequest>(
        `/finances/api/v1/tax_consultations/request/${id}`
      )
      return json.data
    },
  })

export const FETCH_TAX_CONSULTATION_REQUEST_CSAT_KEY =
  'FETCH_TAX_CONSULTATION_REQUEST_CSAT_KEY'
export const submitTaxConsultationRequestCSAT = (id: number | string) =>
  fetchWrapper({
    fetchKey: FETCH_TAX_CONSULTATION_REQUEST_CSAT_KEY,
    fetchFunction: async () => {
      const json = await axios.put<TaxConsultationRequest>(
        `/finances/api/v1/tax_consultations/request/${id}/csat`
      )
      return json.data
    },
  })
