import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const GettingStartedOnboardingStep = {
  joinGroupOnboardingSession: 'JOIN_GROUP_ONBOARDING_SESSION',
  uploadBankStatements: 'UPLOAD_BANK_STATEMENTS',
  setupAutomaticStatementUpload: 'SETUP_AUTOMATIC_STATEMENT_UPLOAD',
  clarifyUncategorizedTransactions: 'CLARIFY_UNCATEGORIZED_TRANSACTIONS',
  connectBusinessBankAccount: 'CONNECT_A_BANK_ACCOUNT',
  yearEndWrapUpSurvey: 'YEAR_END_WRAP_UP_SURVEY',
  complete_1099_survey: 'COMPLETE_1099_SURVEY',
  payrollInterest: 'SET_UP_PAYROLL',
  // Not yet implemented
  // confirmTaxInformation: 'CONFIRM_TAX_INFORMATION',
  // completeQteSurvey: 'COMPLETE_QTE_SURVEY',
} as const
export type GettingStartedOnboardingStepId =
  (typeof GettingStartedOnboardingStep)[keyof typeof GettingStartedOnboardingStep]

export const BookkeepingOnboardingStep = {
  learnHowBookkeepingWorks: 'LEARN_HOW_BOOKKEEPING_WORKS',
  bookkeepingAtHeard: 'BOOKKEEPING_AT_HEARD',
  howToClarifyTransactions: 'HOW_TO_CLARIFY_TRANSACTIONS',
  clarifyTransactionsExample: 'CLARIFY_TRANSACTIONS_EXAMPLE',
  transactionsClarifiedSuccess: 'TRANSACTIONS_CLARIFIED_SUCCESS',
} as const
export type BookkeepingOnboardingStepId =
  (typeof BookkeepingOnboardingStep)[keyof typeof BookkeepingOnboardingStep]

export const TaxesOnboardingStep = {
  learnAboutTaxesAtHeard: 'LEARN_ABOUT_TAXES_AT_HEARD',
  taxesTimeline: 'TAXES_TIMELINE',
  annualTaxes: 'ANNUAL_TAXES',
  quarterlyTaxes: 'QUARTERLY_TAXES',
  taxesSuccess: 'TAXES_SUCCESS',
} as const
export type TaxesOnboardingStepId =
  (typeof TaxesOnboardingStep)[keyof typeof TaxesOnboardingStep]

export enum OnboardingStepPhase {
  gettingStarted = 'GETTING_STARTED',
  ahaMoment = 'AHA_MOMENT',
  ahaMomentBookkeeping = 'AHA_MOMENT_BOOKKEEPING',
  ahaReadyForTaxes = 'AHA_MOMENT_TAXES',
}

export enum GettingStartedGroup {
  taxes = 'taxes',
  bookkeeping = 'bookkeeping',
  payroll = 'payroll',
  getHelp = 'getHelp',
  accountSetup = 'accountSetup',
}

interface UserOnboardingStepBase {
  id: number
  userId: number
  createdAt: string
  updatedAt: string
  onboardingStepId: OnboardingStepId
  completedAt?: string | null
  startedAt?: string | null
  skippedAt?: string | null
}

export type OnboardingStepId =
  | GettingStartedOnboardingStepId
  | BookkeepingOnboardingStepId
  | TaxesOnboardingStepId

export interface OnboardingStep {
  identifier: OnboardingStepId
  phase: OnboardingStepPhase
  createdAt: string
  updatedAt: string
}

export interface UserOnboardingStepExpanded extends UserOnboardingStepBase {
  onboardingStep: OnboardingStep
}

export type UserOnboardingStep =
  | UserOnboardingStepBase
  | UserOnboardingStepExpanded

export type GettingStartedUserOnboardingStepExpanded = Omit<
  UserOnboardingStepExpanded,
  'onboardingStep'
> & {
  onboardingStep: {
    identifier: GettingStartedOnboardingStepId
    phase: OnboardingStepPhase
    createdAt: string
    updatedAt: string
  }
}
export const isUserOnboardingStepExpanded = (
  step: UserOnboardingStepBase | UserOnboardingStepExpanded
): step is UserOnboardingStepExpanded => {
  return 'onboardingStep' in step
}

export const isGettingStartedUserOnboardingStepExpanded = (
  step: UserOnboardingStepExpanded
): step is GettingStartedUserOnboardingStepExpanded => {
  return Object.values(GettingStartedOnboardingStep).includes(
    step.onboardingStep.identifier as GettingStartedOnboardingStepId
  )
}

export interface UserOnboardingStepState {
  byId: {
    [key: number]: UserOnboardingStepBase
  }
  allOnboardingSteps: {
    [key: string]: OnboardingStep
  }
}

const initialState: UserOnboardingStepState = {
  byId: {},
  allOnboardingSteps: {},
}

const onboardingStepsSlice = createSlice({
  name: 'userOnboardingSteps',
  initialState,
  reducers: {
    receiveAllOnboardingSteps: (
      state,
      action: PayloadAction<OnboardingStep[]>
    ) => {
      state.allOnboardingSteps = action.payload.reduce(
        (acc, step) => {
          acc[step.identifier] = step
          return acc
        },
        {} as { [key: string]: OnboardingStep }
      )
    },
    receiveUpdatedUserOnboardingStep: (
      state,
      action: PayloadAction<UserOnboardingStepBase>
    ) => {
      state.byId[action.payload.id] = {
        ...state.byId[action.payload.id],
        ...action.payload,
      }
    },
    receiveUserOnboardingSteps: (
      state,
      action: PayloadAction<UserOnboardingStep[]>
    ) => {
      action.payload.forEach((step) => {
        state.byId[step.id] = step
        if (isUserOnboardingStepExpanded(step)) {
          state.allOnboardingSteps[step.onboardingStep.identifier] =
            step.onboardingStep
        }
      })
    },
  },
})

export default onboardingStepsSlice.reducer

export const {
  receiveAllOnboardingSteps,
  receiveUpdatedUserOnboardingStep,
  receiveUserOnboardingSteps,
} = onboardingStepsSlice.actions
