import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import PayrollInterestIntro from './PayrollInterestIntro'
import PayrollInterestOutro from './PayrollInterestOutro'
import PayrollInterestForm from './PayrollInterestForm'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../components/FormFlow/formFlow'

export enum PayrollInterestScreenName {
  INTRO = 'intro',
  FORM = 'form',
  OUTRO = 'outro',
}

const PayrollInterestFlow = () => {
  const { state } = useLocation()

  const { setScreen, currentScreen } = useSetScreen({
    // If the user is coming from a different page (like payroll/welcome) navigate them back to that page
    backLink: state?.from?.pathname || '/',
  })

  useEffect(() => {
    if (!currentScreen) {
      setScreen(PayrollInterestScreenName.INTRO, true)
    }
  }, [setScreen, currentScreen])

  const screenConfig = useMemo(
    () => [
      {
        component: () => <PayrollInterestIntro setScreen={setScreen} />,
        screenName: PayrollInterestScreenName.INTRO,
        step: 0,
      },
      {
        component: () => <PayrollInterestForm setScreen={setScreen} />,
        screenName: PayrollInterestScreenName.FORM,
        step: 0,
      },
      {
        component: () => <PayrollInterestOutro />,
        screenName: PayrollInterestScreenName.OUTRO,
        step: 0,
      },
    ],
    [setScreen]
  )

  const { content } = useFormFlow({
    steps: [],
    screens: screenConfig,
  })

  return content
}

export default PayrollInterestFlow
