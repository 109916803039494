import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { fetchWrapper } from '../../reducers/fetch'
import axios from 'axios'

export enum UserCouponProductName {
  taxAdvisory = 'tax_advisory',
}

interface TaxAdvisoryCouponMetadata {
  durationInMinutes: string
  couponType: 'tax_advisory'
}

export interface UserCoupon {
  id: number
  userId: number
  productName: UserCouponProductName
  redeemedAt: string | null
  amountOff: number | null
  percentOff: number | null
  metadata: TaxAdvisoryCouponMetadata | null
}

interface UserCouponState {
  taxAdvisory: { [key: string]: UserCoupon }
}

const userCouponsSlice = createSlice({
  name: 'userCoupons',
  initialState: {
    taxAdvisory: {},
  } as UserCouponState,
  reducers: {
    receiveUserCoupons: (state, action: PayloadAction<UserCoupon[]>) => {
      state.taxAdvisory = keyBy(
        action.payload.filter(
          (coupon) => coupon.productName === UserCouponProductName.taxAdvisory
        ),
        'id'
      )
    },
  },
})

export default userCouponsSlice.reducer

const { receiveUserCoupons } = userCouponsSlice.actions

export const FETCH_USER_COUPONS_KEY = 'FETCH_USER_COUPONS_KEY'
export const fetchUserCoupons = ({
  includeRedeemed = false,
  productName,
}: {
  includeRedeemed?: boolean
  productName?: UserCouponProductName
}) =>
  fetchWrapper({
    fetchKey: FETCH_USER_COUPONS_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.get<UserCoupon[]>(
        '/finances/api/v2/user_coupons',
        {
          params: {
            includeRedeemed,
            productName,
          },
        }
      )
      dispatch(receiveUserCoupons(res.data))
      return res.data
    },
  })
