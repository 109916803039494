import { useEffect, useMemo } from 'react'
import { fetchUserYearEndModuleStatuses } from '../../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import {
  useLoadedFlagValue,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  Accordion,
  Alert,
  GridRowColumn,
  Text,
  Button,
  Icon,
} from '../../../../../components/BaseComponents'
import { Grid, Image } from 'semantic-ui-react'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import YearEndModuleProgressList from '../../../../YearEndModuleStatus/components/YearEndModuleProgressList'
import {
  getMostUrgentYearEndModuleType,
  selectAllYearEndModulesComplete,
  selectOrderedCompletedYEModules,
  selectOrderedIncompleteYEModules,
} from '../../../../YearEndModuleStatus/yearEndModuleStatus.selectors'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { useYearEndNextStepPill } from '../../../../YearEndModuleStatus/components/EOYNextStepPill'
import { getAnnualTaxFilingForYearSelector } from '../../annualTaxFilings.selector'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../../annualTaxFilingForms.slice'
import {
  fetchAnnualTaxFilingsIfNeeded,
  needContractorFilingOption,
} from '../../annualTaxFilings.slice'
import {
  OptIntoTaxesWithHeard,
  OptOutOfTaxesWithHeard,
  OptInto1099NEC,
  OptOutOf1099NEC,
  OptIntoExtension,
  FAQsSection,
  GetTaxAdvice,
} from './RightSidebarItems'
import Ten99FilingSection from './Ten99FilingSection'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../../OpenFeature'
import {
  selectHasSubmitted1099sForYear,
  selectPayerHasAboundTaxDocsForYear,
} from '../../aboundAnnualTaxFilings.selector'
import { selectUserShouldSeeOnboardingDashboard } from '../../../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { Colors } from '../../../../../styles/theme'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import OneFormExtensionNotice from './OneFormExtensionNotice'
import TaxConsultationCSATSurvey from '../../../Advisory/TaxConsultationSurvey'
import CompletedAllModulesCard from '../CompletedAllModulesCard'

const getHeaderContent = ({
  taxYear,
  optedOutOfTaxesWithHeard,
  completedAllModules,
}: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
  completedAllModules: boolean
}) => {
  if (optedOutOfTaxesWithHeard && completedAllModules) {
    return {
      title: `${taxYear} Year-End Bookkeeping`,
      description: `You are all set with your ${taxYear} bookkeeping.`,
      image:
        'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.svg',
    }
  }

  if (!optedOutOfTaxesWithHeard && completedAllModules) {
    return {
      title: 'Annual Taxes',
      description: `You finished your taxes for ${taxYear}! See below for a copy of your returns.`,
      image: 'https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg',
    }
  }

  if (!optedOutOfTaxesWithHeard && !completedAllModules) {
    return {
      title: 'Annual Taxes',
      description:
        'We’ll walk you through getting your books and documents in order for annual taxes. After we have everything we need, we’ll connect you to a tax preparer.',
      image: 'https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg',
    }
  }

  if (optedOutOfTaxesWithHeard && !completedAllModules) {
    return {
      title: `${taxYear} Year-End Bookkeeping`,
      description:
        'We’ll walk you through getting your books in order for the year. This will set you up for success when you file your annual taxes.',
      image:
        'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.svg',
    }
  }

  return {
    title: 'Year-End Bookkeeping',
    description:
      'We’ll walk you through getting your books in order for the year. This will set you up for success when you file your annual taxes.',
    image: 'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.svg',
  }
}

const Header = ({
  taxYear,
  optedOutOfTaxesWithHeard,
  completedAllModules,
}: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
  completedAllModules: boolean
}) => {
  const nextStepPill = useYearEndNextStepPill()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const content = getHeaderContent({
    taxYear,
    optedOutOfTaxesWithHeard,
    completedAllModules,
  })

  return (
    <Grid.Row>
      <Grid.Column
        width={13}
        style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      >
        <Text as="h1" style={{ marginBottom: 8 }}>
          {content.title}
        </Text>

        {!isMobile && (
          <Text as="bodySm" style={{ marginBottom: 24 }}>
            {content.description}
          </Text>
        )}
        {!completedAllModules && (
          <div style={{ display: 'inline-block' }}>{nextStepPill}</div>
        )}
      </Grid.Column>
      {!isMobile && (
        <Grid.Column width={3} textAlign="right">
          <Image src={content.image} style={{ width: 180, height: 180 }} />
        </Grid.Column>
      )}
    </Grid.Row>
  )
}

const OptionsSection = ({
  taxYear,
  optedOutOfTaxesWithHeard,
  optedOutOf1099NEC,
}: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
  optedOutOf1099NEC: boolean
}) => {
  const optionsTitle = <Text as="h2">Options</Text>
  const titleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  }

  const optInFlagOn = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable2024OptOutFlow,
    false
  )

  const enable1099nec = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable1099nec,
    false
  )

  const hasTen99FilingsSubmitted = useReselector(
    selectPayerHasAboundTaxDocsForYear,
    taxYear
  )
  const extensionRequestOptInEnabled = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.extensionRequestOptInEnabled,
    false
  )

  const enableTaxConsultations = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableTaxConsultations,
    false
  )

  const optionsContent = useMemo(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {extensionRequestOptInEnabled && <OptIntoExtension taxYear={taxYear} />}
        {enableTaxConsultations && <GetTaxAdvice />}
        {optInFlagOn &&
          (optedOutOfTaxesWithHeard ? (
            <OptIntoTaxesWithHeard taxYear={taxYear} />
          ) : (
            <OptOutOfTaxesWithHeard />
          ))}
        {enable1099nec &&
          !hasTen99FilingsSubmitted &&
          (optedOutOf1099NEC ? (
            <OptInto1099NEC taxYear={taxYear} />
          ) : (
            <OptOutOf1099NEC taxYear={taxYear} />
          ))}
      </div>
    )
  }, [
    extensionRequestOptInEnabled,
    taxYear,
    optInFlagOn,
    optedOutOfTaxesWithHeard,
    enable1099nec,
    optedOutOf1099NEC,
    hasTen99FilingsSubmitted,
    enableTaxConsultations,
  ])

  return (
    <Accordion
      title={optionsTitle}
      titleStyle={titleStyle}
      content={optionsContent}
      variant="text"
      iconColor="black"
      initialOpen
    />
  )
}

const RightSideContent = (props: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
  optedOutOf1099NEC: boolean
  completedAllModules: boolean
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
      <OneFormExtensionNotice />
      <FAQsSection />
      {!props.optedOutOf1099NEC && <Ten99FilingSection />}
      {!props.completedAllModules && <OptionsSection {...props} />}
    </div>
  )
}

const OnboardingDismissibleAlert = () => {
  const storageKey = 'yearEndHomeOnboardingAlert'
  const navigate = useNavigate()
  const isOnboarding = useReselector(selectUserShouldSeeOnboardingDashboard)

  if (!isOnboarding) {
    return null
  }

  return (
    <GridRowColumn
      columnStyle={{
        display: 'flex',
        gap: 40,
      }}
    >
      <Alert
        style={{ backgroundColor: Colors.sunrise, width: '100%' }}
        useCustomContent
        closeable
        permanentDismissalKey={storageKey}
      >
        <div style={{ display: 'flex', gap: 32 }}>
          <Image
            size="small"
            src="https://heard-images.s3.amazonaws.com/assets/ledger-and-coffee.svg"
          />
          <div style={{ display: 'flex', gap: 12, flexDirection: 'column' }}>
            <Text as="h2">Don’t forget to complete your onboarding!</Text>
            <Text as="bodySm">
              Complete all required tasks under <b>Get Started with Heard</b> on
              your dashboard to start on your annual taxes.
            </Text>
            <Button
              style={{ width: 'fit-content' }}
              onClick={() => navigate('/onboarding-dashboard')}
            >
              Complete Onboarding
              <Icon icon={light('arrow-right')} style={{ marginLeft: 6 }} />
            </Button>
          </div>
        </div>
      </Alert>
    </GridRowColumn>
  )
}

const YearEndHome = () => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const completedYEModules = useReselector(selectOrderedCompletedYEModules)
  const incompleteModules = useReselector(selectOrderedIncompleteYEModules)
  const mostUrgentModuleType = useReselector(getMostUrgentYearEndModuleType)
  const completedAllModules = useReselector(selectAllYearEndModulesComplete)
  const hasSubmitted1099NEC = useReselector(
    selectHasSubmitted1099sForYear,
    taxYear
  )
  const {
    optedOutAt,
    needContractorFiling,
    extensionReason,
    annualTaxFormNeeds,
  } = useReselector(getAnnualTaxFilingForYearSelector, taxYear) || {}
  const needs1099NEC = needContractorFiling === needContractorFilingOption.yes

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const gridStyle = isMobile
    ? { padding: '0px 16px' }
    : { padding: '0px 20px 0px 0px' }
  useEffect(() => {
    dispatch(fetchUserYearEndModuleStatuses())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
  }, [dispatch, taxYear])

  return (
    <Grid style={gridStyle}>
      <OnboardingDismissibleAlert />
      <Header
        taxYear={taxYear}
        optedOutOfTaxesWithHeard={Boolean(optedOutAt)}
        completedAllModules={completedAllModules}
      />
      <Grid.Row>
        <Grid.Column {...makeGridConfig([11, 16])}>
          {completedAllModules && (
            <Grid.Row>
              <CompletedAllModulesCard
                taxYear={taxYear}
                optedOutOfTaxesWithHeard={Boolean(optedOutAt)}
              />
            </Grid.Row>
          )}
          <YearEndModuleProgressList
            completeModules={completedYEModules}
            incompleteModules={incompleteModules}
            taxYear={taxYear}
            mostUrgentModule={mostUrgentModuleType}
            annualTaxFormNeeds={annualTaxFormNeeds}
            extensionReason={extensionReason}
            hasSubmitted1099NEC={hasSubmitted1099NEC}
          />
        </Grid.Column>
        <Grid.Column {...makeGridConfig([5, 16])}>
          <RightSideContent
            taxYear={taxYear}
            optedOutOfTaxesWithHeard={Boolean(optedOutAt)}
            optedOutOf1099NEC={!needs1099NEC}
            completedAllModules={completedAllModules}
          />
        </Grid.Column>
      </Grid.Row>
      <TaxConsultationCSATSurvey />
    </Grid>
  )
}

export default YearEndHome
