import { useNavigate } from 'react-router-dom'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectIsBasicPlan } from '../../../../reducers/subscription.slice'
import {
  getCurrentUser,
  getFreeTrialStatus,
} from '../../../../selectors/user.selectors'
import { TrialStatus } from '../../../../reducers/auth/userReducer'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { Alert, Button, Text } from '../../../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'

export const useTSKReminderBanner = () => {
  const isBasicPlan = useReselector(selectIsBasicPlan)
  const trialStatus = useReselector(getFreeTrialStatus)
  const hasActiveTrial = trialStatus === TrialStatus.active
  const isAdminUser = useReselector(getCurrentUser)?.admin
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const {
    pretaxSurveySubmittedAt,
    optedOutAt,
    isLateJoiner,
    lateJoinerInitiallyOptedIn,
  } = annualTaxFiling || {}

  // Never show the banner if the user has submitted the pretax survey
  if (pretaxSurveySubmittedAt) {
    return false
  }

  return !isBasicPlan && !hasActiveTrial && !isAdminUser && !isLateJoiner
    ? !optedOutAt
    : lateJoinerInitiallyOptedIn
}

const TSKReminderBanner = () => {
  const navigate = useNavigate()
  const shouldShowBanner = useTSKReminderBanner()

  if (!shouldShowBanner) {
    return null
  }

  return (
    <Alert type="warn">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text>
              Don&apos;t forget! Tell us your tax needs as soon as possible.
              Until we hear from you, we&apos;ll assume you do not need our help
              to file your taxes. If you do need our help, our process will have
              you file an extension to give you ample time.
            </Text>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              variant="secondaryLink"
              onClick={() => navigate('/taxes/annual')}
            >
              Complete Tax Season Kickoff
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Alert>
  )
}

export default TSKReminderBanner
