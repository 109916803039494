import { useState, useEffect, FC, useMemo } from 'react'
import {
  Card,
  Header,
  Grid,
  Button,
  Divider,
  Label,
  Loader,
  List,
} from 'semantic-ui-react'

import FinancialProfileFormModal from './FinancialProfileFormModal'
import {
  fetchHelloSignUrls,
  HelloSignResponse,
} from '../../../actions/helloSignActions'
import TaxProfileCard from './TaxProfileCard'
import PracticeEditModal from './PracticeEditModal'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { TAX_ENTITY_TYPES } from '../../../features/Taxes/taxConstants'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { DateTime } from 'luxon'
import {
  adminFetchSmsConsentStatus,
  getSmsConsentStatusByUserId,
  SmsConsentStatusOption,
} from '../../../features/SmsConsent/smsConsentReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  getIsAdministrator,
  selectIsAccountingOpsManager,
  selectIsAccountManager,
} from '../../../selectors/user.selectors'

interface PracticeInformationCardProps {
  user: UserWithAdminInfo
}

const PracticeInformationCard: FC<PracticeInformationCardProps> = ({
  user,
}) => {
  const dispatch = useAppDispatch()
  const [isPracticesEditModalOpen, setIsPracticesEditModalOpen] =
    useState(false)
  const [isFinancialAccountModalOpen, setIsFinancialAccountModalOpen] =
    useState(false)
  const [agreements, setAgreements] = useState<HelloSignResponse>()
  const [agreementsLoading, setAgreementsLoading] = useState(true)

  const profile = user.financialProfile

  const smsConsentStatus = useReselector(getSmsConsentStatusByUserId, user.id)

  const isAdministrator = useReselector(getIsAdministrator)
  const isAccountManager = useReselector(selectIsAccountManager)
  const isAccountingOpsManager = useReselector(selectIsAccountingOpsManager)
  const hasEditPermission = useMemo(
    () =>
      Boolean(isAdministrator || isAccountManager || isAccountingOpsManager),
    [isAdministrator, isAccountManager, isAccountingOpsManager]
  )

  useEffect(() => {
    const fetchAgreements = async () => {
      const agreements = await fetchHelloSignUrls()(dispatch)
      setAgreements(agreements)
      setAgreementsLoading(false)
    }

    fetchAgreements()
  }, [dispatch, user.id])

  useEffect(() => {
    if (user.id) {
      dispatch(adminFetchSmsConsentStatus(user.id))
    }
  }, [dispatch, user.id])

  const handleOpenPracticesEditModal = () => {
    setIsPracticesEditModalOpen(true)
  }

  const renderAgreements = () => {
    if (
      agreementsLoading ||
      !agreements ||
      !agreements.baa_url ||
      !('signed' in agreements.baa_url)
    ) {
      return (
        <Grid.Column width={16}>
          <Loader inline active />
        </Grid.Column>
      )
    }
    return (
      <Grid.Column width={16}>
        {agreements.baa_url?.signed && (
          <span>
            <b>BAA Agreement </b>{' '}
            <Label color="green" horizontal>
              Signed
            </Label>
            <p>
              HelloSign Link —{' '}
              <a
                href={agreements.baa_url?.detailsUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                View in HelloSign
              </a>
            </p>
            <p>
              <a
                href={agreements.baa_url?.fileUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download agreement
              </a>
            </p>
          </span>
        )}
        {!agreements.baa_url?.signed && (
          <span>
            <b>BAA Agreement: </b>{' '}
            <Label color="red" horizontal>
              Unsigned
            </Label>
            <p>
              HelloSign Link —{' '}
              <a
                href={agreements.baa_url?.detailsUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                View in HelloSign
              </a>
            </p>
          </span>
        )}
      </Grid.Column>
    )
  }
  if (!profile) {
    return null
  }

  return (
    <>
      <Card fluid className="practiceInformationCard">
        <Card.Header>
          <div className="flexbox">
            <Header as="h6">Practice Information</Header>
            <Button
              className="link"
              onClick={handleOpenPracticesEditModal}
              disabled={!hasEditPermission}
            >
              Edit
            </Button>
          </div>
        </Card.Header>
        <Card.Content>
          <div style={{ padding: '1em' }}>
            <List relaxed="very" divided verticalAlign="middle">
              <List.Item>
                <List.Content floated="right">{user.email}</List.Content>
                <List.Header>Email</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.businessName}
                </List.Content>
                <List.Header>Practice Name</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.practiceType}
                </List.Content>
                <List.Header>Practice Type</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.businessAddress}
                </List.Content>
                <List.Header>Business Address</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.practiceType === 'solo'
                    ? 1
                    : profile.practiceSize
                      ? profile.practiceSize
                      : 0}
                </List.Content>
                <List.Header># of Providers</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.yearPracticeStarted}
                </List.Content>
                <List.Header>Year Practice Started</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.businessEntity}
                </List.Content>
                <List.Header>Legal Entity Type</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">{profile.einNumber}</List.Content>
                <List.Header>Business EIN Number</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.accountsSeparate ? 'Yes' : 'No'}
                </List.Content>
                <List.Header>Bank Accounts Separate</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {profile.accountsSeparate
                    ? 'n/a'
                    : profile.willingAccountsSeparate
                      ? 'Yes'
                      : 'No'}
                </List.Content>
                <List.Header>Willing to Separate Accounts</List.Header>
              </List.Item>
              {profile.taxEntityType === TAX_ENTITY_TYPES.form_1120_s && (
                <List.Item>
                  <List.Header>S Corp Effective Date</List.Header>
                  <List.Content floated="right">
                    {profile?.entityChangeElectionDate
                      ? DateTime.fromISO(profile?.entityChangeElectionDate)
                          .toUTC()
                          .startOf('day')
                          .toFormat(DATE_FORMATS_LUXON.YEAR_MONTH_DAY)
                      : 'None Added'}
                  </List.Content>
                </List.Item>
              )}
            </List>
            <Divider />
            <Grid stackable doubling>
              <Grid.Row>
                <Grid.Column width={8}>{renderAgreements()}</Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <List relaxed="very" divided verticalAlign="middle">
              <List.Item>
                <List.Content floated="right">
                  {smsConsentStatus?.status === SmsConsentStatusOption.OPT_IN
                    ? smsConsentStatus?.mobilePhoneNumber
                    : ''}
                </List.Content>
                <List.Header>SMS Mobile Number</List.Header>
              </List.Item>
              <List.Item>
                <List.Content floated="right">
                  {smsConsentStatus?.status === SmsConsentStatusOption.OPT_IN
                    ? smsConsentStatus?.updatedAt
                    : ''}
                </List.Content>
                <List.Header>SMS Opt-In Timestamp</List.Header>
              </List.Item>
            </List>
          </div>
        </Card.Content>
        <Divider />
        <Card.Content>
          <Grid stackable doubling>
            <Grid.Column width={16}>
              <div className="flexbox">
                <Header as="h6">Financial Account Info </Header>
                <Button
                  className="link"
                  onClick={() => setIsFinancialAccountModalOpen(true)}
                >
                  Edit
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column width={16}>
              <p>
                <b>Bank Account Read Access:</b>{' '}
                {String(Boolean(profile.bankAccountReadAccess))}{' '}
              </p>
              <p>
                <b>Statement Available Day (latest one):</b>{' '}
                {profile.lastStatementAvailableDay
                  ? profile.lastStatementAvailableDay
                  : 'Not provided'}
              </p>
              <p>
                <b>Prevent Send Statement Notifications:</b>{' '}
                {String(Boolean(profile.preventMonthlyStatementNotifications))}
              </p>
            </Grid.Column>
          </Grid>
        </Card.Content>

        <Divider />
        <Card.Content>
          <TaxProfileCard userId={user.id} />
        </Card.Content>
      </Card>
      <FinancialProfileFormModal
        open={isFinancialAccountModalOpen}
        close={() => setIsFinancialAccountModalOpen(false)}
        profile={profile}
      />
      <PracticeEditModal
        user={user}
        open={isPracticesEditModalOpen}
        setOpen={setIsPracticesEditModalOpen}
      />
    </>
  )
}

export default PracticeInformationCard
