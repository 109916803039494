import { useCallback, useMemo, useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import { selectPlaidItems } from '../../../selectors/financeSelectors'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { getUserActionItemByActionItemIdentifier } from '../../Dashboard/UserActionItems/userActionItems.selectors'
import { updateFinancialProfile } from '../../../actions/financialProfileActions'
import { GuidedOnboardingTaskIdents } from '../config'
import { SetupCard } from '../SetupCard'
import { ConnectBusinessAccounts } from './ConnectBusinessAccounts'
import { SetupBankAccountPreScreenCard } from './SetupBankAccountPreScreenCard'
import { markUserActionItemCompleteIfExists } from '../../Dashboard/UserActionItems/service'
import { fetchUserActionItemsIfNeeded } from '../../Dashboard/UserActionItems/userActionItems.slice'
import { useAnalyticsTrack } from '../../Amplitude'
import { SkipConnectBankAccountModal } from './SkipConnectBankAccountModal'
import { SignBaaCard } from './SignBaaCard'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const ConnectStage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const plaidItems = useReselector(selectPlaidItems)
  const track = useAnalyticsTrack()
  const user = useReselector(getCurrentUser)
  const [cantFindBank, setCantFindBank] = useState(
    user?.financialProfile?.hasIncompatibleAccounts
  )
  const [showSkipButton, setShowSkipButton] = useState(false)
  const [showSkipModal, toggleSkipModal] = useToggle(false)

  useEffect(() => {
    dispatch(fetchUserActionItemsIfNeeded())
  }, [dispatch])

  const preScreenActionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    GuidedOnboardingTaskIdents.FINANCIAL_ACCOUNT_PRESCREEN
  )
  const signBaaActionItem = useReselector(
    getUserActionItemByActionItemIdentifier,
    GuidedOnboardingTaskIdents.SIGN_BAA_AGREEMENT
  )
  const hasSignedBAA =
    signBaaActionItem?.status === 'signed' || signBaaActionItem?.completedAt

  const [hasBusinessCheckingAccount, setHasBusinessCheckingAccount] = useState<
    boolean | undefined
  >()

  useEffect(() => {
    if (
      preScreenActionItem?.status &&
      hasBusinessCheckingAccount === undefined
    ) {
      setHasBusinessCheckingAccount(
        preScreenActionItem?.status === 'true'
          ? true
          : preScreenActionItem?.status === 'false'
            ? false
            : undefined
      )
    }
  }, [preScreenActionItem, hasBusinessCheckingAccount])

  const step = useMemo(() => {
    return searchParams.get('step')
  }, [searchParams])

  const continueDisabled = useMemo(() => {
    if (step === 'prescreen') {
      return hasBusinessCheckingAccount === undefined
    } else if (step === 'link') {
      return Object.keys(plaidItems || {}).length === 0
    } else if (step === 'baa') {
      return !hasSignedBAA
    }
    return false
  }, [step, hasBusinessCheckingAccount, plaidItems, hasSignedBAA])

  const handlePrescreenSubmit = useCallback(async () => {
    track('completed bank account prescreen', {
      status: hasBusinessCheckingAccount ? 'Yes' : 'No',
    })
    await markUserActionItemCompleteIfExists(
      GuidedOnboardingTaskIdents.FINANCIAL_ACCOUNT_PRESCREEN,
      track,
      hasBusinessCheckingAccount?.toString()
    )

    navigate('/onboarding-v2?stage=connect&step=link')
  }, [navigate, hasBusinessCheckingAccount, track])

  const handleSaveIncompatibleAccounts = useCallback(async () => {
    await updateFinancialProfile(user?.financialProfile?.id, {
      hasIncompatibleAccounts: cantFindBank,
    })(dispatch)
  }, [dispatch, cantFindBank, user?.financialProfile?.id])

  const handleLinkComplete = useCallback(async () => {
    await handleSaveIncompatibleAccounts()
    track('completed connect financial accounts', {
      numberOfLinkedAccounts: Object.keys(plaidItems || {}).length,
    })
    navigate('/onboarding-v2?stage=account&step=questions&accountIdx=0')
  }, [navigate, handleSaveIncompatibleAccounts, plaidItems, track])

  const handleBaaContinue = useCallback(async () => {
    await markUserActionItemCompleteIfExists(
      GuidedOnboardingTaskIdents.SIGN_BAA_AGREEMENT,
      track
    )
    navigate('/onboarding-v2?stage=connect&step=prescreen')
  }, [navigate, track])

  const handleContinue = () => {
    const step = searchParams.get('step')

    if (step === 'baa') {
      handleBaaContinue()
      track('completed sign baa page')
      return
    }

    if (step === 'link') {
      track('completed onboarding item connect financial accounts', {
        status: 'done',
      })
      handleLinkComplete()
      return
    }

    if (step === 'prescreen') {
      handlePrescreenSubmit()
    }
  }

  const component = useMemo(() => {
    switch (step) {
      case 'link':
        return (
          <>
            <ConnectBusinessAccounts
              cantFindBank={Boolean(cantFindBank)}
              setCantFindBank={setCantFindBank}
              showSkipButton={showSkipButton}
              setShowSkipButton={setShowSkipButton}
            />
            {showSkipModal && (
              <SkipConnectBankAccountModal closeModal={toggleSkipModal} />
            )}
          </>
        )
      case 'prescreen':
        return (
          <SetupBankAccountPreScreenCard
            hasBusinessCheckingAccount={hasBusinessCheckingAccount}
            setHasBusinessCheckingAccount={setHasBusinessCheckingAccount}
          />
        )
      default:
        return <SignBaaCard signBaaActionItem={signBaaActionItem} />
    }
  }, [
    cantFindBank,
    hasBusinessCheckingAccount,
    showSkipButton,
    showSkipModal,
    step,
    toggleSkipModal,
    signBaaActionItem,
  ])

  const handleBack = async () => {
    const step = searchParams.get('step')

    if (step === 'baa') {
      navigate('/onboarding-v2')
      return
    }

    if (step === 'prescreen') {
      await markUserActionItemCompleteIfExists(
        GuidedOnboardingTaskIdents.FINANCIAL_ACCOUNT_PRESCREEN,
        undefined,
        hasBusinessCheckingAccount?.toString()
      )
      navigate('/onboarding-v2?stage=connect&step=baa')
      return
    }

    handleSaveIncompatibleAccounts()
    navigate('/onboarding-v2?stage=connect&step=prescreen')
  }

  return (
    <SetupCard
      currentStep={
        step === 'prescreen' || step === 'link' || step === 'baa' ? -1 : 0
      }
      onBackClick={handleBack}
      onContinueClick={handleContinue}
      continueDisabled={continueDisabled}
      onSkipClick={toggleSkipModal}
      showSkipButton={showSkipButton}
      isSkipForNow
    >
      {component}
    </SetupCard>
  )
}
