import { Grid, Image } from 'semantic-ui-react'
import {
  Card,
  GridRowColumn,
  Popup,
  Text,
} from '../../../../components/BaseComponents'

export const NeedHelpTip = () => (
  <Card backgroundColor="lightYellow" type="subsection">
    <Grid>
      <Grid.Column width={10}>
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow" color="darkGray">
              Tip
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">
              What if I don’t know how to answer a question or find a document?
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              Use the &apos;Tax preparer will follow-up&apos; checkbox to alert
              your tax preparer that you need guidance.
              <br />
              <br />
              If a question is required, you’ll need to answer the question
              first before you can ask for follow-up.
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Popup
              content={
                <>
                  <Text as="h3">When should I expect follow-up?</Text>
                  <br />
                  <Text>
                    After you&apos;ve completed the tax questionnaire, your tax
                    preparer will receive your completed questionnaire and
                    documents, including the questions you’ve flagged for
                    follow-up. You&apos;ll be able to connect with your tax
                    preparer through a chat portal where you can discuss your
                    concerns.
                  </Text>
                </>
              }
              trigger={
                <Text color="black" greenUnderline>
                  When can I expect follow-up?
                </Text>
              }
            />
          </GridRowColumn>
        </Grid>
      </Grid.Column>
      <Grid.Column width={6} verticalAlign="middle">
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/checklist_question.svg"
          width={180}
        />
      </Grid.Column>
    </Grid>
  </Card>
)
