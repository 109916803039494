import { useEffect } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid, Image, Divider } from 'semantic-ui-react'

import { Button, Text, Icon } from '../../../components/BaseComponents'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../helpers'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { useNavigateWithLocation } from '../../../utils/routeHelpers'
import { Colors } from '../../../styles/theme'

const WelcomeToPayroll = () => {
  const navigate = useNavigateWithLocation()
  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()

  useEffect(() => {
    pageView('payroll upsell welcome')
  }, [pageView])

  const whyAddPayroll = [
    {
      title: 'Calculate your salary',
      description:
        'If you are taxed as an S-Corp, our team can help calculate your reasonable salary.',
    },
    {
      title: 'Simplifies tax forms',
      description:
        'Easily generate and store W-2 and 1099 forms for employees and contractors come tax time.',
    },
    {
      title: 'Payroll taxes filed for you',
      description: 'We file payroll taxes for you every time you run payroll.',
    },
    {
      title: 'Hands-on support',
      description:
        'Our payroll team will support you throughout the year should you run into any issues when running payroll.',
    },
  ]

  const isPayrollForMe = [
    'You own a group practice and pay your employees or contractors',
    'You’re an S corporation and required to pay yourself a reasonable income through a W-2 salary',
  ]

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row>
        <Grid.Column
          computer={7}
          tablet={7}
          mobile={16}
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              backgroundColor: Colors.lightYellow,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/checkbooks.svg"
              style={{ width: 'auto', height: 162 }}
            />
          </div>

          <br />
          <div>
            <Text as="display" style={{ display: 'inline' }}>
              ${GEP_SUB_PRICE}
            </Text>
            <Text as="bodyLg" style={{ display: 'inline' }}>
              /month
            </Text>
          </div>

          <Text as="bodyXs">
            Plus ${GEP_PER_PERSON_PRICE} per employee / month
          </Text>
          <br />
          <Button
            onClick={() => {
              track('clicked payroll upsell welcome page button')
              navigate('/payroll/interest')
            }}
          >
            Get Started
          </Button>
          <br />
          <Text as="bodyXs" color="darkGray">
            Already paying for Gusto payroll? You’ll no longer be charged by
            Gusto and will only be charged by Heard going forward.
          </Text>
        </Grid.Column>
        <Grid.Column computer={9} tablet={9} mobile={16}>
          <Text as="h1">Welcome to Heard Payroll</Text>
          <br />
          <Text>
            Heard partners with Gusto so you can pay yourself, employees, and
            contractors in minutes. Keep your practice’s finances in one place.
          </Text>
          <br />
          <Text as="h2">Why add payroll?</Text>
          <br />
          <Grid>
            {whyAddPayroll.map((item) => (
              <Grid.Row key={item.title}>
                <Grid.Column width={1}>
                  <Icon icon={light('check')} color="green" />
                </Grid.Column>
                <Grid.Column width={15}>
                  <Text as="h3">{item.title}</Text>
                  <Text as="bodySm">{item.description}</Text>
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
          <br />
          <Divider />
          <br />
          <Text as="h2">Is payroll right for me?</Text>
          <br />
          <Grid>
            {isPayrollForMe.map((content) => (
              <Grid.Row key={content}>
                <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon icon={light('arrow-right')} color="green" />
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text>{content}</Text>
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default WelcomeToPayroll
