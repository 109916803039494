import { useBooleanFlagValue } from '@openfeature/react-sdk'

import { Alert, Link } from '../../components/BaseComponents'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { useTSKReminderBanner } from '../Taxes/AnnualTaxes/TaxSeasonKickoff/TSKReminderBanner'
import { useReselector } from '../../utils/sharedHooks'
import { selectDeadlineBannerEligibility } from '../Taxes/AnnualTaxes/annualTaxFilings.selector'

const HeardCommunityBanner = () => {
  const isShowingTskBanner = useTSKReminderBanner()
  const shouldShowDeadlineBanner = useReselector(
    selectDeadlineBannerEligibility
  )
  const shouldDisplayCommunityLink = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableHeardCommunity,
    false
  )

  if (
    !shouldDisplayCommunityLink ||
    isShowingTskBanner ||
    shouldShowDeadlineBanner
  ) {
    return null
  }

  return (
    <Alert type="announcement">
      Join the new{' '}
      <Link href="https://support.joinheard.com/hc/en-us/community/topics">
        Heard Community
      </Link>{' '}
      — connect with other practice owners to share tips, insights, and get
      support.
    </Alert>
  )
}

export default HeardCommunityBanner
