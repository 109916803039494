import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'
import {
  removeQAUser,
  setQAUsers,
  setSingleQAUser,
  updateQAUser,
} from '../../features/Admin/QA/qaUsers.slice'

import {
  BaseUser,
  EndOfYearReconciliationLog,
  FinancialProfile,
  Membership,
} from '../auth/userReducer'
import { YearEndModuleStatus } from '../../features/YearEndModuleStatus/yearEndModuleStatus.slice'
import { AdminAnnualTaxFiling } from '../../features/Admin/Taxes/adminAnnualTaxFilings.slice'

export interface UserTaxStatus {
  userId: number
  taxYear: string
  taxType: string
  status:
    | 'call_scheduled'
    | 'preparing'
    | 'needs_review'
    | 'ready_file'
    | 'filed'
    | 'accepted'
  completedAt: string
  createdAt: string
  updatedAt: string
}

export interface UserWithAdminInfo extends BaseUser {
  uncategorizedCount?: number
  pendingCount?: number
  requestedClarificationCount?: number
  userTaxStatuses: UserTaxStatus[] | null
  financialProfile?: FinancialProfile
  userEndOfYearReconciliationLogs: EndOfYearReconciliationLog[] | null
  memberships: Membership[] | null
  annualTaxFilings?: AdminAnnualTaxFiling[] | null
  eoyBookkeepingFollowupStatuses?:
    | Pick<YearEndModuleStatus, 'year' | 'status' | 'substepIdentifier'>[]
    | null
}

export interface AllUsersState {
  byId: { [key: number]: UserWithAdminInfo }
  allIds: number[]
}

const initialState: AllUsersState = {
  byId: {},
  allIds: [],
}

const allUsersSlice = createSlice({
  name: 'allUsers',
  initialState,
  reducers: {
    setAdminAllUsers: (
      state,
      action: PayloadAction<{ [key: number]: UserWithAdminInfo }>
    ) => {
      state.byId = {
        ...state.byId,
        ...action.payload,
      }

      state.allIds = uniq([
        ...state.allIds,
        ...Object.values(action.payload).map((user) => user.id),
      ])
    },
    setAdminSingleUser: (state, action: PayloadAction<UserWithAdminInfo>) => {
      state.byId[action.payload.id] = action.payload

      state.allIds = uniq([...state.allIds, action.payload.id])
    },
    updateAdminSingleUser: (
      state,
      action: PayloadAction<Partial<UserWithAdminInfo>>
    ) => {
      const userId = action.payload.id

      // Make sure the user exists before updating
      if (!userId || !state.byId[userId]) {
        return
      }

      state.byId[userId] = {
        ...state.byId[userId],
        ...action.payload,
      }
    },
    receiveUserFinancialProfile: (
      state,
      action: PayloadAction<FinancialProfile>
    ) => {
      const userId = action.payload.userId
      // Make sure the user exists before updating
      if (!userId || !state.byId[userId]) {
        return
      }

      state.byId[userId].financialProfile = action.payload
    },
    receiveAdminUserDeletion: (state, action: PayloadAction<number>) => {
      const newObjWithDeleted = Object.assign({}, state.byId)
      delete newObjWithDeleted[action.payload]
      state.byId = newObjWithDeleted

      state.allIds = state.allIds.filter((id) => id !== action.payload)
    },
    updateUserRoles: (
      state,
      action: PayloadAction<Pick<BaseUser, 'id' | 'roles'>>
    ) => {
      state.byId[action.payload.id].roles = action.payload.roles
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setQAUsers, (state, action) =>
      Object.keys(action.payload).forEach((key) => {
        const id = Number(key)
        state.byId[id] = action.payload[id]
      })
    )
    builder.addCase(setSingleQAUser, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(removeQAUser, (state, action) => {
      if (state.byId[action.payload]) {
        delete state.byId[action.payload]
      }
    })
    builder.addCase(updateQAUser, (state, action) => {
      state.byId[action.payload.id] = action.payload
    })
  },
})

export default allUsersSlice.reducer

export const {
  setAdminAllUsers,
  setAdminSingleUser,
  receiveAdminUserDeletion,
  receiveUserFinancialProfile,
  updateAdminSingleUser,
  updateUserRoles,
} = allUsersSlice.actions
