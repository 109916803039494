import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { partition } from 'lodash'

import {
  FETCH_TAX_FILINGS_KEY,
  fetchAnnualTaxFilingsIfNeeded,
} from '../annualTaxFilings.slice'
import {
  useLoadedFlagValue,
  useReselector,
  useScrollRef,
} from '../../../../utils/sharedHooks'
import {
  select1040FormForYear,
  select1120sFormForYear,
  selectFilingFormsForCurrentTaxYear,
  selectIsTwoFormFiler,
} from '../annualTaxFilingForms.selector'
import {
  getIsFetching,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import {
  Alert,
  BorderedIcon,
  Button,
  Card,
  CircularWrapper,
  Container,
  GridRowColumn,
  Link,
  Loader,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../annualTaxFilingForms.slice'
import PageHeader from '../../../../components/shared/PageHeader'
import { getUnpaidTaxEstimatesByYear } from '../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import {
  formatTaxQuarter,
  sortAndGroupEstimatesByTaxQuarter,
} from '../../QuarterlyTaxEstimates/helpers'
import {
  FETCH_TAX_ESTIMATES_KEY,
  fetchUserTaxEstimatesIfNeeded,
} from '../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import {
  StepStatus,
  fetchUserEoyReviewProgress,
  FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { fetchAllEoyReviewStepsIfNeeded } from './Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import {
  selectUserEoyReviewProgressForSubstepIdentifier,
  selectUserEoyReviewProgressStatusForChecklistSteps,
} from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import {
  CHANGES_IN_YEAR,
  CHECK_YOUR_DETAILS_1040,
  CHECK_YOUR_DETAILS_1120S,
  COMPLETE_PAYMENT,
  DEDUCTIONS_AND_CREDITS,
  ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
  NON_THERAPY_INCOME_AND_LIABILITIES,
  SubStepIdentifiers,
  UPLOAD_DOCUMENTS,
} from './Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  selectAllRequiredDocsUploaded,
  selectDocsUploadedOrSkippedAndTotalCount,
  selectRequiresPaymentForPersonalFiling,
} from './taxChecklist.selectors'
import { ADDITIONAL_1040_COST, TaxChecklistPaths } from '../constants'
import useProgressSteps from './Shared/ReviewStepsandProgresses/useProgressSteps'
import { fetchUserTaxQuestionnaire } from './taxChecklistQuestion.actions'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../UserDocuments/userDocuments.slice'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import useTaxChecklistPayment from './Form1040/payment'
import {
  selectTaxOptInEligibility,
  selectTqFilingIsReadOnly,
} from '../annualTaxFilings.selector'
import { PersonalFilingOptInReminder } from './PersonalFilingOptInReminder'
import ApproveAndSubmitModal from './ApproveAndSubmitModal'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import { fetchTaxUserDocumentsIfNeeded } from '../taxUserDocuments.slice'
import {
  TQSubstep,
  TQSubstepId,
  upsertUserYearEndModuleStatus,
  YearEndModuleType,
} from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import TaxChecklistFaq from './TaxChecklistFaq'
import TaxConsultationCTA from '../../Advisory/TaxConsultationCTA'

interface StepCardProps {
  title: ReactNode
  key: string
  content: ReactNode
  path?: TaxChecklistPaths
  disabled?: boolean
  status?: StepStatus
  action?: () => void
  loading?: boolean
  readOnly?: boolean
  formId: number
  yearEndSubstep?: TQSubstepId
  popupText?: string
}

const StepCard = ({
  title,
  content,
  path,
  disabled,
  status,
  action,
  loading,
  readOnly,
  formId,
  yearEndSubstep,
  popupText,
}: StepCardProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isFetchingProgress = useReselector(
    getIsFetching,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY
  )

  const toPage = useMemo(() => {
    const page =
      path && formId
        ? `/taxes/annual/tax_checklist/${formId}/${path}`
        : '/taxes/annual'

    return status === StepStatus.completed ? `${page}?screen=review` : page
  }, [formId, path, status])

  const setYearEndSubstepIfNeeded = useCallback(async () => {
    if (yearEndSubstep && !readOnly) {
      await upsertUserYearEndModuleStatus({
        moduleType: YearEndModuleType.taxQuestionnaire,
        substepIdentifier: yearEndSubstep,
      })(dispatch)
    }
  }, [yearEndSubstep, dispatch, readOnly])

  const onClick = useCallback(async () => {
    await setYearEndSubstepIfNeeded()
    if (action) {
      action()
    } else {
      navigate(toPage)
    }
  }, [action, navigate, toPage, setYearEndSubstepIfNeeded])

  const icon = useMemo(() => {
    switch (status) {
      case StepStatus.completed:
        return (
          <BorderedIcon
            icon={regular('check')}
            size="sm"
            height={30}
            color="green"
            wrapperColor="lightGray"
          />
        )
      case StepStatus.inProgress:
        return (
          <BorderedIcon
            icon={regular('ellipsis')}
            size="sm"
            height={30}
            wrapperColor="white"
            borderColor="gray"
          />
        )
      case StepStatus.notStarted:
        return (
          <CircularWrapper
            height={30}
            wrapperColor="white"
            borderColor="gray"
          />
        )
      case undefined:
        return null
      default:
        return status satisfies never
    }
  }, [status])

  return (
    <Card
      backgroundColor={status === StepStatus.completed ? 'stone40' : 'natural'}
      style={{ marginTop: 15 }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={1} verticalAlign="middle">
            {icon}
          </Grid.Column>
          <Grid.Column width={11}>
            <Text as="h3">{title}</Text>
            <Text style={{ marginTop: 5 }}>{content}</Text>
            {status === StepStatus.completed && !readOnly && (
              <Button onClick={setYearEndSubstepIfNeeded} variant="link">
                <Link
                  to={toPage}
                  style={{
                    marginTop: 5,
                    fontSize: 14,
                    textDecoration: 'underline',
                  }}
                >
                  Edit
                </Link>
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={4} verticalAlign="middle">
            {status !== StepStatus.completed && (
              <Popup
                disabled={!popupText}
                trigger={
                  <Button
                    fullWidth
                    onClick={onClick}
                    disabled={disabled || isFetchingProgress}
                    variant={isFetchingProgress ? 'secondary' : 'primary'}
                    loading={loading || isFetchingProgress}
                  >
                    {status === StepStatus.inProgress ? 'Continue' : 'Start'}
                  </Button>
                }
                content={<Text>{popupText}</Text>}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const CombinedChecklist = () => {
  const [approveAndSubmitOpen, setApproveAndSubmitOpen] = useState(false)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const personalForm = useReselector(select1040FormForYear, currentTaxYear)
  const businessForm = useReselector(select1120sFormForYear, currentTaxYear)
  const { personalOptInEligible } = useReselector(selectTaxOptInEligibility)
  const paymentRequired = useReselector(selectRequiresPaymentForPersonalFiling)

  const { makePayment, paymentLoading, paymentPending } =
    useTaxChecklistPayment({
      formId: personalForm?.id,
    })

  const paymentStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    COMPLETE_PAYMENT
  )

  //Missing QTE payments related
  const unpaidEstimates = useReselector(
    getUnpaidTaxEstimatesByYear,
    currentTaxYear
  )
  const qteProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.addMissingQTEPayments
  )
  const qteStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    ENTER_MISSING_QUARTERLY_TAX_PAYMENTS
  )

  const qteSecCompleted = qteStatus === StepStatus.completed

  const readOnly = useReselector(selectTqFilingIsReadOnly, currentTaxYear)

  const unpaidEstimatesByTaxQuarter = useMemo(
    () => sortAndGroupEstimatesByTaxQuarter(unpaidEstimates),
    [unpaidEstimates]
  )
  const { updateProgressData, isFetching: isFetchingProgress } =
    useProgressSteps({
      steps: [
        ...(personalForm ? [SubStepIdentifiers.addMissingQTEPayments] : []),
        ...(businessForm || personalForm ? UPLOAD_DOCUMENTS : []),
      ],
    })

  const allRequiredDocsUploaded = useReselector(
    selectAllRequiredDocsUploaded,
    currentTaxYear
  )

  const uploadDocsProgress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.uploadDocumentsCombined
  )

  const qtePaymentsCompleted = useMemo(
    () => Object.keys(unpaidEstimates).length === 0,
    [unpaidEstimates]
  )

  useEffect(() => {
    const checkAndSetQTEProgress = async () => {
      if (qteProgress?.id) {
        if (!qtePaymentsCompleted && qteProgress.completedAt) {
          await updateProgressData({
            incompleteSteps: [SubStepIdentifiers.addMissingQTEPayments],
          })
        }
      }
    }
    checkAndSetQTEProgress()
  }, [updateProgressData, qtePaymentsCompleted, qteProgress])

  useEffect(() => {
    if (!uploadDocsProgress?.id) {
      return
    }

    if (allRequiredDocsUploaded && !uploadDocsProgress.completedAt) {
      updateProgressData({
        completedSteps: [SubStepIdentifiers.uploadDocumentsCombined],
      })
    }

    if (!allRequiredDocsUploaded && uploadDocsProgress.completedAt) {
      updateProgressData({
        incompleteSteps: [SubStepIdentifiers.uploadDocumentsCombined],
      })
    }
  }, [
    allRequiredDocsUploaded,
    updateProgressData,
    uploadDocsProgress?.completedAt,
    uploadDocsProgress?.id,
  ])

  const lifeChangesStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHANGES_IN_YEAR
  )
  const deductionsAndCreditsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    DEDUCTIONS_AND_CREDITS
  )
  const incomeAndLiabilitiesStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    NON_THERAPY_INCOME_AND_LIABILITIES
  )

  const qteStepContent = useMemo(() => {
    if (qteSecCompleted) {
      return 'Thanks for updating your payments'
    }

    const unpaidQuarters = Object.keys(unpaidEstimatesByTaxQuarter)

    if (!unpaidQuarters.length) {
      return `Review and update your ${currentTaxYear} quarterly tax payments`
    }

    return (
      <>
        We are missing payments for the following quarters:
        {unpaidQuarters.map((taxQuarter) => (
          <li key={taxQuarter}>{formatTaxQuarter(taxQuarter)}</li>
        ))}
      </>
    )
  }, [currentTaxYear, qteSecCompleted, unpaidEstimatesByTaxQuarter])

  const aboutYourBusinessStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHECK_YOUR_DETAILS_1120S
  )

  const personalDetailsStatus = useReselector(
    selectUserEoyReviewProgressStatusForChecklistSteps,
    CHECK_YOUR_DETAILS_1040
  )

  const docsSectionCounts = useReselector(
    selectDocsUploadedOrSkippedAndTotalCount,
    currentTaxYear
  )

  const uploadDocumentStatus = useMemo(() => {
    if (docsSectionCounts.completeCount === docsSectionCounts.totalCount) {
      return StepStatus.completed
    } else if (docsSectionCounts.completeCount > 0) {
      return StepStatus.inProgress
    }
    return StepStatus.notStarted
  }, [docsSectionCounts.completeCount, docsSectionCounts.totalCount])

  const { incompleteItems: newChecklistItems, completedItems } = useMemo(() => {
    const checklistItems = []

    if (personalForm) {
      checklistItems.push({
        loading: isFetchingProgress,
        title: qteSecCompleted
          ? 'Review quarterly tax payments'
          : 'Enter missing quarterly tax payments',
        key: 'Enter missing quarterly tax payments',
        status: qteStatus,
        path: TaxChecklistPaths.addMissingQtePayments,
        content: qteStepContent,
        formId: personalForm.id,
        completed: qteSecCompleted,
        yearEndSubstep: TQSubstep.tqMissingQtePayments,
      })
    }

    if (businessForm) {
      checklistItems.push({
        title: 'About your business',
        key: 'About your business',
        content: readOnly
          ? 'Review your business information.'
          : 'Review and update your business information.',
        path: readOnly
          ? TaxChecklistPaths.checkYourDetailsReadOnly
          : TaxChecklistPaths.checkYourDetails,
        status: aboutYourBusinessStatus,
        formId: businessForm.id,
        completed: aboutYourBusinessStatus === StepStatus.completed,
        yearEndSubstep: TQSubstep.tqAboutYourBusiness,
      })
    }

    if (personalForm) {
      checklistItems.push(
        {
          title: 'Personal details',
          key: 'Personal details',
          content: 'Review and update your personal information.',
          path: readOnly
            ? TaxChecklistPaths.checkYourDetailsPersonalReadOnly
            : TaxChecklistPaths.checkYourDetailsPersonal,
          status: personalDetailsStatus,
          formId: personalForm.id,
          completed: personalDetailsStatus === StepStatus.completed,
          yearEndSubstep: TQSubstep.checkYourDetails,
        },
        {
          title: `Changes in ${currentTaxYear}`,
          key: `Changes in ${currentTaxYear}`,
          content: 'Fill us in on any big life changes.',
          path: readOnly
            ? TaxChecklistPaths.lifeChangesReadOnly
            : TaxChecklistPaths.lifeChanges,
          status: lifeChangesStatus,
          formId: personalForm.id,
          completed: lifeChangesStatus === StepStatus.completed,
          yearEndSubstep: TQSubstep.tqBigChanges,
        },
        {
          title: 'Deductions and credits',
          key: 'Deductions and credits',
          status: deductionsAndCreditsStatus,
          path: readOnly
            ? TaxChecklistPaths.deductionsAndCreditsReadOnly
            : TaxChecklistPaths.deductionsAndCredits,
          content:
            'Tell us about your personal expenses, and we’ll help you identify relevant deductions.',
          formId: personalForm.id,
          completed: deductionsAndCreditsStatus === StepStatus.completed,
          yearEndSubstep: TQSubstep.tqDeductionsCredits,
        },
        {
          title: (
            <>
              Non-therapy income and liabilities
              <Popup
                content={
                  <>
                    <Text as="h3">Non-therapy income and liabilities</Text>
                    <br />
                    <Text as="bodyLg">
                      This refers to any income you earn outside of your private
                      practice (i.e. investments, other businesses, bank account
                      interest) as well as any personal financial situations
                      (i.e. retirement contributions, donating to charities,
                      etc.)
                    </Text>
                  </>
                }
              />
            </>
          ),
          key: 'Non-therapy income and liabilities',
          status: incomeAndLiabilitiesStatus,
          content: 'Report income outside of your therapy practice.',
          path: readOnly
            ? TaxChecklistPaths.incomeAndLiabilitiesReadOnly
            : TaxChecklistPaths.incomeAndLiabilities,
          formId: personalForm.id,
          completed: incomeAndLiabilitiesStatus === StepStatus.completed,
          yearEndSubstep: TQSubstep.tqIncomeLiabilities,
        }
      )
    }

    const anyFormId = personalForm?.id || businessForm?.id

    if (anyFormId) {
      checklistItems.push({
        title: 'Upload documents',
        key: 'Upload documents',
        content:
          'A personalized list based upon your answers in the previous steps.',
        path: readOnly
          ? TaxChecklistPaths.documentsReadOnly
          : TaxChecklistPaths.documents,
        status: uploadDocumentStatus,
        formId: anyFormId,
        completed: uploadDocumentStatus === StepStatus.completed,
        yearEndSubstep: TQSubstep.tqUploadDocuments,
      })
    }

    if (personalForm && paymentRequired) {
      const paymentCompleted = paymentStatus === StepStatus.completed
      checklistItems.push({
        title: 'Complete payment',
        key: 'Complete payment',
        content: paymentCompleted
          ? 'Thank you for your payment.'
          : `Checkout with Stripe for the $${ADDITIONAL_1040_COST} fee of filing your personal tax return.`,
        status: paymentStatus,
        action: makePayment,
        loading: paymentLoading,
        disabled: paymentLoading || paymentCompleted || paymentPending,
        formId: personalForm.id,
        readOnly: paymentCompleted,
        completed: paymentCompleted,
      })
    }

    if (anyFormId) {
      const disabled = checklistItems.some((item) => !item.completed)
      checklistItems.push({
        title: 'Share with tax preparer',
        key: 'Share with tax preparer',
        content: 'Send answers and documents to your tax preparer.',
        status: StepStatus.notStarted,
        action: () => setApproveAndSubmitOpen(true),
        disabled,
        formId: anyFormId,
        completed: false,
        popupText: disabled
          ? 'Complete all tasks to share with tax preparer.'
          : undefined,
      })
    }

    // split into completed and incomplete
    const partitionedItems = partition(checklistItems, (item) => item.completed)
    return {
      incompleteItems: partitionedItems[1],
      completedItems: partitionedItems[0],
    }
  }, [
    businessForm,
    aboutYourBusinessStatus,
    currentTaxYear,
    deductionsAndCreditsStatus,
    incomeAndLiabilitiesStatus,
    isFetchingProgress,
    lifeChangesStatus,
    makePayment,
    paymentLoading,
    paymentPending,
    paymentStatus,
    personalForm,
    qteSecCompleted,
    qteStatus,
    qteStepContent,
    readOnly,
    uploadDocumentStatus,
    paymentRequired,
    personalDetailsStatus,
  ])

  return (
    <Container variant="noShadow">
      <Grid>
        {readOnly && (
          <GridRowColumn spacer={4} width={8}>
            <Text textAlign="center">
              You can no longer edit your responses, but you can still view
              answers for your Personal Tax Questionnaire and quarterly tax
              payments.
            </Text>
          </GridRowColumn>
        )}
        {newChecklistItems.map((item) => (
          <GridRowColumn short spacer={1} width={14} key={item.key}>
            <StepCard {...item} />
          </GridRowColumn>
        ))}
        {personalOptInEligible && newChecklistItems.length > 1 ? (
          <GridRowColumn spacer={1} width={14}>
            <PersonalFilingOptInReminder />
          </GridRowColumn>
        ) : (
          <GridRowColumn spacer={1} width={14}>
            <TaxConsultationCTA />
          </GridRowColumn>
        )}
        {completedItems.length > 0 && (
          <>
            <GridRowColumn />
            <GridRowColumn spacer={1} width={14}>
              <Text as="h2">Completed</Text>
            </GridRowColumn>
          </>
        )}
        {completedItems.map((item) => (
          <GridRowColumn short spacer={1} width={14} key={item.key}>
            <StepCard {...item} />
          </GridRowColumn>
        ))}
      </Grid>
      <ApproveAndSubmitModal
        open={approveAndSubmitOpen}
        close={() => setApproveAndSubmitOpen(false)}
      />
    </Container>
  )
}

const TaxChecklistTitle = () => {
  const enable2024TaxChecklist = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.enable2024TaxChecklist,
    false
  )
  const navigate = useNavigate()
  const forms = useReselector(selectFilingFormsForCurrentTaxYear)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, currentTaxYear)
  const businessForm = useReselector(select1120sFormForYear, currentTaxYear)

  useEffect(() => {
    if (enable2024TaxChecklist === false) {
      navigate('/taxes/annual')
    }
  }, [enable2024TaxChecklist, navigate])

  const config = useMemo(() => {
    if (isTwoFormFiler) {
      return {
        image: 'https://heard-images.s3.amazonaws.com/assets/practice.png',
        title: 'Business and Personal Tax Questionnaire',
        subtitle: 'Preparation for Form 1120-S and Form 1040',
        description: (
          <>
            In order to prepare your tax return, we need additional information
            and documents from you. This checklist takes from <b>1-2 hours.</b>
          </>
        ),
      }
    }

    if (businessForm?.id) {
      return {
        image: 'https://heard-images.s3.amazonaws.com/assets/bank-shrubs.svg',
        title: 'Business Tax Questionnaire',
        subtitle: 'Preparation for Form 1120-S',
        description: (
          <>
            In order to prepare your tax return, we need additional information
            and documents from you. This checklist takes about{' '}
            <b>30 minutes.</b>
          </>
        ),
      }
    }

    return {
      image: 'https://heard-images.s3.amazonaws.com/assets/home.svg',
      title: 'Personal Tax Questionnaire',
      subtitle: 'Preparation for Form 1040',
      description: (
        <>
          In order to prepare your tax return, we need additional information
          and documents from you. This checklist takes about <b>60 minutes.</b>
        </>
      ),
    }
  }, [businessForm?.id, isTwoFormFiler])

  if (forms.length === 0) {
    return null
  }

  return (
    <>
      <GridRowColumn spacer={4} width={8} centerContent>
        <Image style={{ width: 300, height: 300 }} src={config.image} />
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8} centerContent>
        <Text as="display2" textAlign="center">
          {config.title}
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8} short>
        <Text as="h2" textAlign="center">
          {config.subtitle}
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={4} width={8} short>
        <Text>{config.description}</Text>
      </GridRowColumn>
    </>
  )
}

const TaxChecklist = () => {
  const dispatch = useAppDispatch()
  const { scrollRef, scrollToRef } = useScrollRef()
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  ])
  const error = useReselector(selectErrorsForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchTaxUserDocumentsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (currentTaxYear) {
      dispatch(fetchUserEoyReviewProgress(currentTaxYear))
      dispatch(fetchUserTaxQuestionnaire(currentTaxYear))
    }
  }, [dispatch, currentTaxYear])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  return (
    <>
      <span ref={scrollRef} />
      <PageHeader
        header=""
        backControl={{ link: '/taxes/annual', text: 'Back to Tax Center' }}
      />
      <Grid>
        {isLoading && (
          <GridRowColumn>
            <Loader loading={isLoading} />
          </GridRowColumn>
        )}
        {!isLoading && error.length > 0 && (
          <GridRowColumn>
            <Alert type="error">Something went wrong</Alert>
          </GridRowColumn>
        )}
        <TaxChecklistTitle />
        <GridRowColumn>
          <CombinedChecklist />
        </GridRowColumn>
        <TaxChecklistFaq />
        <Grid.Row />
        <Grid.Row />
      </Grid>
    </>
  )
}

export default TaxChecklist
