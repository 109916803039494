import { createSelector } from 'reselect'
import moment, { Moment } from 'moment'
import filter from 'lodash/filter'

import { ReduxState } from '../../utils/typeHelpers'
import {
  getFinancialProfile,
  selectFinancialProfileIdByUserId,
  selectIsCurrentUserScorp,
} from '../../selectors/user.selectors'
import { insertPlaceholderDocuments } from './service'
import { TabCategoryKeys } from './constants'
import {
  getUserDocumentCategoriesById,
  selectScorpUserDocumentCategoryIds,
  selectUserDocumentCategoriesByInternalNames,
  selectUserDocumentCategoryByInternalName,
  selectUserDocumentCategoryList,
} from '../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { UserDocument } from './userDocuments.slice'
import { selectBookkeepingReports } from '../../selectors/bookkeepingReportsSelectors'
import { UploadDocumentType } from '../../constants/businessConstants'
import { getUsersTransactions } from '../Transactions/transactions.selectors'
import { selectTaxUserDocuments } from '../Taxes/AnnualTaxes/taxUserDocuments.selector'
import { UserDocumentCategoryIdentifier } from '../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { UserDocumentCategory } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { TaxUserDocument } from '../Taxes/AnnualTaxes/taxUserDocuments.slice'

export const selectDocuments = (state: ReduxState) => state.userDocuments

export const selectUserDocumentList = createSelector(
  selectDocuments,
  (documents) => Object.values(documents)
)

/*
  Get User Document by Id
*/
export const getUserDocumentById = createSelector(
  [selectDocuments, (_: unknown, id: string | number | null | undefined) => id],
  (userDocuments, id) => {
    return id && userDocuments[id] ? userDocuments[id] : null
  }
)

export const selectUserDocumentsWithPlaceholders = createSelector(
  getFinancialProfile,
  selectUserDocumentList,
  selectUserDocumentCategoryList,
  (fp, documents, categories) =>
    insertPlaceholderDocuments({
      documents,
      categories,
      entityType: fp?.businessEntity?.toString(),
    })
)

export const getUserTaxDocumentsForCategory = (
  userDocumentCategory: UserDocumentCategory | undefined,
  taxUserDocuments: TaxUserDocument[],
  userDocuments: UserDocument[],
  taxYear: string | undefined
) => {
  if (!userDocumentCategory) {
    return []
  }
  return userDocuments.filter((doc) => {
    if (!taxYear || !userDocumentCategory.isAnnual) {
      return doc.userDocumentCategoryId === userDocumentCategory.id
    }
    const taxUserDoc = taxUserDocuments.find(
      (taxDoc) => taxDoc.userDocumentId === doc.id
    )
    return (
      taxUserDoc?.taxYear === taxYear &&
      doc.userDocumentCategoryId === userDocumentCategory.id
    )
  })
}

export const selectUserTaxDocumentsForCategory = createSelector(
  selectUserDocumentCategoryByInternalName,
  selectTaxUserDocuments,
  selectUserDocumentList,
  (_: unknown, __: unknown, taxYear?: string) => taxYear,
  getUserTaxDocumentsForCategory
)

const generateCategorySelector = (type: string) =>
  createSelector(selectUserDocumentsWithPlaceholders, (documents) =>
    documents.filter(({ documentType }) => documentType === type)
  )

export const selectUserBankDocuments = generateCategorySelector(
  TabCategoryKeys.BANK_STATEMENTS
)
export const selectIncorporationDocuments = generateCategorySelector(
  TabCategoryKeys.INCORPORATION_DOCUMENTS
)
export const selectReceiptDocuments = generateCategorySelector(
  TabCategoryKeys.RECEIPTS
)

export const selectBookkeepingDocuments = generateCategorySelector(
  TabCategoryKeys.BOOKKEEPING
)

export const selectTaxDocuments = createSelector(
  generateCategorySelector(TabCategoryKeys.TAX),
  selectScorpUserDocumentCategoryIds,
  selectIsCurrentUserScorp,
  (documents, scorpCategoryIds, isScorp) =>
    documents.filter(({ userDocumentCategoryId, id }) =>
      // Non scorps should not see scorp doc placeholders
      isScorp || !userDocumentCategoryId || id !== -1
        ? true
        : !scorpCategoryIds.includes(userDocumentCategoryId.toString())
    )
)

export const selectOtherDocuments = createSelector(
  selectUserDocumentsWithPlaceholders,
  (documents) =>
    documents.filter(({ documentType }) =>
      [TabCategoryKeys.OTHER, null, ''].includes(documentType)
    )
)

export const selectDocumentsByUserId = createSelector(
  selectDocuments,
  selectFinancialProfileIdByUserId,
  (documents, financialProfileId) =>
    filter(
      documents,
      (document) => document.financialProfileId === financialProfileId
    )
)

export const selectDocumentsByFinancialProfileId = createSelector(
  selectDocuments,
  (_: unknown, financialProfileId: number) => financialProfileId,
  (documents, financialProfileId) =>
    filter(
      documents,
      (document) => document.financialProfileId === financialProfileId
    )
)

export const getUserDocumentsByCategoryInternalName = createSelector(
  [selectDocuments, selectUserDocumentCategoryByInternalName],
  (userDocuments, category) =>
    category
      ? filter(
          userDocuments,
          (document) => document.userDocumentCategoryId === category.id
        )
      : []
)

/*
 * returns a list of user documents where documentType is equal
 * to type, and the document does not need admin review
 * (needsReview is false or null).
 * When type is `other` returns all documents that are type `other`
 * do not have a documentType, or the document needs admin review
 * (needsReview is true).
 */
export const selectUserDocumentsByType = createSelector(
  selectDocumentsByUserId,
  (_: unknown, __: unknown, type: UploadDocumentType) => type,
  (documents, type) => {
    if (type === 'other') {
      return Object.values(documents).filter(
        (document) =>
          document.documentType === type ||
          !document.documentType ||
          Boolean(document.needsReview)
      )
    }
    return Object.values(documents).filter(
      (document) => document.documentType === type && !document.needsReview
    )
  }
)

export const selectNumberUserDocumentsNeedsReview = createSelector(
  selectDocumentsByUserId,
  (documents) =>
    Object.values(documents)
      .map((document) => document.needsReview)
      .reduce((val, doc) => val + Number(doc), 0)
)

export const getUserDocumentsByCategoryInternalNames = createSelector(
  [selectDocuments, selectUserDocumentCategoriesByInternalNames],
  (userDocuments, categories) => {
    const categoryIds = categories.map((category) => category.id)
    return filter(
      userDocuments,
      (document) =>
        document.userDocumentCategoryId !== null &&
        categoryIds.includes(document.userDocumentCategoryId)
    )
  }
)

// Fetch statement for given bookkeeping report

export const getUserStatementsForReport = (
  documents?: { [key: number]: UserDocument },
  date?: Moment
) => {
  if (!documents || !date) {
    return []
  }

  return Object.values(documents).filter(
    (document) =>
      document.documentType === 'statement' &&
      document.statementMonth === date.format('YYYY-MM')
  )
}

export const selectUserDocumentsByReportId = createSelector(
  selectDocumentsByUserId,
  selectBookkeepingReports,
  (_: unknown, __: unknown, reportId: string | number | undefined) => reportId,
  (documents, bookkeepingReportsById, reportId) => {
    const report = reportId ? bookkeepingReportsById[reportId] : undefined

    return report
      ? Object.values(documents).filter((document) => {
          const statementMonth =
            document.statement?.statementMonth || document.statementMonth
          return (
            document.documentType === 'statement' &&
            statementMonth === report.date
          )
        })
      : []
  }
)

// Needed for non uploaded docs
export const selectUserDocumentCategoryByDocument = createSelector(
  getUserDocumentCategoriesById,
  (_: unknown, document: UserDocument | null) => document,
  (cats, doc) =>
    (doc?.userDocumentCategoryId && cats[doc?.userDocumentCategoryId]) ||
    undefined
)

export const selectUserStatements = createSelector(
  [selectUserDocumentList],
  (documents) =>
    documents.filter((document) => document.documentType === 'statement')
)

/*
  Returns string[] in YYYY-MM format we are missing bank statements for

  Notes
  - If a user doesn't have at least one document per month for a given year, that month is considered
    to be "missing" a bank statement
  - UserDocument.statementMonth is stored as a YYYY-MM string in the database, e.g. Jan is "2022-01"
*/
export const selectMissingBankStatementMonthsForYear = createSelector(
  [selectUserStatements, (_: unknown, year: string) => year],
  (statementsOnFile, year) => {
    const yearNumber = Number(year)
    const statementMonthsSet = new Set()
    statementsOnFile.forEach((s) => statementMonthsSet.add(s.statementMonth))

    const missingStatementMonths = []

    for (let x = 0; x < 12; x++) {
      const statementMonth = moment()
        .year(yearNumber)
        .month(x)
        .format('YYYY-MM')
      if (!statementMonthsSet.has(statementMonth)) {
        missingStatementMonths.push(statementMonth)
      }
    }

    return missingStatementMonths
  }
)

export const selectStatementsForYear = createSelector(
  [selectUserStatements, (_: unknown, year: string) => year],
  (statementsOnFile, year) =>
    statementsOnFile.filter((doc) => doc.statementMonth?.includes(year))
)

/*
  Filters User documents on Admin panel to find matching by category
*/

export const getAdminUserDocumentsByCategoryInternalNames = createSelector(
  selectDocumentsByUserId,
  selectUserDocumentCategoryList,
  (_: unknown, __: unknown, categories: string[]) => categories,
  (userDocs, categoryList, categories) => {
    const catObjects = categoryList.filter((category) =>
      categories.includes(category.internalName)
    )
    const categoryIds = catObjects.map((category) => category.id)
    return filter(
      userDocs,
      (document) =>
        document.userDocumentCategoryId !== null &&
        categoryIds.includes(document.userDocumentCategoryId)
    )
  }
)

export const selectAdminTaxReturnDocumentsForTaxYear = createSelector(
  selectDocumentsByFinancialProfileId,
  selectUserDocumentCategoryList,
  selectTaxUserDocuments,
  (_: unknown, __: unknown, taxYear: string) => taxYear,
  (userDocs, categoryList, taxDocs, taxYear) => {
    const taxReturnCategories = categoryList.filter(
      (cat) =>
        cat.internalName === UserDocumentCategoryIdentifier.finalReturn ||
        cat.internalName ===
          UserDocumentCategoryIdentifier.finalReturnForm1040 ||
        cat.internalName === UserDocumentCategoryIdentifier.finalReturnForm1120s
    )
    return userDocs.filter((doc) => {
      const taxDoc = taxDocs.find((taxDoc) => taxDoc.userDocumentId === doc.id)
      return (
        taxReturnCategories.some(
          (cat) => cat.id === doc.userDocumentCategoryId
        ) && taxDoc?.taxYear === taxYear
      )
    })
  }
)

export const selectSortedTaxReturnDocumentsForYear = createSelector(
  selectUserDocumentList,
  selectUserDocumentCategoryList,
  selectTaxUserDocuments,
  (_: unknown, taxYear: string) => taxYear,
  (userDocs, categoryList, taxDocs, taxYear) => {
    const businessReturnCategory = categoryList.find(
      (cat) =>
        cat.internalName === UserDocumentCategoryIdentifier.finalReturnForm1120s
    )
    const business = userDocs.filter((doc) => {
      const taxDoc = taxDocs.find((taxDoc) => taxDoc.userDocumentId === doc.id)
      return (
        doc.userDocumentCategoryId === businessReturnCategory?.id &&
        taxDoc?.taxYear === taxYear
      )
    })

    const personalReturnCategory = categoryList.find(
      (cat) =>
        cat.internalName === UserDocumentCategoryIdentifier.finalReturnForm1040
    )
    const personal = userDocs.filter((doc) => {
      const taxDoc = taxDocs.find((taxDoc) => taxDoc.userDocumentId === doc.id)
      return (
        doc.userDocumentCategoryId === personalReturnCategory?.id &&
        taxDoc?.taxYear === taxYear
      )
    })

    const otherReturnCategory = categoryList.find(
      (cat) => cat.internalName === UserDocumentCategoryIdentifier.finalReturn
    )
    const other = userDocs.filter((doc) => {
      const taxDoc = taxDocs.find((taxDoc) => taxDoc.userDocumentId === doc.id)
      return (
        doc.userDocumentCategoryId === otherReturnCategory?.id &&
        taxDoc?.taxYear === taxYear
      )
    })

    return {
      business,
      personal,
      other,
    }
  }
)

export const selectAdminTaxDocumentsByYearAndCategory = createSelector(
  selectDocumentsByUserId,
  selectUserDocumentCategoryList,
  selectTaxUserDocuments,
  (_: unknown, __: unknown, taxYear: string) => taxYear,
  (_: unknown, __: unknown, ___: unknown, categoryInternalName: string) =>
    categoryInternalName,
  (userDocs, categoryList, taxDocs, taxYear, categoryInternalName) => {
    const category = categoryList.find(
      (cat) => cat.internalName === categoryInternalName
    )
    return userDocs.filter((doc) => {
      const taxDoc = taxDocs.find((taxDoc) => taxDoc.userDocumentId === doc.id)
      return (
        doc.userDocumentCategoryId === category?.id &&
        taxDoc?.taxYear === taxYear
      )
    })
  }
)

export const selectReceiptsForTransaction = createSelector(
  selectDocuments,
  getUsersTransactions,
  (_: unknown, transactionId: string | number) => transactionId,
  (_: unknown, __: unknown, splitIndex?: number) => splitIndex,
  (documents, userTransactions, transactionId, splitIndex) => {
    const receipts =
      typeof splitIndex === 'number'
        ? userTransactions?.[transactionId]?.splitTransactions?.[splitIndex]
            ?.receipts
        : userTransactions[transactionId]?.receipts
    if (!receipts) return null
    const receiptIds = receipts.map((receipt) => receipt.id)
    return filter(documents, (document) => receiptIds.includes(document.id))
  }
)

export const selectDocumentsInList = createSelector(
  selectDocuments,
  (_: unknown, docIds: number[] = []) => docIds,
  (documents, docIds) => filter(documents, (doc) => docIds?.includes(doc.id))
)
