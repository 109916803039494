import { createSelector } from 'reselect'
import { DateTime } from 'luxon'
import { ReduxState } from '../../utils/typeHelpers'
import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from './yearEndModuleStatus.slice'
import { getBlockedByText } from './yearEndModuleStatus.helpers'
import { selectUserShouldSeeOnboardingDashboard } from '../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { selectCurrentAnnualTaxDetails } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { isoToLocalDate } from '../../utils/dateHelpers'

export const getYearEndModuleStatusesByType = (state: ReduxState) =>
  state.yearEndModuleStatuses.byModuleType
export const getYearEndModuleStatuses = (state: ReduxState) =>
  state.yearEndModuleStatuses.allModules
export const getMostUrgentYearEndModuleType = (state: ReduxState) =>
  state.yearEndModuleStatuses.mostUrgentModule

export const getCompleteYearEndModuleStatuses = createSelector(
  getYearEndModuleStatuses,
  (modules) =>
    modules.filter(
      (module) => module.status === YearEndModuleStatusOptions.complete
    )
)
export const getIncompleteYearEndModuleStatuses = createSelector(
  getYearEndModuleStatuses,
  (modules) =>
    modules.filter(
      (module) => module.status !== YearEndModuleStatusOptions.complete
    )
)

export const selectAllYearEndModulesComplete = createSelector(
  getYearEndModuleStatuses,
  (modules) =>
    modules.every(
      (module) => module.status === YearEndModuleStatusOptions.complete
    )
)

export const selectOrderedCompletedYEModules = createSelector(
  getCompleteYearEndModuleStatuses,
  (completeModules) => completeModules.sort((a, b) => a.order - b.order)
)
export const selectOrderedIncompleteYEModules = createSelector(
  getIncompleteYearEndModuleStatuses,
  (incompleteModules) => incompleteModules.sort((a, b) => a.order - b.order)
)
export const selectMostUrgentModule = createSelector(
  getYearEndModuleStatusesByType,
  getMostUrgentYearEndModuleType,
  (byModuleType, mostUrgentModule) =>
    mostUrgentModule ? byModuleType[mostUrgentModule] : null
)

export const haveAnyModulesBeenStarted = createSelector(
  getYearEndModuleStatuses,
  (byModuleType) =>
    Object.values(byModuleType).some(
      (module) => module.startedAt || module.completedAt
    )
)

export const selectAvailableAfterTextFor = createSelector(
  getYearEndModuleStatuses,
  selectCurrentAnnualTaxDetails,
  selectUserShouldSeeOnboardingDashboard,
  (_: unknown, moduleType: YearEndModuleType) => moduleType,
  (yeModules, currentTaxDetails, userIsOnboarding, moduleType) => {
    const blockedByVerifyAndFileExtension = yeModules
      .filter(
        (module) =>
          (module.moduleType === YearEndModuleType.verifyTaxEntity ||
            module.moduleType === YearEndModuleType.fileExtensionRequest) &&
          module.status !== YearEndModuleStatusOptions.complete
      )
      .map((module) => module.moduleType)
    const blockedByTQAndEOYBKFollowup = yeModules
      .filter(
        (module) =>
          (module.moduleType === YearEndModuleType.taxQuestionnaire ||
            module.moduleType === YearEndModuleType.eoyBookkeepingFollowup) &&
          module.status !== YearEndModuleStatusOptions.complete
      )
      .map((module) => module.moduleType)

    switch (moduleType) {
      case YearEndModuleType.verifyTaxEntity:
      case YearEndModuleType.file1099Nec:
        // These modules don't get blockedBy others:
        return null
      case YearEndModuleType.submitExtensionRequest: {
        const now = DateTime.local()
        const date = isoToLocalDate(
          currentTaxDetails?.extension_survey_start_date ?? null
        )
        return getBlockedByText([], false, date && now > date ? null : date)
      }

      case YearEndModuleType.eoyBookkeeping: {
        const blockedBy = yeModules
          .filter(
            (module) =>
              module.moduleType === YearEndModuleType.fileExtensionRequest &&
              module.status !== YearEndModuleStatusOptions.complete
          )
          .map((module) => module.moduleType)
        return getBlockedByText(blockedBy, userIsOnboarding)
      }
      case YearEndModuleType.eoyBookkeepingFollowup: {
        const blockedBy = yeModules
          .filter(
            (module) =>
              (module.moduleType === YearEndModuleType.eoyBookkeeping ||
                module.moduleType === YearEndModuleType.verifyTaxEntity ||
                module.moduleType === YearEndModuleType.fileExtensionRequest) &&
              module.status !== YearEndModuleStatusOptions.complete
          )
          .map((module) => module.moduleType)
        return getBlockedByText(blockedBy)
      }
      case YearEndModuleType.taxQuestionnaire: {
        const now = DateTime.local()
        const date = isoToLocalDate(
          currentTaxDetails?.form_1120_s_tq_start_date ?? null
        )
        return getBlockedByText(
          blockedByVerifyAndFileExtension,
          userIsOnboarding,
          date && now > date ? null : date
        )
      }
      case YearEndModuleType.fileExtensionRequest:
        return getBlockedByText([YearEndModuleType.submitExtensionRequest])
      case YearEndModuleType.file1120S: {
        return getBlockedByText(blockedByTQAndEOYBKFollowup)
      }
      case YearEndModuleType.file1040: {
        const form1120s = yeModules.find(
          (module) => module.moduleType === YearEndModuleType.file1120S
        )
        if (form1120s) {
          return getBlockedByText([YearEndModuleType.file1120S])
        }
        return getBlockedByText([...blockedByTQAndEOYBKFollowup])
      }
      default:
        moduleType satisfies never
        return null
    }
  }
)
