import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../components/BaseComponents'
import { useFetchResponse } from '../../../utils/sharedHooks'
import { useMemo } from 'react'
import {
  TAX_CONSULT_VALUE_30_MINUTES,
  TAX_CONSULT_VALUE_60_MINUTES,
} from './TaxConsultationRequest'
import { useNavigate } from 'react-router-dom'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { fetchNextTaxConsultationCoupon } from './taxConsultation.actions'

const TaxConsultationCTA = () => {
  const consultationsEnabled = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableTaxConsultations,
    false
  )
  const navigate = useNavigate()
  const availableCoupon =
    useFetchResponse(fetchNextTaxConsultationCoupon) ?? null

  const content = useMemo(() => {
    if (
      availableCoupon?.percentOff &&
      availableCoupon.metadata?.durationInMinutes
    ) {
      const value =
        availableCoupon.metadata.durationInMinutes === '60'
          ? TAX_CONSULT_VALUE_60_MINUTES
          : TAX_CONSULT_VALUE_30_MINUTES
      if (availableCoupon.percentOff === 100) {
        return (
          <GridRowColumn short>
            <Text>
              After you’ve completed this tax questionnaire, book a <b>free</b>{' '}
              {availableCoupon.metadata.durationInMinutes}-minute consult ($
              {value} value) with your dedicated tax preparer.
            </Text>
          </GridRowColumn>
        )
      } else {
        return (
          <>
            <GridRowColumn short>
              <Text>
                After you’ve completed this tax questionnaire, book a{' '}
                {availableCoupon.metadata.durationInMinutes}-minute consult with
                your dedicated tax preparer for ${value}.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text as="bodySm">
                Your initial session is available at a{' '}
                {availableCoupon.percentOff}% off discounted rate.
              </Text>
            </GridRowColumn>
          </>
        )
      }
    }
    return (
      <GridRowColumn short>
        <Text>
          After you’ve completed this tax questionnaire, book a 30-minute
          consult with your dedicated tax preparer for $
          {TAX_CONSULT_VALUE_30_MINUTES}.
        </Text>
      </GridRowColumn>
    )
  }, [availableCoupon])

  if (!consultationsEnabled) {
    return null
  }

  return (
    <Card backgroundColor="lightYellow">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 40,
        }}
      >
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/two_phones.svg"
          style={{ width: 180, height: 'auto' }}
        />
        <Grid>
          <GridRowColumn>
            <Text as="eyebrow">Tip</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="h2">Get live tax advice</Text>
          </GridRowColumn>
          {content}
          <GridRowColumn short>
            <Button
              variant="link"
              onClick={() =>
                navigate('/taxes/annual/tax_consultations', {
                  state: {
                    fromTQ: 'true',
                  },
                })
              }
            >
              Learn more →
            </Button>
          </GridRowColumn>
        </Grid>
      </div>
    </Card>
  )
}

export default TaxConsultationCTA
