import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  FormikInput,
  FormikLabelError,
  FormikRadioButton,
  FormikRadioToggleButton,
  getFieldNames,
  GridRowColumn,
  Icon,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { PayrollInterestScreenName } from './PayrollInterestFlow'
import { Colors } from '../../../../styles/theme'
import { updateUser } from '../../../../actions/userActions'
import { markUserActionItemCompleteIfExists } from '../../../Dashboard/UserActionItems/service'
import { PostOnboardingTaskIdents } from '../../../Onboarding/config'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../../helpers'
import { PayrollSetup } from '../../../../reducers/auth/userReducer'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import {
  fetchUserOnboardingStepsIfNeeded,
  updateUserOnboardingStep,
} from '../../../Onboarding/UserOnboardingSteps/onboarding.actions'
import { DateTime } from 'luxon'
import { getUserStepIdByOnboardingStepId } from '../../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { GettingStartedOnboardingStep } from '../../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { useEffect } from 'react'

const PayrollInterestForm = ({
  setScreen,
}: {
  setScreen: (screen: string) => void
}) => {
  const currentUser = useReselector(getCurrentUser)
  const dispatch = useAppDispatch()

  const gettingStartedStepId = useReselector(
    getUserStepIdByOnboardingStepId,
    GettingStartedOnboardingStep.payrollInterest
  )

  useEffect(() => {
    dispatch(fetchUserOnboardingStepsIfNeeded())
  }, [dispatch])

  const formik = useFormik({
    initialValues: {
      payrollSetup: undefined as PayrollSetup | undefined,
      otherProvider: '',
      interestedInGep: undefined as boolean | undefined,
    },
    onSubmit: async ({ payrollSetup, otherProvider, interestedInGep }) => {
      const NOW = Date.now()

      const userRes = await dispatch(
        updateUser(currentUser?.id, {
          payrollSetup,
          payrollProvider:
            payrollSetup === PayrollSetup.other ? otherProvider : undefined,
          interestedInGepAt: interestedInGep ? NOW : undefined,
          gepRequestFormSubmittedAt: NOW,
        })
      )

      if (!userRes) {
        return
      }

      markUserActionItemCompleteIfExists(
        PostOnboardingTaskIdents.SET_UP_PAYROLL_ACCESS
      )

      if (gettingStartedStepId) {
        dispatch(
          updateUserOnboardingStep({
            id: gettingStartedStepId,
            updatableFields: { completedAt: DateTime.now().toISO() },
          })
        )
      }

      setScreen(PayrollInterestScreenName.OUTRO)
    },
  })

  const { values, submitForm, isSubmitting, isValid } = formik
  const fieldNames = getFieldNames(formik)

  const gridConfig = { ...makeGridConfig([10, 14], true) }

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...gridConfig}>
          <Text as="h1">Get started with payroll</Text>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <div
            style={{
              backgroundColor: Colors.natural,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/writing_check.svg"
              style={{ width: 180, height: 180 }}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <Text as="h2">Tell us about your payroll needs</Text>
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <Text>
            This will help us determine how to set up your payroll in Heard.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <FormikLabelError
            name={fieldNames.payrollSetup}
            label="Do you have a payroll provider?"
            schema={makeReqStringSchema({ field: 'payroll provider' })}
          />
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <FormikRadioButton
            name={fieldNames.payrollSetup}
            label="Yes, through Gusto"
            value={PayrollSetup.gusto}
          />
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <FormikRadioButton
            name={fieldNames.payrollSetup}
            label="Yes, through another provider"
            value={PayrollSetup.other}
          />
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <FormikRadioButton
            name={fieldNames.payrollSetup}
            label="No, I don't have payroll set up yet"
            value={PayrollSetup.none}
          />
        </GridRowColumn>

        {values.payrollSetup === PayrollSetup.other && (
          <GridRowColumn {...gridConfig}>
            <FormikInput
              name={fieldNames.otherProvider}
              label="Who is your payroll provider?"
              description="e.g. QuickBooks, OnPay, Square, etc."
              placeholder="Enter payroll provider name"
              fullWidth
            />
          </GridRowColumn>
        )}

        {values.payrollSetup && (
          <>
            <GridRowColumn {...gridConfig}>
              <FormikLabelError
                name={fieldNames.interestedInGep}
                label={
                  values.payrollSetup === PayrollSetup.gusto
                    ? 'Would you like to migrate your Gusto payroll to Heard?'
                    : 'Are you interested in setting up payroll on Heard?'
                }
                description={
                  values.payrollSetup === PayrollSetup.gusto
                    ? `Migrating allows you to manage your payroll directly within Heard. This is a base cost of $${GEP_SUB_PRICE}/month + $${GEP_PER_PERSON_PRICE}/individual per month.`
                    : `Heard has an integrated payroll via the Gusto platform. This is a base cost of $${GEP_SUB_PRICE}/month + $${GEP_PER_PERSON_PRICE}/individual per month.`
                }
              />
            </GridRowColumn>
            <GridRowColumn short {...gridConfig}>
              {[
                'Calculate a reasonable salary',
                'Simplify your tax forms',
                'Payroll taxes filed for you',
              ].map((item) => (
                <Text as="bodyXs" key={item} style={{ paddingBottom: 8 }}>
                  <Icon icon={light('check')} color="green" /> {item}
                </Text>
              ))}
            </GridRowColumn>
            <Grid.Row className="short">
              <Grid.Column computer={3} tablet={1} mobile={1} />
              <Grid.Column width={3}>
                <FormikRadioToggleButton
                  name={fieldNames.interestedInGep}
                  value
                  fullWidth
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={3}>
                <FormikRadioToggleButton
                  name={fieldNames.interestedInGep}
                  value={false}
                  fullWidth
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
          </>
        )}

        {values.interestedInGep && (
          <GridRowColumn {...gridConfig}>
            <Card type="subsection" backgroundColor="stone40">
              <Text as="h3">Heard Payroll doesn’t currently support:</Text>
              <Text>
                <ul>
                  <li>Contractor only payrolls</li>
                  <li>Gusto-sponsored or employee benefits</li>
                  <li>Time tracking</li>
                  <li>Check payments</li>
                  <li>100% commission-based payments</li>
                  <li>Multiple pay rates for a teammate</li>
                </ul>
              </Text>
              <Text>
                We’ll review your request to ensure that you meet the
                requirements.
              </Text>
            </Card>
          </GridRowColumn>
        )}

        <FormFlowFooter
          onBack={() => setScreen(PayrollInterestScreenName.INTRO)}
          loading={isSubmitting}
          continueDisabled={isSubmitting || !isValid}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default PayrollInterestForm
