import { Grid, Image } from 'semantic-ui-react'

import {
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { PayrollInterestScreenName } from './PayrollInterestFlow'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const PayrollInterestIntro = ({
  setScreen,
}: {
  setScreen: (screen: string | null) => void
}) => {
  const gridConfig = { ...makeGridConfig([10, 14], true) }

  return (
    <Grid>
      <GridRowColumn {...gridConfig}>
        <Text as="h1">Get started with payroll</Text>
      </GridRowColumn>
      <Grid.Row />
      <GridRowColumn {...gridConfig}>
        <Text>
          As an S corporation, you’re required by the IRS to pay yourself and
          any employees through payroll.
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={3} />
        <Grid.Column
          width={2}
          verticalAlign="middle"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/Bank.svg"
            style={{ width: 80, height: 80 }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Text as="h3">Why am I required to have payroll?</Text>
          <br />
          <Text>
            This guarantees that you’re paying yourself a{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/7475592736919-How-do-I-set-a-reasonable-salary-and-why-is-it-important"
              newPage
            >
              reasonable salary
            </Link>
            , and that payroll taxes (Medicare and Social Security) are being
            collected.
          </Text>
        </Grid.Column>
      </Grid.Row>
      `
      <Grid.Row>
        <Grid.Column width={3} />
        <Grid.Column
          width={2}
          verticalAlign="middle"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/question_coin.svg"
            style={{ width: 80, height: 80 }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Text as="h3">What if I don’t have a payroll provider?</Text>
          <br />
          <Text>
            You can sign up for Heard Payroll as an add-on service. You’ll be
            able to do this in a later step.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn {...gridConfig}>
        <Text>Next, we’ll ask for some details about your payroll needs.</Text>
      </GridRowColumn>
      <FormFlowFooter
        onBack={() => setScreen(null)}
        onForward={() => setScreen(PayrollInterestScreenName.FORM)}
      />
    </Grid>
  )
}

export default PayrollInterestIntro
