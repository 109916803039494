import { Grid, Image, List } from 'semantic-ui-react'
import PageHeader from '../../../components/shared/PageHeader'
import {
  Alert,
  Button,
  Card,
  CircularWrapper,
  Container,
  GridRowColumn,
  Icon,
  Label,
  Loader,
  Modal,
  Radio,
  Text,
} from '../../../components/BaseComponents'
import {
  useFetchResponse,
  useLoadedFlagValue,
  useReselector,
  useScrollRef,
} from '../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  dollarsToCurrency,
  formatCurrency,
} from '../../../utils/currencyHelpers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  selectFirstErrorMessageForKeys,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import {
  FETCH_USER_COUPONS_KEY,
  fetchUserCoupons,
  UserCoupon,
  UserCouponProductName,
} from '../../UserCoupons/userCoupons.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  FETCH_NEXT_TAX_CONSULTATION_COUPON_KEY,
  FETCH_TAX_CONSULTATION_CHECKOUT_SESSION_KEY,
  fetchNextTaxConsultationCoupon,
  fetchTaxConsultationCheckoutSession,
} from './taxConsultation.actions'
import {
  selectOldestRedeemedTaxAdvisoryCoupon,
  selectRedeemedTaxAdvisoryCouponsForTaxYear,
} from '../../UserCoupons/userCoupons.selectors'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../utils/dateHelpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { hasFilingJobAssigned } from '../AnnualTaxes/annualTaxFilings.selector'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import {
  FETCH_TAX_CONSULTATIONS_KEY,
  fetchTaxConsultationRequests,
  inProgressConsultationStatuses,
} from './taxConsultationRequest.actions'

export const TAX_CONSULT_VALUE_30_MINUTES = 150
export const TAX_CONSULT_VALUE_60_MINUTES = 300

const HowItWorksModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const NumberedItem = ({
    number,
    title,
    children,
  }: {
    number: number
    title: string
    children: React.ReactNode
  }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      <div>
        <CircularWrapper wrapperColor="green" height={32}>
          <Text color="white">{number}</Text>
        </CircularWrapper>
      </div>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Text as="h3">{title}</Text>
        <Text>{children}</Text>
      </div>
    </div>
  )

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Text as="h2">How tax consults work</Text>
      </Modal.Header>
      <Modal.Content>
        <Card backgroundColor="natural">
          <NumberedItem number={1} title="Finish Tax Questionnaire">
            After you submit your Tax Questionnaire and finish your bookkeeping
            tasks, you’ll be connected with your tax preparer.
          </NumberedItem>
          <NumberedItem number={2} title="Request consult from tax preparer">
            Chat with your tax preparer about if you would benefit from having a
            conversation. Your preparer will provide a link to process the
            request and collect payment (if applicable).
          </NumberedItem>
          <NumberedItem number={3} title="Schedule consult">
            Work directly with your tax preparer to schedule the consult. Please
            allow at least 24 hour notice if canceling or rescheduling.
          </NumberedItem>
        </Card>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const DiscountedPriceCard = ({
  userCoupon,
}: {
  userCoupon?: UserCoupon | null
}) => {
  const firstRedeemedCoupon = useReselector(
    selectOldestRedeemedTaxAdvisoryCoupon
  )
  const isFirstRedeemedCoupon = Boolean(
    firstRedeemedCoupon?.id === userCoupon?.id
  )
  const couponDuration = useMemo(
    () =>
      userCoupon?.metadata?.durationInMinutes
        ? Number(userCoupon?.metadata.durationInMinutes)
        : null,
    [userCoupon]
  )
  const discountPercent = useMemo(() => {
    if (!userCoupon) {
      return null
    }
    if (userCoupon.redeemedAt) {
      return 'REDEEMED'
    }
    return userCoupon?.percentOff === 100
      ? 'FREE'
      : `${userCoupon?.percentOff}% OFF`
  }, [userCoupon])
  const displayPrice = useMemo(() => {
    const fullCost = dollarsToCurrency(
      couponDuration === 60
        ? TAX_CONSULT_VALUE_60_MINUTES
        : TAX_CONSULT_VALUE_30_MINUTES
    )
    if (!userCoupon?.percentOff) {
      return {
        fullPrice: formatCurrency(fullCost, { hideDecimalsIfZero: true }),
        discountedPrice: formatCurrency(fullCost, { hideDecimalsIfZero: true }),
      }
    }
    return {
      fullPrice: formatCurrency(fullCost, { hideDecimalsIfZero: true }),
      discountedPrice: formatCurrency(
        fullCost.subtract(fullCost.multiply(userCoupon.percentOff / 100)),
        { hideDecimalsIfZero: true }
      ),
    }
  }, [couponDuration, userCoupon])
  const isRedeemed = Boolean(userCoupon?.redeemedAt)

  return (
    <Card backgroundColor={isRedeemed ? 'stone' : 'white'} variant="noShadow">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={10}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            <Text as="eyebrow" color="darkGray">
              {!firstRedeemedCoupon || isFirstRedeemedCoupon ? 'First ' : ''}{' '}
              consult
            </Text>
            <Text>{couponDuration} min call</Text>
          </Grid.Column>
          <Grid.Column width={6}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  gap: 8,
                  alignItems: 'center',
                }}
              >
                <Label
                  style={{ height: 'auto', whiteSpace: 'nowrap' }}
                  color={isRedeemed ? 'gray' : 'red'}
                >
                  {discountPercent}
                </Label>
                <Text as="display" color={isRedeemed ? 'mediumGray' : 'green'}>
                  {displayPrice.discountedPrice}
                </Text>
              </div>
              <Text
                textAlign="right"
                style={{ textDecoration: 'line-through' }}
                color="darkGray"
              >
                {displayPrice.fullPrice}
              </Text>
              {isRedeemed && (
                <Text as="bodySm" textAlign="right" color="darkGray">
                  Redeemed on{' '}
                  {convertUtcToLocalDate(
                    userCoupon?.redeemedAt ?? undefined
                  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
                </Text>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const CombinedPriceCard = () => (
  <Card backgroundColor="white" variant="noShadow">
    <Grid>
      <GridRowColumn style={{ paddingBottom: 8 }}>
        <Text as="eyebrow" color="darkGray">
          additional consults
        </Text>
      </GridRowColumn>
      <Grid.Row style={{ padding: 0, paddingBottom: 4 }}>
        <Grid.Column width={12}>
          <Text>30 min call</Text>
        </Grid.Column>
        <Grid.Column width={4}>
          <Text textAlign="right">
            {formatCurrency(TAX_CONSULT_VALUE_30_MINUTES, {
              hideDecimalsIfZero: true,
            })}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={12}>
          <Text>60 min call</Text>
        </Grid.Column>
        <Grid.Column width={4}>
          <Text textAlign="right">
            {formatCurrency(TAX_CONSULT_VALUE_60_MINUTES, {
              hideDecimalsIfZero: true,
            })}
          </Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Card>
)

const SeparatedPriceCard = ({ callDuration }: { callDuration: 30 | 60 }) => {
  const price = useMemo(
    () =>
      formatCurrency(
        callDuration === 30
          ? TAX_CONSULT_VALUE_30_MINUTES
          : TAX_CONSULT_VALUE_60_MINUTES,
        { hideDecimalsIfZero: true }
      ),
    [callDuration]
  )

  return (
    <Card backgroundColor="white" variant="noShadow">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={10}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            <Text as="eyebrow" color="darkGray">
              consult
            </Text>
            <Text>{callDuration} min call</Text>
          </Grid.Column>
          <Grid.Column
            width={6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Text as="display" color="green">
              {price}
            </Text>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const PricingSection = ({ userCoupon }: { userCoupon?: UserCoupon | null }) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const redeemedCoupons = useReselector(
    selectRedeemedTaxAdvisoryCouponsForTaxYear
  )
  const mobileStyle = isMobile ? { padding: 0 } : {}

  return (
    <Card backgroundColor="natural" variant="noShadow">
      <Grid style={{ display: 'block' }}>
        <GridRowColumn columnStyle={mobileStyle}>
          <Text as="h2">Pricing</Text>
        </GridRowColumn>
        <Grid>
          {userCoupon || redeemedCoupons.length > 0 ? (
            <>
              {userCoupon?.percentOff && (
                <GridRowColumn columnStyle={mobileStyle}>
                  <DiscountedPriceCard userCoupon={userCoupon} />
                </GridRowColumn>
              )}
              <GridRowColumn columnStyle={mobileStyle}>
                <CombinedPriceCard />
              </GridRowColumn>
              {redeemedCoupons.map((coupon) => (
                <GridRowColumn key={coupon.id} columnStyle={mobileStyle}>
                  <DiscountedPriceCard userCoupon={coupon} />
                </GridRowColumn>
              ))}
            </>
          ) : (
            <>
              <GridRowColumn columnStyle={mobileStyle}>
                <SeparatedPriceCard callDuration={30} />
              </GridRowColumn>
              <GridRowColumn columnStyle={mobileStyle}>
                <SeparatedPriceCard callDuration={60} />
              </GridRowColumn>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

const FreeConsultationCard = ({
  userCoupon,
}: {
  userCoupon?: UserCoupon | null
}) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const consultDuration = useMemo(
    () => userCoupon?.metadata?.durationInMinutes || null,
    [userCoupon]
  )
  const firstRedeemedCoupon = useReselector(
    selectOldestRedeemedTaxAdvisoryCoupon
  )
  const isFree = Boolean(userCoupon?.percentOff === 100)

  if (!userCoupon?.percentOff || !consultDuration) {
    return null
  }

  return (
    <Card backgroundColor="natural" variant="noShadow">
      <div style={{ display: 'flex', gap: 32, alignItems: 'center' }}>
        <Grid>
          <GridRowColumn>
            <Label color="green" style={{ display: 'flex', gap: 8 }}>
              <Icon icon={light('star')} color="green" />
              Member Benefit
            </Label>
          </GridRowColumn>
          <GridRowColumn>
            {isFree && (
              <Text as="h2">
                Congratulations! You have a free consult available.
              </Text>
            )}
            {!isFree && (
              <Text as="h2">
                Congratulations! You have {userCoupon.percentOff}% off your{' '}
                {!firstRedeemedCoupon ? 'first consult' : 'next consult'}
              </Text>
            )}
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              You have not yet redeemed your{' '}
              {isFree ? 'free' : `${userCoupon.percentOff}% off promo for a`}{' '}
              {consultDuration} minute 1:1 consult with your tax preparer for
              the {taxYear} tax season.{' '}
              {isFree && 'If you book now, you will not be charged.'}
            </Text>
          </GridRowColumn>
        </Grid>
        {!isMobile && (
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/giftbox_no_shadow.svg"
            style={{ height: 80, width: 80 }}
          />
        )}
      </div>
    </Card>
  )
}

const PhoneImage = () => (
  <CircularWrapper height={249} wrapperColor="lightYellow">
    <Image
      src="https://heard-images.s3.amazonaws.com/assets/two_phones.svg"
      alt="two phones"
      style={{ height: 172, width: 180 }}
    />
  </CircularWrapper>
)

const CheckItem = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => (
  <List.Item>
    <div style={{ display: 'flex', gap: 16, marginBottom: 8 }}>
      <Text>
        <Icon icon={regular('check')} color="green" />
      </Text>
      <div>
        <Text as="h3">{title}</Text>
        <Text>{children}</Text>
      </div>
    </div>
  </List.Item>
)

const NoOpenJobs = ({
  unredeemedCoupon,
}: {
  unredeemedCoupon?: UserCoupon | null
}) => {
  const hasClaimedFilingJob = useReselector(hasFilingJobAssigned)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [open, setOpen] = useState(false)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const isFree = Boolean(unredeemedCoupon?.percentOff === 100)
  const location = useLocation()
  const backLink = useMemo(
    () =>
      location.state?.fromTQ === 'true'
        ? '/taxes/annual/tax_checklist'
        : '/taxes/annual',
    [location.state?.fromTQ]
  )

  if (hasClaimedFilingJob) {
    return null
  }

  return (
    <Grid>
      <GridRowColumn style={{ marginBottom: 48 }}>
        <PageHeader
          divider={false}
          header="Tax Consults"
          backControl={{
            link: backLink,
            text: 'Go back',
          }}
        />
      </GridRowColumn>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          marginBottom: 40,
        }}
      >
        <div style={{ marginRight: isMobile ? 0 : 64 }}>
          <Grid>
            <GridRowColumn
              columnStyle={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 16,
              }}
            >
              <PhoneImage />
            </GridRowColumn>
            <GridRowColumn
              short
              textAlign="center"
              style={{ marginBottom: 16 }}
            >
              <Text as="h2">Chat with your preparer to schedule</Text>
            </GridRowColumn>
            <GridRowColumn
              columnStyle={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 64,
              }}
            >
              <Button onClick={() => setOpen(true)}>How does it work?</Button>
            </GridRowColumn>
          </Grid>
        </div>
        <div>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Book a tax consult</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text>
                During the {taxYear} tax season, schedule a{' '}
                {isFree &&
                  `free ${unredeemedCoupon?.metadata?.durationInMinutes} minute `}
                consult
                {isFree &&
                  ` (${formatCurrency(TAX_CONSULT_VALUE_30_MINUTES, { hideDecimalsIfZero: true })} value)`}{' '}
                with your dedicated tax preparer.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>It’s a great way to:</Text>
            </GridRowColumn>
            <GridRowColumn>
              <List>
                <CheckItem title="Discuss complex tax situations">
                  Convey your unique and complicated tax scenarios in-depth to
                  your tax preparer.
                </CheckItem>
                <CheckItem title="Review your tax return in detail">
                  Schedule a walkthrough of your return to resolve any lingering
                  questions you may have.
                </CheckItem>
                <CheckItem title="Get tax planning advice">
                  Create a strategy with your tax advisor for how to prepare for
                  taxes for the coming year.
                </CheckItem>
              </List>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodySm">
                Note: Consult must take place before you file your return.
              </Text>
            </GridRowColumn>
            <Grid.Row />
            <PricingSection userCoupon={unredeemedCoupon} />
          </Grid>
        </div>
      </div>
      <HowItWorksModal open={open} onClose={() => setOpen(false)} />
    </Grid>
  )
}

const JobInProgress = ({
  unredeemedCoupon,
  setError,
}: {
  unredeemedCoupon: UserCoupon | null
  setError: (error: string | null) => void
}) => {
  const hasClaimedFilingJob = useReselector(hasFilingJobAssigned)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [open, setOpen] = useState(false)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const mobileStyle = isMobile ? { padding: 0 } : {}
  const isFree = Boolean(unredeemedCoupon?.percentOff === 100)
  const [selectedDuration, setSelectedDuration] = useState<number | undefined>(
    unredeemedCoupon?.metadata?.durationInMinutes
      ? Number(unredeemedCoupon.metadata?.durationInMinutes)
      : undefined
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const backLink = useMemo(
    () =>
      location.state?.fromTQ === 'true'
        ? '/taxes/annual/tax_checklist'
        : '/taxes/annual',
    [location.state?.fromTQ]
  )

  useEffect(() => {
    if (unredeemedCoupon?.metadata?.durationInMinutes) {
      setSelectedDuration(Number(unredeemedCoupon.metadata.durationInMinutes))
    }
  }, [unredeemedCoupon])

  const checkout = useCallback(async () => {
    try {
      setIsSubmitting(true)
      setError(null)
      if (!selectedDuration) {
        setError('Please select an option.')
        return false
      }
      let paymentLink: { url: string; internal: boolean } | undefined
      if (selectedDuration === 30) {
        paymentLink = await fetchTaxConsultationCheckoutSession(30)(dispatch)
      } else if (selectedDuration === 60) {
        paymentLink = await fetchTaxConsultationCheckoutSession(60)(dispatch)
      }
      if (paymentLink) {
        if (paymentLink.internal) {
          navigate(paymentLink.url)
        } else {
          window.open(paymentLink.url, '_self')
        }
        return true
      }
      return false
    } finally {
      setIsSubmitting(false)
    }
  }, [selectedDuration, dispatch, navigate, setError])

  if (!hasClaimedFilingJob) {
    return null
  }

  return (
    <Grid style={{ marginBottom: 80 }}>
      <GridRowColumn style={{ marginBottom: 48 }}>
        <PageHeader
          divider={false}
          header="Request a tax consult"
          backControl={{
            link: backLink,
            text: 'Go back',
          }}
        />
      </GridRowColumn>
      <GridRowColumn
        style={{ marginBottom: 16 }}
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <PhoneImage />
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          During the {taxYear} tax season, schedule a{' '}
          {isFree &&
            `free ${unredeemedCoupon?.metadata?.durationInMinutes} minute `}
          consult
          {isFree &&
            ` (${formatCurrency(TAX_CONSULT_VALUE_30_MINUTES, { hideDecimalsIfZero: true })} value)`}{' '}
          with your dedicated tax preparer.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <Button onClick={() => setOpen(true)} variant="secondaryLink">
          How does it work?
        </Button>
      </GridRowColumn>
      <GridRowColumn columnStyle={mobileStyle}>
        <FreeConsultationCard userCoupon={unredeemedCoupon} />
      </GridRowColumn>
      <GridRowColumn columnStyle={mobileStyle}>
        <PricingSection userCoupon={unredeemedCoupon} />
      </GridRowColumn>
      <Grid.Row />
      {!unredeemedCoupon && (
        <>
          <GridRowColumn textAlign="center">
            <Text as="h3">Which consult would you like to request?</Text>
          </GridRowColumn>
          <GridRowColumn short textAlign="center">
            <Radio
              name="consult-duration"
              onClick={() => setSelectedDuration(30)}
              checked={selectedDuration === 30}
              label={`30 minute add-on consult (${formatCurrency(TAX_CONSULT_VALUE_30_MINUTES, { hideDecimalsIfZero: true })})`}
            />
          </GridRowColumn>
          <GridRowColumn short textAlign="center">
            <Radio
              name="consult-duration"
              onClick={() => setSelectedDuration(60)}
              checked={selectedDuration === 60}
              label={`60 minute add-on consult (${formatCurrency(TAX_CONSULT_VALUE_60_MINUTES, { hideDecimalsIfZero: true })})`}
            />
          </GridRowColumn>
          <Grid.Row />
        </>
      )}
      {isFree && (
        <GridRowColumn textAlign="center">
          <Text as="h3">Click to redeem your free consult.</Text>
        </GridRowColumn>
      )}
      <GridRowColumn
        short
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={checkout}
          style={{ display: 'flex', gap: 8 }}
        >
          {isFree ? 'Redeem' : 'Purchase'}
          <Icon icon={regular('arrow-up-right-from-square')} size="1x" />
        </Button>
      </GridRowColumn>
      {!isFree && (
        <GridRowColumn short>
          <Text textAlign="center" as="bodySm" color="darkGray">
            You’ll be redirected to Stripe to confirm payment.
          </Text>
        </GridRowColumn>
      )}
      <HowItWorksModal open={open} onClose={() => setOpen(false)} />
    </Grid>
  )
}

const TaxAdvisoryRequest = () => {
  const { scrollRef, scrollToRef } = useScrollRef()
  const fetchKeys = [
    FETCH_USER_COUPONS_KEY,
    FETCH_NEXT_TAX_CONSULTATION_COUPON_KEY,
    FETCH_TAX_CONSULTATION_CHECKOUT_SESSION_KEY,
  ]
  const errorMessage = useReselector(selectFirstErrorMessageForKeys, fetchKeys)
  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_USER_COUPONS_KEY,
    FETCH_NEXT_TAX_CONSULTATION_COUPON_KEY,
    FETCH_TAX_CONSULTATIONS_KEY,
  ])
  const [error, setError] = useState<string | null>(null)
  const unredeemedCoupon =
    useFetchResponse(fetchNextTaxConsultationCoupon) ?? null
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const consultationsEnabled = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.enableTaxConsultations,
    false
  )

  useEffect(() => {
    if (consultationsEnabled === false) {
      navigate('/taxes/annual')
    }
  }, [consultationsEnabled, navigate])

  useEffect(() => {
    // if they currently have an in-progress consultation, redirect the to the confirmation page
    const loadConfirmationPage = async () => {
      const res = (await fetchTaxConsultationRequests()(dispatch)) ?? []
      const inProgressConsultation = res.find((request) =>
        inProgressConsultationStatuses.includes(request.status)
      )
      if (inProgressConsultation) {
        navigate(
          `/taxes/annual/tax_consultations/${inProgressConsultation.id}/confirmed`
        )
      }
    }
    loadConfirmationPage()
  }, [dispatch, navigate])

  useEffect(() => {
    if (errorMessage || error) {
      scrollToRef()
    }
  }, [errorMessage, error, scrollToRef])

  useEffect(() => {
    fetchUserCoupons({
      includeRedeemed: true,
      productName: UserCouponProductName.taxAdvisory,
    })(dispatch)
  }, [dispatch])

  return (
    <Container variant="noShadow" centerContent>
      <Loader loading={isLoading} />
      <div style={{ maxWidth: 920 }}>
        {(error || errorMessage) && (
          <Grid>
            <span ref={scrollRef} />
            <GridRowColumn>
              <Alert type="error">{error || errorMessage}</Alert>
            </GridRowColumn>
          </Grid>
        )}
        <NoOpenJobs unredeemedCoupon={unredeemedCoupon} />
        <JobInProgress
          setError={setError}
          unredeemedCoupon={unredeemedCoupon}
        />
      </div>
    </Container>
  )
}

export default TaxAdvisoryRequest
