import { Grid } from 'semantic-ui-react'

import UploadCard from './UploadCard'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { filterNulls } from '../../../../../utils/typeHelpers'
import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import { tqBigSpace } from '../helpers'

const UploadDocumentSubSection = ({
  categories,
}: {
  categories: Array<UserDocumentCategoryIdentifier | undefined>
}) => {
  const filteredCats = filterNulls(categories)

  if (!filteredCats.length) {
    return null
  }

  return (
    <GridRowColumn {...tqBigSpace}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Upload documents</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            If you don’t have a document on hand, you may skip for now. You’ll
            be able to view instructions and upload everything at the end.
          </Text>
        </GridRowColumn>
        {filteredCats.map((category) => (
          <GridRowColumn key={category}>
            <UploadCard docCategoryIdentifier={category} />
          </GridRowColumn>
        ))}
      </Grid>
    </GridRowColumn>
  )
}

export default UploadDocumentSubSection
