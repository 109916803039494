import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  CircularWrapper,
  GridRowColumn,
  Icon,
  Text,
  Link,
  Button,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useReselector } from '../../../../utils/sharedHooks'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { PayrollSetup } from '../../../../reducers/auth/userReducer'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../../helpers'
import { Colors } from '../../../../styles/theme'

export const PayrollInterestOutroContent = () => {
  const user = useReselector(getCurrentUser)
  const gridConfig = { ...makeGridConfig([10, 14], true) }

  const cardContent = useMemo(() => {
    if (user?.interestedInGepAt) {
      return (
        <>
          <Text>
            Our team will review your information to ensure you meet the
            requirements for Heard payroll. After you’re approved, follow the
            instructions below to enroll.
          </Text>
          <br />
          <Text>We’ll also send you this information in a message.</Text>
        </>
      )
    }
    if (user?.payrollSetup === PayrollSetup.gusto) {
      return (
        <>
          <Text>
            Want to save time during tax season? Add Heard as Full Access Admin
            so that we can retrieve your end of year tax documents & complete
            year-end tasks on your behalf.
          </Text>
          <Text>
            Instructions
            <br />
            Follow these instructions to add an administrator to your Gusto
            payroll.
          </Text>
          <Text>
            <ul>
              <li>Email: payroll@joinheard.com</li>
              <li>First Name: Heard</li>
              <li>Last Name: Payroll</li>
            </ul>
          </Text>
        </>
      )
    } else if (user?.payrollSetup === PayrollSetup.other) {
      return (
        <Text>
          Since you’re using a separate payroll provider, your payroll data
          won’t be available in Heard. Please connect with your provider’s
          support team for any further assistance.
        </Text>
      )
    } else if (user?.payrollSetup === PayrollSetup.none) {
      return (
        <Text>
          Please note that as an S corporation, you’re <b>required</b> by the
          IRS to pay yourself through a payroll.
          <br />
          We’ll remind you to update this information later. If you wish to sign
          up for Heard Payroll in the future, you can find it in your sidebar.
        </Text>
      )
    }
    return null
  }, [user?.interestedInGepAt, user?.payrollSetup])

  return (
    <>
      <GridRowColumn {...gridConfig}>
        <Text as="h1">Get started with payroll</Text>
      </GridRowColumn>
      <GridRowColumn {...gridConfig}>
        <Card type="subsection" backgroundColor="lightGreen">
          <br />
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/check.svg"
            style={{
              width: 80,
              height: 80,
              backgroundColor: Colors.transparent,
            }}
          />
          <br />
          <Text as="h2">
            {user?.interestedInGepAt
              ? 'Thanks! We’re reviewing your request'
              : 'Thanks for letting us know about your payroll needs!'}
          </Text>
          <br />
          {cardContent}
        </Card>
      </GridRowColumn>
      {user?.interestedInGepAt && (
        <>
          <GridRowColumn {...gridConfig}>
            <Text as="h2">What to expect next</Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column
              width={1}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <CircularWrapper height={28} wrapperColor="stone">
                <Text as="h3">1</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={4}>
              <Text>Enroll in Payroll</Text>
              <Text as="bodySm">
                Once a member of our Support team has verified your eligibility,
                go to <b>Enroll in Payroll</b> in your sidebar.
              </Text>
              <Text as="bodyXs" color="darkGray">
                Heard Payroll is a base cost of ${GEP_SUB_PRICE}/month + $
                {GEP_PER_PERSON_PRICE}/individual per month. Once you migrate,
                your subscription will be charged by Heard, not Gusto.
              </Text>
            </Grid.Column>
            <Grid.Column width={5} verticalAlign="middle">
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/payroll_enroll.png"
                style={{ width: 182, height: 70 }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column
              width={1}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <CircularWrapper height={28} wrapperColor="stone">
                <Text as="h3">2</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={9}>
              {user?.payrollSetup === PayrollSetup.gusto ? (
                <>
                  <Text>Connect to Gusto</Text>
                  <Text as="bodySm">
                    Link your Heard and Gusto account by reading and accepting
                    the Terms of Service.
                  </Text>
                </>
              ) : (
                <>
                  <Text>Complete payroll onboarding</Text>
                  <Text as="bodySm">This takes 30-45 minutes.</Text>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column
              width={1}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <CircularWrapper height={28} wrapperColor="stone">
                <Text as="h3">3</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={7}>
              <Text>Start managing your payroll in Heard</Text>
              <Text as="bodySm">
                Your payroll should now be active in Heard.
                {user?.payrollSetup === PayrollSetup.other
                  ? ' Export your statements from your previous provider and upload them into Gusto.'
                  : ''}
              </Text>

              {user?.payrollSetup === PayrollSetup.gusto && (
                <>
                  <br />
                  <Text as="bodyXs" color="darkGray">
                    Important: Migration fully transfers your payroll to Heard.
                    Your Gusto payroll will no longer be available, nor will
                    they be able to provide support. Please reach out to us via
                    Messages instead.
                  </Text>
                </>
              )}
              <br />
              <Link
                href="https://support.joinheard.com/hc/en-us/sections/7709867454359-Payroll"
                newPage
                style={{ fontSize: 14 }}
              >
                Heard Payroll help articles{' '}
                <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </>
  )
}

const PayrollInterestOutro = () => {
  const navigate = useNavigate()

  return (
    <Grid>
      <PayrollInterestOutroContent />
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column computer={13} tablet={8} only="computer tablet" />
        <Grid.Column computer={3} tablet={5} mobile={16}>
          <Button fullWidth onClick={() => navigate('/')}>
            Done
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PayrollInterestOutro
