import { Divider, Grid, Image } from 'semantic-ui-react'
import {
  GridRowColumn,
  Button,
  Text,
  Loader,
  Link,
  CircularWrapper,
} from '../../../components/BaseComponents'
import Card from '../../../components/BaseComponents/Card'
import {
  FinancialAdvisoryProfile,
  getUpriseCalendlyEventDetails,
} from '../financialAdvisory.slice'
import { useEffect, useState } from 'react'
import { useAnalyticsView } from '../../Amplitude'
import { fetchUserFinancialAdvisoryCallEventsIfNeeded } from '../userFinancialAdvisoryCallEvents.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getIntroUserFinancialAdvisoryCallEvents } from '../financialAdvisory.selectors'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { Colors } from '../../../styles/theme'
import {
  useIsDeviceWidth,
  DeviceWidthCombo,
} from '../../../utils/deviceWidthHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { UpriseIntroCard } from './IntroCallCTAPanel'

export const AdvisorHelpSummaryCard = () => {
  return (
    <Card type="subsection" backgroundColor="natural">
      <Grid stackable doubling centered>
        <GridRowColumn />
        <Grid.Row>
          <Grid.Column textAlign="center" width={12}>
            <Text as="h1">Get to the financial future you deserve</Text>
            <br />
            <Text as="h2">
              With personalized financial advising and planning that&#39;s
              proven to work for practices like yours.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5} textAlign="left">
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisor_piggy_bank.svg"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">Expert Driven</Text>
            <br />
            <Text as="bodyMd">
              Dedicated CFP® advisor who understands therapy practices and can
              help with:
              <ul>
                <li>Business structure decisions</li>
                <li>Retirement planning</li>
                <li>Student loan repayment strategies</li>
                <li>Saving strategies</li>
                <li>And more</li>
              </ul>
            </Text>
          </Grid.Column>
          <Grid.Column width={5} textAlign="left">
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/step_guidance.png"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">Step by Step Guidance</Text>
            <br />
            <Text as="bodyMd">
              Real time, step by step guidance
              <ul>
                <li>Financial goals broken down into actionable steps</li>
                <li>Prioritized recommendations</li>
                <li>Personalized for your financial priorities</li>
              </ul>
            </Text>
          </Grid.Column>
          <Grid.Column width={5} textAlign="left">
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                marginBottom: '20px',
              }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/advisor_financials.svg"
                alt="heard financial advisory"
                width="100"
              />
            </div>
            <Text as="h3">Continuous, Ongoing Support</Text>
            <br />
            <Text as="bodyMd">
              Help when you need it, how you need it
              <ul>
                <li>Unlimited messaging</li>
                <li>Unlimited plan revisions when goals change</li>
                <li>Goal tracking</li>
                <li>1:1 video calls (add on)</li>
              </ul>
            </Text>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn />
      </Grid>
    </Card>
  )
}

/**
 * This Panel shows after a User has scheduled their intro Advisory Call
 */
const PreIntroCallPanel = ({
  faProfile,
}: {
  faProfile: FinancialAdvisoryProfile
}) => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()
  const [fetching, setFetching] = useState(false)
  const [calendlyJoinUrl, setCalendlyJoinUrl] = useState('')
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const introCallEvent = useReselector(getIntroUserFinancialAdvisoryCallEvents)
  const advisorName =
    introCallEvent?.rawCalendlyEventBlob?.event_memberships[0]?.user_name

  useEffect(() => {
    async function fetchCalendlyEvent() {
      if (introCallEvent?.rawCalendlyEventBlob?.uri) {
        const calendlyResponse = await getUpriseCalendlyEventDetails(
          introCallEvent?.rawCalendlyEventBlob?.uri
        )(dispatch)
        if (calendlyResponse) {
          setCalendlyJoinUrl(calendlyResponse?.location?.join_url)
        }
      }
    }
    fetchCalendlyEvent()
  }, [dispatch, introCallEvent])

  useEffect(() => {
    async function fetchData() {
      setFetching(true)
      await dispatch(fetchUserFinancialAdvisoryCallEventsIfNeeded())
      setFetching(false)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    pageView('advisory: pre intro call panel')
  }, [pageView])

  if (!fetching && faProfile) {
    return (
      <>
        <div
          style={
            isMobileOrTablet
              ? {
                  borderRadius: 4,
                  overflow: 'hidden',
                  paddingTop: 50,
                }
              : {
                  display: 'flex',
                  borderRadius: 4,
                  overflow: 'hidden',
                  paddingTop: 50,
                }
          }
        >
          <div
            style={
              isMobileOrTablet
                ? {
                    width: '100%',
                    backgroundColor: Colors.natural,
                    padding: 20,
                  }
                : {
                    width: '40%',
                    backgroundColor: Colors.natural,
                    padding: 20,
                  }
            }
          >
            <Grid centered stackable doubling>
              <GridRowColumn />
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={16} textAlign="center">
                  <Text as="h1" color="forest">
                    Here&apos;s what to expect next
                  </Text>
                </Grid.Column>
                <Grid.Column width={6} textAlign="center">
                  <Image
                    src="https://heard-images.s3.amazonaws.com/assets/Clock.svg"
                    height="300"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div
            style={
              isMobileOrTablet
                ? {
                    width: '100%',
                    backgroundColor: Colors.stone,
                    padding: 20,
                  }
                : { width: '60%', backgroundColor: Colors.stone, padding: 20 }
            }
          >
            <Grid doubling stackable>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <CircularWrapper height={40} wrapperColor="forest">
                    <Text as="bodyLg" color="white">
                      1
                    </Text>
                  </CircularWrapper>
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text as="bodyMd">
                    {' '}
                    <b>
                      Attend your free 20 Minute Intro Call with your financial
                      advisor
                    </b>
                  </Text>
                  <Text as="bodyMd">
                    Learn how you can benefit from Advisory, and get your
                    questions around the experience answered.
                  </Text>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <CircularWrapper height={40} wrapperColor="forest">
                    <Text as="bodyLg" color="white">
                      2
                    </Text>
                  </CircularWrapper>
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text as="bodyMd">
                    {' '}
                    <b>Decide if advisory services will be a fit</b>
                  </Text>
                  <Text as="bodyMd">
                    After your intro call, you’ll have a good understanding
                    around the experience, and if it is a fit for your goals.
                  </Text>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <CircularWrapper height={40} wrapperColor="forest">
                    <Text as="bodyLg" color="white">
                      3
                    </Text>
                  </CircularWrapper>
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text as="bodyMd">
                    {' '}
                    <b>
                      Add the Advisory Add On to onboard and receive your 100%
                      personalized and comprehensive financial plan.
                    </b>
                  </Text>
                  <Text as="bodyMd">
                    If you decide that Advisory is a fit, onboard to share your
                    full financial picture with your Advisor to receive a
                    personalized, comprehensive financial plan.
                  </Text>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <CircularWrapper height={40} wrapperColor="forest">
                    <Text as="bodyLg" color="white">
                      4
                    </Text>
                  </CircularWrapper>
                </Grid.Column>
                <Grid.Column width={14}>
                  <Text as="bodyMd">
                    {' '}
                    <b>Work towards your financial goals, together</b>
                  </Text>
                  <Text as="bodyMd">
                    Review and access your financial plan and recommended
                    actions, with the ability to message your advisor at any
                    point at time. Unlimited revisions and unlimited messaging,
                    with 1:1 calls if needed.
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
        <br />
        <br />
        <Text as="h2" style={{ paddingLeft: '10px' }}>
          Your upcoming call
        </Text>
        {introCallEvent && (
          <Card type="section">
            <Grid stackable doubling centered>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={3} textAlign="center">
                  <Image
                    src="https://heard-images.s3.amazonaws.com/assets/calendar_month.svg"
                    height={100}
                  />
                </Grid.Column>
                <Grid.Column width={9}>
                  <Text as="eyebrow">Date</Text>
                  <Text as="h2">
                    {' '}
                    {DateTime.fromISO(introCallEvent.startTime).toFormat(
                      DATE_FORMATS_LUXON.DISPLAY_SHORT
                    )}
                  </Text>
                  <Text as="h2">
                    {' '}
                    {DateTime.fromISO(introCallEvent.startTime).toFormat(
                      DATE_FORMATS_LUXON.TIME
                    )}{' '}
                    -{' '}
                    {DateTime.fromISO(introCallEvent.endTime).toFormat(
                      DATE_FORMATS_LUXON.TIME
                    )}
                  </Text>
                  <br />

                  <Text as="eyebrow">Certified Financial Planner</Text>
                  <Text as="h2">{advisorName}</Text>
                  <br />
                  <Text as="bodyMd">
                    Please join the Video Call by clicking &#34;Join Call&#34;
                    on the button on the right, or in your calendar invite.
                  </Text>
                  <br />
                  <Text as="bodySm" color="darkGray">
                    * Please provide 24 hour notice to cancel or reschedule, or
                    you may be charged a fee.{' '}
                  </Text>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Link newPage href={`${calendlyJoinUrl}`}>
                    <Button variant="primary" fullWidth>
                      Join Call
                    </Button>
                  </Link>
                  <br />
                  {/* <Button variant="secondary" fullWidth>
                   Reschedule or Cancel
                 </Button> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        )}
        <Divider />
        <Text as="h2">An Overview of the Advisory Experience</Text>
        <br />
        <AdvisorHelpSummaryCard />
        <br />
        <UpriseIntroCard />
        <br />
      </>
    )
  } else {
    return <Loader loading={fetching} />
  }
}

export default PreIntroCallPanel
