import { Grid, Image, Loader } from 'semantic-ui-react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Container,
  GridRowColumn,
  Icon,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  FETCH_TAX_CONSULTATION_KEY,
  fetchTaxConsultationRequest,
  TaxConsultationRequest,
} from './taxConsultationRequest.actions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useIntervalRef, useReselector } from '../../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../../reducers/fetch'
import {
  FETCH_TAX_CONSULTATION_URL,
  fetchTaxConsultationUrl,
} from './taxConsultation.actions'
import { Fonts } from '../../../styles/theme'

const CancellationModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => (
  <Modal open={open} onClose={onClose}>
    <Modal.Header>
      <Text as="h2">Cancellation policy</Text>
    </Modal.Header>
    <Modal.Content>
      <Text>
        If you need to cancel or reschedule your Consultation Service, please
        provide at least 24 hours’ notice to allow for proper rescheduling. If
        you do not provide at least 24 hours notice, the Consultation Service
        may be cancelled without a refund. If You have prepaid for Consultation
        Services on a prearranged timeframe, You must use the Consultation
        Services within the timeframe. No refunds or credits will be issued for
        failure to use Consultation Services within the prearranged timeframe
        (use it or lose it).
      </Text>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>Done</Button>
    </Modal.Actions>
  </Modal>
)

const LearnMoreFAQs = () => {
  return (
    <>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="How do I schedule my tax consult?"
          content={
            <Text>
              You’ll work with your tax preparer to schedule your consult. View
              chat to initiate a conversation with them about scheduling.
            </Text>
          }
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="How do I reschedule or cancel my consult?"
          content={
            <Text>
              Reach out to your tax preparer via chat to cancel or reschedule
              your consult. Remember that you must provide{' '}
              <b>at least 24 hours</b> notice to both reschedule or cancel your
              consult.
            </Text>
          }
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="What happens if I miss my consult?"
          content={
            <Text>
              Unfortunately, you will not receive a refund, and will forfeit any
              promotional discounts.
            </Text>
          }
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="What happens if my tax preparer misses my consult?"
          content={
            <Text>
              We are so sorry to hear this happened! Please reach out to our
              Support team via Messages so we can help resolve this for you.
            </Text>
          }
        />
      </GridRowColumn>
    </>
  )
}

const TaxAdvisoryPaymentConfirmation = () => {
  const { taxConsultationRequestId } = useParams()
  const dispatch = useAppDispatch()
  const intervalRef = useIntervalRef()
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [consultationRequest, setConsultationRequest] =
    useState<TaxConsultationRequest | null>(null)
  const [jobLink, setJobLink] = useState<string | null>(null)
  const navigate = useNavigate()
  const fetchError = useReselector(getFetchError, FETCH_TAX_CONSULTATION_KEY)
  const loadingConsultation = useReselector(
    getIsFetching,
    FETCH_TAX_CONSULTATION_KEY
  )
  const loadingJobPortal = useReselector(
    getIsFetching,
    FETCH_TAX_CONSULTATION_URL
  )

  useEffect(() => {
    const loadRequest = async () => {
      if (taxConsultationRequestId && !consultationRequest?.paidAt) {
        const res = await fetchTaxConsultationRequest(taxConsultationRequestId)(
          dispatch
        )
        if (res) {
          setConsultationRequest(res)
          const link = await fetchTaxConsultationUrl(res.taxfyleJobId)(dispatch)
          setJobLink(link ?? null)
        }
      }
    }

    // Stripe payment can take a few seconds to process so reload every 3 seconds until paidAt is set
    intervalRef.current = setInterval(loadRequest, 3000)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [
    taxConsultationRequestId,
    consultationRequest?.paidAt,
    dispatch,
    intervalRef,
  ])

  return (
    <Container variant="noShadow" centerContent>
      <Loader loading={loadingConsultation} />
      <div style={{ maxWidth: 456 }}>
        {fetchError?.message && (
          <Alert type="error">
            <Text>{fetchError.message}</Text>
          </Alert>
        )}
        {consultationRequest?.paidAt && (
          <>
            <Card backgroundColor="lightGreen" variant="noShadow">
              <Grid>
                <GridRowColumn
                  columnStyle={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Image
                    src="https://heard-images.s3.amazonaws.com/assets/circle_check_2.svg"
                    style={{ height: 80, width: 80 }}
                  />
                </GridRowColumn>
                <GridRowColumn textAlign="center">
                  <Text as="h2">Your request was sent</Text>
                </GridRowColumn>
                <GridRowColumn textAlign="center">
                  <Text>
                    Talk to your tax preparer to schedule your{' '}
                    <b>
                      {consultationRequest.durationInMinutes} minute consult
                    </b>
                  </Text>
                </GridRowColumn>
              </Grid>
            </Card>
            <Grid>
              <Grid.Row />
              <GridRowColumn>
                <Text as="h2">Next steps</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Your tax preparer will reach out to schedule your{' '}
                  {consultationRequest.durationInMinutes} minute consult. If you
                  don&apos;t hear from them within 24 hours, you can reach out
                  to them to get it scheduled.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text as="bodySm" color="darkGray">
                  Note: Provide your tax preparer with 24 hour notice if
                  cancelling or rescheduling. Please{' '}
                  <Button
                    style={{ ...Fonts.bodySm, textDecoration: 'underline' }}
                    variant="link"
                    onClick={() => setCancelModalOpen(true)}
                  >
                    read terms
                  </Button>{' '}
                  for more details.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h3">After your call</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  A copy of a transcript of your call will be automatically
                  uploaded to the Tax section of Documents.
                </Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  Also, look out for a follow-up survey to share your thoughts
                  about how your call went.
                </Text>
              </GridRowColumn>
              {
                <GridRowColumn
                  columnStyle={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    loading={loadingJobPortal}
                    onClick={() => {
                      if (jobLink) {
                        window.open(jobLink, '_self')
                      }
                    }}
                    style={{ display: 'flex', gap: 8 }}
                  >
                    View Chat
                    <Icon icon={regular('arrow-right')} />
                  </Button>
                </GridRowColumn>
              }
              <GridRowColumn textAlign="center">
                <Button
                  onClick={() => navigate('/taxes/annual')}
                  variant="link"
                >
                  Back to Annual Tax Center
                </Button>
              </GridRowColumn>
              <Grid.Row />
              <GridRowColumn>
                <Text as="h2">Learn more</Text>
              </GridRowColumn>
              <LearnMoreFAQs />
              <CancellationModal
                open={cancelModalOpen}
                onClose={() => setCancelModalOpen(false)}
              />
            </Grid>
          </>
        )}
        {consultationRequest && !consultationRequest.paidAt && (
          <Grid>
            <GridRowColumn>
              <Alert type="warn">
                <Text>
                  Looks like your payment might still be processing. Once we
                  receive confirmation, you&apos;ll be able to schedule your
                  call.
                </Text>
              </Alert>
            </GridRowColumn>
            <GridRowColumn textAlign="center">
              <Button onClick={() => navigate('/taxes/annual')} variant="link">
                Back to Annual Tax Center
              </Button>
            </GridRowColumn>
          </Grid>
        )}
      </div>
    </Container>
  )
}

export default TaxAdvisoryPaymentConfirmation
