import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { FetchError } from '../../../reducers/fetch'
import { TransactionCategory } from '../../Reports/reports.slice'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import {
  EOYBookkeepingFollowupSubstepId,
  YearEndModuleStatusOptions,
} from '../../YearEndModuleStatus/yearEndModuleStatus.slice'

export enum EndOfYearReviewStepStatus {
  pending = 'pending',
  userActionRequired = 'user_action_required',
  complete = 'complete',
}

export type ExtendedStepStatus = EndOfYearReviewStepStatus | 'pending_ticket'

export type StepContext = Record<string, unknown>

export type AdminStepUpdaterArgs = {
  step: EndOfYearAdminReviewStep
  context?: StepContext
  status?: EndOfYearReviewStepStatus
}
export type AdminStepUpdater = (
  args: AdminStepUpdaterArgs
) => Promise<EndOfYearReviewSubmissionStep | null>

export type OnAdminNoteSaveCallback = (note: string) => Promise<void>
export type OnStatementReUploadSaveCallback = (note: string) => Promise<void>
export type OnDocumentReUploadSaveCallback = (note: string) => Promise<void>

export type AdminNoteState = {
  open: boolean
  loading: boolean
  note: string | null
  onSave: OnAdminNoteSaveCallback | null
}

export type StatementReUploadState = {
  open: boolean
  loading: boolean
  note: string | null
  onSave: OnStatementReUploadSaveCallback | null
}

export type DocumentReUploadState = {
  open: boolean
  loading: boolean
  note: string | null
  onSave: OnDocumentReUploadSaveCallback | null
}

export type ConfirmModalState = {
  open: boolean
  loading: boolean
}

export enum EndOfYearAdminReviewStep {
  disconnectedAccounts = 'disconnected-accounts',
  uploadMissingStatements = 'upload-missing-statements',
  unclarifiedTransactions = 'unclarified-transactions',
  transactions = 'transactions',
  docsPayroll = 'docs-payroll',
  docsContractorPayments = 'docs-contractor-payments',
  docsProcessingFees = 'docs-processing-fees',
  docsPreviousTaxReturn = 'docs-previous-tax-return',
  docsAssetsOver2500 = 'docs-assets-over-2500',
  docsOther = 'docs-other',
  finalReview = 'final-review',
}

export interface EndOfYearReviewSubmissionStep {
  id: number
  submissionId: number
  step: EndOfYearAdminReviewStep
  status: EndOfYearReviewStepStatus
  userSubmissionCount: number
  context: unknown
  completedAt?: string | null
  createdAt: string
  updatedAt: string
}

export enum EndOfYearSubmissionStatus {
  inProgress = 'in_progress',
  waitingOnHeard = 'waiting_on_heard',
  actionRequired = 'action_required',
  complete = 'complete',
}

export interface EndOfYearReviewSubmission {
  id: number
  year: string
  userId: number
  status: EndOfYearSubmissionStatus
  userSubmissionCount: number
  lastUserSubmittedAt?: string
  lastBkSubmittedAt?: string
  createdAt: string
  updatedAt: string
  steps: EndOfYearReviewSubmissionStep[]
  substepIdentifier?: EOYBookkeepingFollowupSubstepId | null
  followupStatus?: YearEndModuleStatusOptions | null
}

export interface DepreciableAssetTransaction
  extends Pick<Transaction, 'id' | 'amountInCents' | 'date' | 'description'> {
  transactionCategory?: Pick<
    TransactionCategory,
    'id' | 'name' | 'identifier'
  > | null
  receipts?: Pick<UserDocument, 'id' | 'fileDescription' | 'signedUrl'>[]
}

export interface KeyedFetchError extends Partial<FetchError> {
  key: string
}

export interface NotRelevant {
  reason: string
  context: string
}

export interface Document {
  categoryCode: string
  name?: string
  documentId?: number
  notRelevant?: NotRelevant
  provider?: string
  integrationError?: boolean
  requestReUpload?: boolean
  updatedByUser?: boolean
  adminNote?: string
}

export type Receipt = Pick<
  Document,
  'documentId' | 'requestReUpload' | 'updatedByUser' | 'adminNote'
>

export type DocumentFile = {
  href: string
  name: string
}

export interface IDocumentsStepContext {
  documents?: Document[]
  otherDocumentIds?: number[]
}

export interface DisconnectedAccountsStepContext {
  cannotReconnectAccountIds?: number[]
  zendeskTicketId?: number
}

export interface MissingBankStatementsStepContext {
  missingStatements?: boolean
  requestNextYear?: boolean
  adminNote?: string
  updatedByUser?: boolean
}

export interface OutstandingTransactionsStepContext {
  adminNote?: string
  updatedByUser?: boolean
}

export interface PayrollDocumentsStepContext extends IDocumentsStepContext {
  payrollProviders?: string[]
}

export interface ContractorDocumentsStepContext extends IDocumentsStepContext {
  payrollProviders?: string[]
}

export interface IFixedAssetDepreciationStepContext {
  receiptIds?: number[]
  receipts?: Receipt[]
  otherDocumentIds?: number[]
}

export interface OtherDocumentsStepContext {
  documentIds?: number[]
}

export interface FinalReviewStepContext {
  updatedTransactionIds?: number[]
  adminNote?: string
}

export interface DisconnectedAccountsStep
  extends EndOfYearReviewSubmissionStep {
  context: DisconnectedAccountsStepContext
}

export interface MissingBankStatementsStep
  extends EndOfYearReviewSubmissionStep {
  context: MissingBankStatementsStepContext
}

export interface OutstandingTransactionsStep
  extends EndOfYearReviewSubmissionStep {
  context: OutstandingTransactionsStepContext
}

export interface PayrollDocumentsStep extends EndOfYearReviewSubmissionStep {
  context: PayrollDocumentsStepContext
}

export interface ContractorDocumentsStep extends EndOfYearReviewSubmissionStep {
  context: ContractorDocumentsStepContext
}

export interface ProcessingFeeDocumentsStep
  extends EndOfYearReviewSubmissionStep {
  context: IDocumentsStepContext
}

export interface PreviousYearReturnStep extends EndOfYearReviewSubmissionStep {
  context: IDocumentsStepContext
}

export interface AssetDepreciationDocumentsStep
  extends EndOfYearReviewSubmissionStep {
  context: IFixedAssetDepreciationStepContext
}

export interface OtherDocumentsStep extends EndOfYearReviewSubmissionStep {
  context: OtherDocumentsStepContext
}

export interface FinalReviewStep extends EndOfYearReviewSubmissionStep {
  context: FinalReviewStepContext
}
