import { ReactElement, cloneElement, useCallback, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { ProgressBar, ProgressBarNoTitles } from '../BaseComponents'
import { useNavigateWithLocation } from '../../utils/routeHelpers'

export const REVIEW_QUERY_PARAM = 'reviewing'

// Returns current screen and set screen function
export const useSetScreen = <T extends string>({
  // If backlink is set and `setScreen` is called with null this url will be naved to
  backLink,
  // Often in form flows there is an end "review" screen with direct links to other parts of the flow.  If the user
  // navigates to one of these pages they should auto naved back to the review page after completion
  reviewScreen,
}: { backLink?: string; reviewScreen?: string } = {}) => {
  const navigate = useNavigateWithLocation()
  const { state } = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const reviewing = searchParams.get(REVIEW_QUERY_PARAM) === 'true'

  // Sets the current screen query param
  const setScreen = useCallback(
    (newScreen: T | null, replace?: boolean) => {
      if (reviewing && reviewScreen) {
        // If a review screen is sent in then the review page will be returned instead of the normal new screen of the flow
        setSearchParams({ screen: reviewScreen }, { state })
      } else if (newScreen) {
        // Replace will set the current link as the url but without adding it to browser history
        setSearchParams(
          { screen: newScreen },
          replace ? { replace: true, state } : { state }
        )
      } else if (backLink) {
        // If back link is sent and no screen is sent in setScreen, screen will navigate back to link
        navigate(backLink)
      }
    },

    [backLink, navigate, reviewScreen, reviewing, setSearchParams, state]
  )

  // Similar to above but also sets the review query param which puts the flow into a review state.
  // Every screen after this will navigate back to the review page
  const setReviewScreen = useCallback(
    (screen: T) => {
      setSearchParams({ screen, [REVIEW_QUERY_PARAM]: 'true' })
    },
    [setSearchParams]
  )

  const currentScreen = searchParams.get('screen')
  return { setScreen, currentScreen, setReviewScreen }
}

export interface FormFlowScreen<T> {
  // Component to render.  This is a function so that the component won't be rendered unless called
  component: () => ReactElement
  // Step number in the progress bar
  step: number
  // Enum screen step
  screenName: T
  // Order of the screens (if different from step - supports multiple screens in the same step)
  order?: number
}

interface BaseFormFlowProps<T> {
  screens: FormFlowScreen<T>[]
}

interface NoTitlesFormFlow<T> extends BaseFormFlowProps<T> {
  noTitles: true
  totalSteps: number
  steps?: undefined
}

interface TitlesFormFlow<T> extends BaseFormFlowProps<T> {
  noTitles?: false
  steps: string[]
  totalSteps?: undefined
}

export const useFormFlow = <T extends string>({
  screens,
  noTitles,
  steps,
  totalSteps,
}: NoTitlesFormFlow<T> | TitlesFormFlow<T>) => {
  const { currentScreen } = useSetScreen()

  const currentScreenConfig = screens.find(
    (screen) => screen.screenName === currentScreen
  )

  const currentStep = useMemo(
    () => currentScreenConfig?.step || 0,
    [currentScreenConfig?.step]
  )

  const progressBar = useMemo(
    () =>
      noTitles ? (
        <ProgressBarNoTitles
          currentStep={currentStep}
          totalSteps={totalSteps}
        />
      ) : (
        <ProgressBar steps={steps} currentStep={currentStep} />
      ),
    [currentStep, noTitles, steps, totalSteps]
  )

  // Note, this does not support conditional form flows.
  // You'll have to explicitly set the screens for the conditional cases (same for previousScreen)
  const nextScreen = useMemo(() => {
    const nextScreenIndex = screens.findIndex((screen) => {
      return (
        (screen.order ?? screen.step) ===
        (currentScreenConfig?.order ?? currentStep) + 1
      )
    })
    return screens[nextScreenIndex]?.screenName ?? null
  }, [currentStep, screens, currentScreenConfig?.order])

  const previousScreen = useMemo(() => {
    const previousScreenIndex = screens.findIndex((screen) => {
      return (
        (screen.order ?? screen.step) ===
        (currentScreenConfig?.order ?? currentStep) - 1
      )
    })
    return screens[previousScreenIndex]?.screenName ?? null
  }, [currentStep, screens, currentScreenConfig?.order])

  const contentComponent = useMemo(() => {
    if (currentScreenConfig) {
      return cloneElement(currentScreenConfig?.component(), {
        nextScreen,
        previousScreen,
      })
    } else {
      return null
    }
  }, [currentScreenConfig, nextScreen, previousScreen])

  return {
    progressBar,
    content: contentComponent,
  }
}
