import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper } from '../../../reducers/fetch'
import {
  destroyUserDocument,
  receiveSingleUserDocument,
} from '../../UserDocuments/userDocuments.slice'
export interface TaxUserDocument {
  id: number
  userDocumentId: number
  taxYear: string | null
  quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4' | null
  taxfyleCallId: string | null
  isState: boolean | null
  isFederal: boolean | null
}

export interface TaxUserDocumentState {
  [key: string]: TaxUserDocument
}

const taxUserDocumentSlice = createSlice({
  name: 'taxUserDocuments',
  initialState: {} as TaxUserDocumentState,
  reducers: {
    receiveTaxUserDocuments: (
      state,
      action: PayloadAction<{ [key: string]: TaxUserDocument }>
    ) => ({ ...state, ...action.payload }),
    receiveSingleTaxUserDocument: (
      state,
      action: PayloadAction<TaxUserDocument>
    ) => {
      state[action.payload.id] = action.payload
    },
    destroySingleTaxUserDocument: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(destroyUserDocument, (state, action) => {
      if (action.payload.deletedTaxDocumentId) {
        delete state[action.payload.deletedTaxDocumentId]
      }
    })
    builder.addCase(receiveSingleUserDocument, (state, action) => {
      if (action.payload.taxUserDocument) {
        state[action.payload.taxUserDocument.id] =
          action.payload.taxUserDocument
      }
    })
  },
})

export default taxUserDocumentSlice.reducer

// Actions
export const {
  receiveTaxUserDocuments,
  receiveSingleTaxUserDocument,
  destroySingleTaxUserDocument,
} = taxUserDocumentSlice.actions

export const FETCH_TAX_USER_DOCUMENTS_KEY = 'FETCH_TAX_USER_DOCUMENTS_KEY'
export const fetchTaxUserDocumentsIfNeeded = () =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'Error fetching all tax user documents',
    fetchKey: FETCH_TAX_USER_DOCUMENTS_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<TaxUserDocument[]>(
        '/finances/api/v1/tax_user_documents'
      )

      dispatch(receiveTaxUserDocuments(keyBy(json.data, 'id')))
      return json.data
    },
  })

export const FETCH_TAX_USER_DOCUMENTS_FOR_YEAR_KEY =
  'FETCH_TAX_USER_DOCUMENTS_FOR_YEAR_KEY'
export const fetchTaxUserDocumentsForYearIfNeeded = (year: string) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'Error fetching all tax user documents',
    fetchKey: FETCH_TAX_USER_DOCUMENTS_FOR_YEAR_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<TaxUserDocument[]>(
        `/finances/api/v1/tax_user_documents/${year}`
      )

      dispatch(receiveTaxUserDocuments(keyBy(json.data, 'id')))
      return json.data
    },
  })

const FETCH_TAX_USER_DOCUMENTS_FOR_USER_KEY =
  'FETCH_TAX_USER_DOCUMENTS_FOR_USER_KEY'
export const fetchTaxUserDocumentsIfNeededForUser = (
  userId: number,
  year?: string
) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_TAX_USER_DOCUMENTS_FOR_USER_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.get<TaxUserDocument[]>(
        `/finances/api/v1/admin/tax_user_documents/${userId}`,
        {
          params: {
            year,
          },
        }
      )

      dispatch(receiveTaxUserDocuments(keyBy(json.data, 'id')))
      return json.data
    },
  })
