import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'

import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import {
  FormikInput,
  GridRowColumn,
  Text,
  makeNumberSchema,
  getFieldNames,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

export const ownedFarmQuestionIds = [TaxListQuestionId.number_of_farms]

const OwnedFarmPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const numberOfFarmsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.number_of_farms,
  })

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.number_of_farms]: numberOfFarmsQR.initialResponse,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await goToNextStep(
        [numberOfFarmsQR.getResponseData(values)],
        nextScreen ?? null,
        {
          completedSteps: [SubStepIdentifiers.farm],
        }
      )
    },
  })

  const { submitForm, isSubmitting } = formik

  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Farm
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg" textAlign="left">
            Your tax preparer will need additional information about your
            farm(s) to determine what deductions you may be eligible for.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikInput
            name={fieldNames[TaxListQuestionId.number_of_farms]}
            label={numberOfFarmsQR.requiredLabel}
            schema={makeNumberSchema({
              field: 'number of farms',
            })}
            fullWidth
            placeholder="Enter number"
          />
        </GridRowColumn>
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default OwnedFarmPanel
