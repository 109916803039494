import { Fragment, useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import TaxCenterPromptCard, { PromptCardStatus } from './TaxCenterPromptCard'
import {
  Alert,
  Button,
  Card,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Icon,
  Link,
  makeNumberSchema,
  makeReqBoolSchema,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import {
  fetchUserDocuments,
  UserDocument,
} from '../../../UserDocuments/userDocuments.slice'
import UploadedDocument from '../TaxChecklist/Documents/UploadedDocument'
import FileUploadModal from '../../../../components/FileUpload/FileUploadModal'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../annualTaxFilingForms.selector'
import { FETCH_ANNUAL_TAX_FILING_FORMS_KEY } from '../annualTaxFilingForms.slice'
import { stateAbbToState } from '../../../../constants/locationConstants'
import {
  getFetchError,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import {
  AnnualTaxOutcome,
  FETCH_ANNUAL_TAX_OUTCOMES_KEY,
  fetchAnnualTaxOutcomesIfNeeded,
  selectAnnualTaxOutcomesByFormId,
  UPDATE_ANNUAL_TAX_OUTCOME_KEY,
  updateAnnualTaxOutcome,
} from '../annualTaxOutcome.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { selectSortedTaxReturnDocumentsForYear } from '../../../UserDocuments/userDocuments.selector'
import { fetchTaxUserDocumentsForYearIfNeeded } from '../taxUserDocuments.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const AnnualTax1040WrapUpModal = ({
  onClose,
  modalOpen,
  year,
  outcomes,
}: {
  onClose: () => void
  modalOpen: boolean
  year?: string
  outcomes: AnnualTaxOutcome[]
}) => {
  const dispatch = useAppDispatch()
  const [modalOpenForIndex, setModalOpenForIndex] = useState<number>()
  const error = useReselector(getFetchError, UPDATE_ANNUAL_TAX_OUTCOME_KEY)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const yearToUse = year || currentTaxYear

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsForYearIfNeeded(yearToUse))
  }, [dispatch, yearToUse])

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      outcomes: outcomes.map((outcome) => ({
        ...outcome,
        amount: outcome.amountInCents
          ? centsToDollars(outcome.amountInCents)
          : '',
      })),
    },
    onSubmit: async (data) => {
      const results = await Promise.all(
        data.outcomes.map(
          (outcome) =>
            outcome.isRefund !== null &&
            updateAnnualTaxOutcome(outcome.id, {
              isRefund: outcome.isRefund,
              receiptDocumentId: outcome.receiptDocumentId,
              amountInCents: dollarsToCents(outcome.amount),
            })(dispatch)
        )
      )

      if (results.every((res) => res)) {
        onClose()
      }
    },
  })

  const {
    values,
    submitForm,
    setFieldValue,
    validateForm,
    isValid,
    isSubmitting,
  } = formik

  const content = useMemo(() => {
    return (
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              For each entity, please let us know if you received a refund or if
              you made a tax payment.
            </Text>
          </GridRowColumn>
          {error && (
            <GridRowColumn>
              <Alert type="error">{error.message}</Alert>
            </GridRowColumn>
          )}
          {values.outcomes.map((result, i) => {
            const { id, isRefund, isFederal, receiptDocumentId, state } = result
            const title = isFederal
              ? 'federal tax'
              : `${state ? stateAbbToState(state) : ''} state tax`

            return (
              <Fragment key={id}>
                {i !== 0 && (
                  <GridRowColumn>
                    <Divider />
                  </GridRowColumn>
                )}
                <Grid.Row key={id}>
                  <Grid.Column width={1}>
                    <Icon
                      icon={isFederal ? light('landmark') : light('home')}
                      color="green"
                      size="2x"
                    />
                  </Grid.Column>

                  <Grid.Column width={15}>
                    <Grid>
                      <GridRowColumn>
                        <Text as="eyebrow" color="darkGray">
                          {title}
                        </Text>
                      </GridRowColumn>
                      <GridRowColumn short>
                        <FormikLabelError
                          name={`outcomes[${i}].isRefund`}
                          label={`For your personal ${title} return, you:`}
                          schema={makeReqBoolSchema()}
                        />
                      </GridRowColumn>
                      <Grid.Row className="short">
                        <Grid.Column width={8}>
                          <FormikRadioToggleButton
                            name={`outcomes[${i}].isRefund`}
                            value
                            fullWidth
                            onClick={validateForm}
                          >
                            Received a Refund
                          </FormikRadioToggleButton>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FormikRadioToggleButton
                            name={`outcomes[${i}].isRefund`}
                            value={false}
                            fullWidth
                            onClick={validateForm}
                          >
                            Paid Taxes
                          </FormikRadioToggleButton>
                        </Grid.Column>
                      </Grid.Row>
                      {isRefund !== null && (
                        <GridRowColumn>
                          <FormikInput
                            label={
                              isRefund
                                ? 'How much was your refund?'
                                : `How much did you pay in ${title}es?`
                            }
                            componentType="currency"
                            name={`outcomes[${i}].amount`}
                            fullWidth
                            schema={makeNumberSchema({
                              allowedDecimals: 2,
                            })}
                          />
                        </GridRowColumn>
                      )}
                      {isRefund === false && (
                        <>
                          <GridRowColumn verticalAlign="middle">
                            <Button
                              variant="secondary"
                              onClick={() => setModalOpenForIndex(i)}
                            >
                              <Icon
                                icon={regular('upload')}
                                size="1x"
                                style={{ marginRight: 10 }}
                              />
                              Upload payment receipt
                            </Button>
                          </GridRowColumn>
                          {receiptDocumentId && (
                            <UploadedDocument id={receiptDocumentId} />
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Fragment>
            )
          })}
        </Grid>
      </Modal.Content>
    )
  }, [error, validateForm, values.outcomes])

  return (
    <FormikProvider value={formik}>
      <Modal open={modalOpen} closeIcon onClose={onClose}>
        <Modal.Header>{yearToUse} Annual Tax Season Wrap-Up</Modal.Header>
        {content}
        <Modal.Actions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={submitForm}
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>

      <FileUploadModal
        open={modalOpenForIndex !== undefined}
        close={() => setModalOpenForIndex(undefined)}
        userFacing
        documentType={UploadDocumentType.TAX}
        categoryInternalName={
          UserDocumentCategoryIdentifier.annualTaxPaymentReceipt
        }
        setUploadedFile={async ({ id }) => {
          await setFieldValue(
            `outcomes[${modalOpenForIndex}].receiptDocumentId`,
            id
          )
        }}
        year={yearToUse}
      />
    </FormikProvider>
  )
}

const FinalReturnsList = ({ documents }: { documents: UserDocument[] }) => {
  return (
    <>
      {documents.map((doc) => (
        <GridRowColumn centerContent key={doc.id}>
          <Link href={doc.signedUrl || '#'} newPage>
            <Icon icon={regular('file-alt')} /> {doc.fileDescription}
          </Link>
        </GridRowColumn>
      ))}
    </>
  )
}

const CongratsCard = ({
  year,
  documents,
}: {
  year: string
  documents: UserDocument[]
}) => (
  <Card type="subsection" backgroundColor="stone40">
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/finish-line.svg"
          style={{ width: 396, height: 200 }}
        />
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text as="display2" textAlign="center">
          Congratulations on finishing your {year} taxes!
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={3} width={10}>
        <Text as="bodyLg" textAlign="center">
          Thanks for allowing us to guide you along the way. You can always
          access your return in the Tax-related Forms tab of Your Documents or
          review your {<Link to="/taxes/annual/tax_checklist">checklist</Link>}.
        </Text>
      </GridRowColumn>
      <FinalReturnsList documents={documents} />
    </Grid>
  </Card>
)

const AnnualTaxWrapUpCard = ({ year }: { year: string }) => {
  const dispatch = useAppDispatch()

  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_ANNUAL_TAX_OUTCOMES_KEY,
  ])

  const [modalOpen, setModalOpen] = useState(false)
  const form1040 = useReselector(select1040FormForYear, year)
  const form1120s = useReselector(select1120sFormForYear, year)
  const outcomes = useReselector(selectAnnualTaxOutcomesByFormId, form1040?.id)
  const form1040FinalReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    year.toString()
  ).personal

  const form1120sFinalReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    year.toString()
  ).business

  useEffect(() => {
    dispatch(fetchAnnualTaxOutcomesIfNeeded(form1040?.id))
  }, [dispatch, form1040?.id, modalOpen])

  const status = useMemo(() => {
    // Is refund is required to be filled out with an outcome.  Use that to check if it's completed
    if (outcomes.some((outcome) => outcome.isRefund === null)) {
      return PromptCardStatus.ACTION_REQUIRED
    }

    return PromptCardStatus.DONE
  }, [outcomes])

  if (isFetching) {
    return null
  }

  if (status === PromptCardStatus.DONE) {
    return (
      <>
        {form1120s?.id && (
          <CongratsCard year={year} documents={form1120sFinalReturns} />
        )}
        {form1040?.id && (
          <CongratsCard year={year} documents={form1040FinalReturns} />
        )}
      </>
    )
  }

  return (
    <TaxCenterPromptCard
      body={{
        [PromptCardStatus.COMING_SOON]: null,
        [PromptCardStatus.ACTION_REQUIRED]: (
          <Grid>
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/house_check.svg"
                style={{ width: 159, height: 81 }}
              />
            </GridRowColumn>
            <GridRowColumn width={10} spacer={3}>
              <Text textAlign="center">
                The IRS has accepted your 1040 tax filing. Please update Heard
                with whether you received a refund or made a tax payment.
              </Text>
            </GridRowColumn>
            <GridRowColumn centerContent>
              <Button onClick={() => setModalOpen(true)}>Update</Button>
            </GridRowColumn>
            <FinalReturnsList documents={form1040FinalReturns} />
            {outcomes && (
              <AnnualTax1040WrapUpModal
                modalOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                year={year}
                outcomes={outcomes}
              />
            )}
          </Grid>
        ),
        [PromptCardStatus.IN_PROGRESS]: null,
        [PromptCardStatus.DONE]: null,
      }}
      icon={regular('user')}
      status={status}
      title={`${year} Annual Tax Season Wrap-Up`}
    />
  )
}

export default AnnualTaxWrapUpCard
