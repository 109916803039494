import { useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import { Divider, Icon } from 'semantic-ui-react'
import { NavButtons } from '../../end-of-year-review/nav-buttons'
import { getMerchantNames } from '../../end-of-year-review/processing-fees-documents/documents-map'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'

import { Text } from '../../../../components/BaseComponents'
import { TransactionCategoryIdentifier } from '../../../../reducers/admin/allTransactions.slice'
import { fetchFilteredUserTransactions } from '../../../Transactions/transactions.slice'
import { selectUserTransactionsByCategoryIdentifierForYear } from '../../../Transactions/transactions.selectors'
import { useReselector, useFetchResponse } from '../../../../utils/sharedHooks'
import { useNavigate, useParams } from 'react-router-dom'
import { selectUserDocumentList } from '../../../UserDocuments/userDocuments.selector'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import { TAX_ENTITY_TYPES } from '../../../Taxes/taxConstants'
import { getAllProviders } from '../../../Provider/provider.selectors'
import { userGetProcessingFees } from '../../end-of-year-review/actions'
import { ReviewDocuments } from '../../end-of-year-review/review-document-uploads'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchPayrollProfileIfNeeded } from '../../../Payroll/payrollActions'
import { fetchPlaidItemsIfNeeded } from '../../../../actions/plaidItemActions'
import { fetchTransactionCategoriesIfNeeded } from '../../../Reports/reports.slice'
import { fetchFinancialAccountsIfNeeded } from '../../../../actions/financialAccountActions'
import { fetchProvidersIfNeeded } from '../../../Provider/provider.actions'
import { selectIsPayrollActive } from '../../../Payroll/payroll.selectors'
import moment from 'moment'
import './styles.scss'

export const FollowUpOnDocuments = () => {
  const { year } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
    }

    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchPayrollProfileIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchProvidersIfNeeded({ alwaysFetch: true }))

    dispatch(
      fetchFilteredUserTransactions({
        startDate: moment()
          .year(parseInt(year ?? '0'))
          .startOf('year'),
        endDate: moment()
          .year(parseInt(year ?? '0'))
          .endOf('year'),
      })
    )

    fetch()
  }, [dispatch, year])

  const documents = useReselector(selectUserDocumentList)
  const financialProfile = useReselector(getFinancialProfile)
  const hasHeardPayroll = useReselector(selectIsPayrollActive)

  const equipmentOver2500 = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.equipment_gte_2500,
    year
  )

  const computerHardwareOver2500 = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.computer_hardware_gte_2500,
    year
  )

  const furnitureOver2500 = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.furniture_gte_2500,
    year
  )

  const providers = useReselector(getAllProviders)

  const assetsOver2500 = useMemo(
    () => [
      ...equipmentOver2500,
      ...computerHardwareOver2500,
      ...furnitureOver2500,
    ],
    [equipmentOver2500, computerHardwareOver2500, furnitureOver2500]
  )

  const isMultiYearSCorp = useMemo(() => {
    if (!financialProfile) return false
    const { taxEntityType, scorpVerifiedAt, entityChangeElectionDate } =
      financialProfile
    if (
      taxEntityType !== TAX_ENTITY_TYPES.form_1120_s ||
      !scorpVerifiedAt ||
      !entityChangeElectionDate
    )
      return false
    const startOfYear = DateTime.fromObject(
      { year: parseInt(year ?? '0'), month: 1, day: 1 },
      { zone: 'utc' }
    )
    const electionDate = DateTime.fromISO(entityChangeElectionDate, {
      zone: 'utc',
    })
    return electionDate < startOfYear
  }, [financialProfile, year])

  const processingFees = useFetchResponse(
    userGetProcessingFees,
    year ?? '',
    [],
    getMerchantNames()
  )

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon name="check" style={{ color: '#457634' }} />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>

      <Divider style={{ marginTop: 50 }} />
      <div className="step-body">
        <ReviewDocuments
          documents={documents}
          taxYear={year ?? ''}
          isMultiYearSCorp={isMultiYearSCorp}
          assetsOver2500={assetsOver2500}
          providers={providers}
          processingFees={processingFees}
          hasHeardPayroll={hasHeardPayroll}
          setIsStepComplete={() => {}}
          isFollowUp
        />
      </div>

      <NavButtons
        isStepComplete
        stepBack={() => {
          navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
        }}
        stepForward={() => {
          navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
        }}
        saveAndExit={() => {
          navigate('/taxes/annual')
        }}
      />
    </div>
  )
}
