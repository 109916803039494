import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid, Image } from 'semantic-ui-react'
import { useMemo } from 'react'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  getCurrentUser,
  getFinancialProfile,
  selectMembershipStart,
} from '../../../../selectors/user.selectors'
import { useNavigate } from 'react-router-dom'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { useYearEndNextStepPill } from '../../../YearEndModuleStatus/components/EOYNextStepPill'
import { getTskDueDateForUser } from './utils'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'

const TaxSeasonKickoffEntryCard = () => {
  const navigate = useNavigate()
  const currentUser = useReselector(getCurrentUser)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const membershipStartDate = useReselector(selectMembershipStart)
  const financialProfile = useReselector(getFinancialProfile)

  const { pretaxSurveySubmittedAt, optedOutAt } = annualTaxFiling || {}
  const nextStepPill = useYearEndNextStepPill()

  const tskDueDateForUser = getTskDueDateForUser({
    membershipStartDate,
    taxEntityType: financialProfile?.taxEntityType,
    originalTskDueDate: annualTaxDetails?.taxSeasonKickoffDueAt,
    newUserCutOffAt: annualTaxDetails?.newUserCutOffAt,
    form1020SCloseBooksDueDate:
      annualTaxDetails?.form_1120_s_close_books_due_date,
    form1020SExtensionSurveyDueDate:
      annualTaxDetails?.form_1120_s_extension_survey_due_date,
    form1040CloseBooksDueDate: annualTaxDetails?.form_1040_close_books_due_date,
    form1040ExtensionSurveyDueDate:
      annualTaxDetails?.form_1040_extension_survey_due_date,
  })

  const cardState = useMemo(() => {
    if (pretaxSurveySubmittedAt) {
      const mostUrgentItemPill = <GridRowColumn>{nextStepPill}</GridRowColumn>
      if (optedOutAt) {
        return {
          headerText: `Close your books for ${taxYear}`,
          dueDate: null,
          description: (
            <>
              We’ll walk you through getting your books in order for the year.
              This will set you up for success when you file your annual taxes.
            </>
          ),
          nextStepPill: mostUrgentItemPill,
          button: {
            text: `Go to ${taxYear} Year-End Bookkeeping`,
            onClick: () => navigate('/taxes/annual'),
          },
        }
      }
      return {
        headerText: 'File your annual tax return ',
        dueDate: annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate,
        description: (
          <>
            Close out {taxYear} by getting everything in order for annual taxes.
          </>
        ),
        nextStepPill: mostUrgentItemPill,
        button: {
          text: 'Go to Annual Taxes',
          onClick: () => navigate('/taxes/annual'),
        },
      }
    }

    return {
      headerText: 'Get started on annual taxes',
      dueDate: tskDueDateForUser?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY),
      description: (
        <>
          Complete the Tax Season Kickoff to let us know if you&apos;d like to
          file taxes with Heard. We recommend completing this <b>ASAP</b>. It
          only takes 5 minutes!
        </>
      ),
      nextStepPill: null,
      button: {
        text: 'Start Tax Season Kickoff',
        onClick: () => navigate('/taxes/annual/kickoff-survey'),
      },
    }
  }, [
    annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate,
    tskDueDateForUser,
    navigate,
    nextStepPill,
    optedOutAt,
    pretaxSurveySubmittedAt,
    taxYear,
  ])

  return (
    <Card
      style={{
        backgroundColor: Colors.natural,
      }}
    >
      <Grid>
        <Grid.Column width={12}>
          <GridRowColumn>
            <Text as="eyebrow" style={{ marginBottom: 5 }}>
              Welcome {currentUser?.firstName}!
            </Text>
          </GridRowColumn>
          <Text
            as="display2"
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {cardState.headerText}
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <Text as="bodySm" style={{ lineHeight: 1.5 }}>
              {cardState.description}
            </Text>
            {cardState.nextStepPill}
            {cardState.button && (
              <Button
                style={{ width: 'fit-content' }}
                onClick={cardState.button.onClick}
              >
                {cardState.button.text}
                <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
              </Button>
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
            style={{
              width: '170px',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Grid.Column>
      </Grid>
    </Card>
  )
}

export default TaxSeasonKickoffEntryCard
