import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../components/BaseComponents'
import { Grid } from 'semantic-ui-react'
import { Colors } from '../../styles/theme'
import { useAnalyticsTrack } from '../Amplitude'

const PayrollCard = () => {
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  useEffect(() => {
    track('viewed payroll upsell card')
  }, [track])
  return (
    <Card
      backgroundColor="green"
      className={'mobile-squared-corners'}
      style={{
        backgroundImage:
          'url("https://heard-images.s3.amazonaws.com/assets/arrow-left.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: '190px 100%',
      }}
    >
      <Grid>
        <GridRowColumn>
          <Text color={'white'} as={'h2'}>
            Did you know you could run payroll with Heard?
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text color={'white'}>
            Heard partners with Gusto so you can pay yourself, employees, and
            contractors in minutes. Keep your practice’s finances in one place.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Button
            style={{ backgroundColor: Colors.white, color: Colors.black }}
            onClick={() => {
              track('clicked payroll upsell card button')
              navigate('/payroll/welcome')
            }}
          >
            Get started
          </Button>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

export default PayrollCard
